import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHStack } from "@components/design-systems";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { getEditManagerGroupDataFieldPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
// Manager viewing group selection product level
interface ManagerViewingGroupSelectionProps {
  viewingGroupData: ViewingGroupSelectionDTO[] | null;
  onChange?: () => void;
  disabled?: boolean;
}
const ManagerViewingGroupSelection = ({
  viewingGroupData,
  onChange,
  disabled,
}: ManagerViewingGroupSelectionProps) => {
  const managerGroupDataPath = getEditManagerGroupDataFieldPath();

  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();
  const handleSelectViewingGroup = (
    value: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      managerGroupDataPath.isModified,
      !isEqual(value, viewingGroupData),
    );
    onChange && onChange();
  };
  const debounceHandleSelectViewingGroup = debounce(
    handleSelectViewingGroup,
    EditProductDefaults.DebounceTime,
  );
  return (
    <SHStack
      width={{
        xs: "100%",
        md: 520,
      }}
      sx={{ paddingBottom: 3 }}
    >
      <Controller
        control={control}
        name={managerGroupDataPath.viewingGroupSelections}
        render={({ field: { ref, ...other } }) => (
          <SelectViewingGroups
            disabled={disabled}
            sx={{
              flex: 1,
              width: "100%",
            }}
            textFieldProps={{
              label: "This profile is visible to",
            }}
            value={
              (getValues(managerGroupDataPath.viewingGroupSelections) as
                | ViewingGroupSelectionDTO[]
                | null) || []
            }
            onChange={(value) => {
              other.onChange(value);
              debounceHandleSelectViewingGroup(value);
            }}
            viewingGroupData={viewingGroupData ?? []}
          />
        )}
      />
    </SHStack>
  );
};

export default ManagerViewingGroupSelection;
