import {
  SHAutocomplete,
  SHCheckbox,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { SxProps, Theme } from "@mui/material";
import { isEmpty, isEqual, last } from "lodash";
import React, { useEffect } from "react";
import { SpecialViewingGroup } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

interface SelectViewingGroupsProps {
  value?: ViewingGroupSelectionDTO[];
  onChange: (value: ViewingGroupSelectionDTO[] | null) => void;
  disabled?: boolean;
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  viewingGroupData?: ViewingGroupSelectionDTO[];
  sx?: SxProps<Theme>;
}

const SelectViewingGroups = ({
  disabled,
  value = [],
  onChange,
  viewingGroupData: viewingGroupDataProp = [],
  textFieldProps,
  sx,
}: SelectViewingGroupsProps) => {
  const [selectedViewingGroup, setSelectedViewingGroup] = React.useState<
    ViewingGroupSelectionDTO[]
  >(value.filter((group) => group.isSelected));
  useEffect(() => {
    setSelectedViewingGroup(value.filter((group) => group.isSelected));
  }, [value]);

  const handleOnChange = (event: any, newValue: ViewingGroupSelectionDTO[]) => {
    const allSuitabilityHubGroup = viewingGroupDataProp.find(
      (group) => group.name === SpecialViewingGroup.AllSuitabilityHubUsers,
    );

    let filteredNewValue: ViewingGroupSelectionDTO[] = [];

    if (isEmpty(newValue)) {
      filteredNewValue = allSuitabilityHubGroup ? [allSuitabilityHubGroup] : [];
    } else {
      const lastSelected = last(newValue);

      if (lastSelected?.id === allSuitabilityHubGroup?.id) {
        filteredNewValue = allSuitabilityHubGroup
          ? [allSuitabilityHubGroup]
          : [];
      } else {
        filteredNewValue = newValue.filter(
          (group) => group.id !== allSuitabilityHubGroup?.id,
        );
      }
    }

    const updatedViewingGroupData = viewingGroupDataProp.map((group) => ({
      ...group,
      isSelected: filteredNewValue.some(
        (selected) => selected?.id === group.id,
      ),
    }));

    // Update parent and local state
    onChange(updatedViewingGroupData);
    setSelectedViewingGroup(
      updatedViewingGroupData.filter((group) => group.isSelected),
    );
  };
  const renderLabel = () => {
    if (isEmpty(selectedViewingGroup)) {
      return null;
    }
    if (
      !isEmpty(selectedViewingGroup) &&
      isEqual(
        selectedViewingGroup[0].name,
        SpecialViewingGroup.AllSuitabilityHubUsers,
      )
    ) {
      return (
        <SHTypography
          disabled={disabled}
          variant="body1"
          sx={{ paddingLeft: "10px" }}
        >
          Everyone
        </SHTypography>
      );
    }
    return (
      <SHTypography
        disabled={disabled}
        variant="body1"
        sx={{ paddingLeft: "10px" }}
      >
        {`${selectedViewingGroup?.length}/${viewingGroupDataProp.length} selected`}
      </SHTypography>
    );
  };

  return (
    <SHAutocomplete
      sx={sx}
      multiple
      disabled={disabled}
      value={selectedViewingGroup}
      options={viewingGroupDataProp}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      textFieldProps={{
        ...textFieldProps,
        InputLabelProps: { shrink: true },
        placeholder: value?.length ? undefined : textFieldProps?.placeholder,
        InputProps: {
          startAdornment: renderLabel(),
        },
      }}
      onChange={(event, newValue) => {
        const actualValue = newValue.map((item) => {
          return {
            ...item,
            isSelected: true,
          };
        });

        handleOnChange(event, actualValue);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <SHStack alignItems={"center"} direction="row" spacing={1}>
            <SHCheckbox checked={selected} />
            <SHTypography variant="body3" fontWeight={500}>
              {option.name}
            </SHTypography>
          </SHStack>
        </li>
      )}
    />
  );
};

export default SelectViewingGroups;
