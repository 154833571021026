import SelectSupplier from "@components/auto-completes/supplier";
import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import CustomUnsavedDialog from "@components/dialogs/custom-unsaved";
import {
  CreateViewingGroupDTO,
  ViewingGroupDTO,
} from "@models/product/investment-product/entities/viewingGroup";
import { DialogContent, DialogTitle } from "@mui/material";
import { theme } from "@themes";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
interface ViewingGroupDialogProps {
  isOpen: boolean;
  dialogTitle: string;
  initialData: ViewingGroupDTO | null;
  onClose: () => void;
  buttonLabel?: string;
}
const ViewingGroupDialog = ({
  isOpen,
  dialogTitle,
  initialData,
  onClose,
  buttonLabel = "Save changes and publish viewing group",
}: ViewingGroupDialogProps) => {
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
  const formMethod = useForm<ViewingGroupDTO>({
    mode: "onChange",
    defaultValues: {
      ...initialData,
    },
  });
  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = formMethod;
  const onDiscard = () => {
    setOpenUnsavedDialog(false);
    onClose();
  };
  const onKeepEditing = () => {
    setOpenUnsavedDialog(false);
  };
  const handleOnSubmit = (data: ViewingGroupDTO) => {
    const createViewingGroupDto: CreateViewingGroupDTO = {
      name: data.name,
      supplierIds: data.suppliers?.map((s) => s.id),
    };
    console.log(createViewingGroupDto);
  };
  useEffect(() => {
    reset({
      ...initialData,
    });
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <form>
      <SHDialog
        open={isOpen}
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: "642px",
            height: "auto",
            padding: { md: "20px 60px", lg: "30px 60px" },
            position: "relative",
          },
        }}
      >
        <DialogTitle
          borderBottom={`1px solid ${theme.palette.secondary[100]}`}
          padding={"0 !important"}
        >
          <SHTypography
            variant="h2"
            fontWeight={600}
            lineHeight={"100%"}
            sx={{
              paddingBottom: "10px",
            }}
          >
            {dialogTitle}
          </SHTypography>
        </DialogTitle>
        <SHStack
          sx={{
            position: "absolute",
            top: "22px",
            right: "25px",
          }}
        >
          <CloseButton
            width="12px"
            height="12px"
            onClick={() => {
              if (isDirty) {
                setOpenUnsavedDialog(true);
                return;
              }
              onClose();
            }}
          />
        </SHStack>
        <DialogContent>
          <SHStack spacing={3}>
            <Controller
              control={control}
              name="name"
              rules={{ required: "Viewing group name is required" }}
              render={({ field }) => (
                <SHTextField
                  {...field}
                  required
                  fullWidth
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : null}
                  placeholder="Enter viewing group name"
                />
              )}
            />
            <Controller
              control={control}
              name="suppliers"
              render={({ field }) => (
                <SelectSupplier
                  value={field.value}
                  multiple
                  textFieldProps={{
                    placeholder: "Search for suppliers",
                    label: "Suppliers",
                  }}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </SHStack>
          <SHButton
            disabled={!isValid || !isDirty}
            variant="contained"
            size="extraMedium"
            type="submit"
            onClick={handleSubmit(handleOnSubmit)}
            sx={{
              marginTop: { md: "10px", lg: "20px" },
              height: "50px",
              width: "410px",
            }}
          >
            {buttonLabel}
          </SHButton>
        </DialogContent>
      </SHDialog>
      <CustomUnsavedDialog
        open={openUnsavedDialog}
        onCancel={onKeepEditing}
        onConfirm={onDiscard}
      />
    </form>
  );
};

export default ViewingGroupDialog;
