import { SHButton, SHButtonProps } from "@components/design-systems";
import React from "react";
interface DiscardDraftButtonProps extends SHButtonProps {
  investmentProductId?: string;
  isFirstDraft?: boolean;
}
export const DiscardDraftButton = ({
  investmentProductId,
  isFirstDraft,
  ...others
}: DiscardDraftButtonProps) => {
  const handleDiscardDraft = () => {
    console.log("discard draft clicked!!");
  };

  const isVisible = true;

  if (!isVisible) return null;
  return (
    <SHButton
      onClick={handleDiscardDraft}
      {...others}
      variant="outlined"
      size="extraMedium"
    >
      Discard draft
    </SHButton>
  );
};
