import { SHContainer, SHStack } from "@components/design-systems";
import { usePlatformAnalysis } from "@hooks/usePlatformAnalysis";
import { CollapseSubGroup } from "@layouts/platform-analysis/collapse-sub-group";
import { GroupSwiperCarousel } from "@layouts/platform-analysis/group-swiper-carousel";

import { TransferSelectFeatureList } from "@layouts/platform-analysis/transfer-select-feature-list";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import {
  AnalysisConfigurationFeatureSectionDTO,
  AnalysisGetFeatureSectionsDTO,
} from "@models/platform-analysis/entities/steps/feature";
import { ConfirmSelectAllDialog } from "@pages/platform-analysis/_id/steps/feature/selection/components/dialogs/confirm-select-all";
import { FeatureSelectionSkeleton } from "@pages/platform-analysis/_id/steps/feature/selection/skeleton";
import { RootState } from "@redux/store";
import { chain, filter, isEmpty } from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";

export interface FeatureSelectionProps extends StepCompProps {}
export const FeatureSelection: ForwardRefRenderFunction<
  StepCompRef,
  StepCompProps
> = ({ stepNumber }, ref) => {
  const { analysisId } = useParams<{ analysisId: string }>();
  const {
    ui: { isLoading, isDirty, collapsedSubGroupIds },
    featureSelections,
  } = useSelector((state: RootState) => state.platformAnalysis);
  const selections = featureSelections?.sections;

  const [showConfirmSelectAllDialog, setShowConfirmSelectAllDialog] =
    useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");

  const {
    loadFeatureSelections,
    updateFeatureSelections,
    setIsDirty,
    setIsValid,
    setFeatureSelections,
  } = usePlatformAnalysis();

  const selectedGroup = useMemo(() => {
    return selections?.find((group) => group?.isSelected) ?? selections?.[0];
  }, [selections]);

  const hasSelectedFeature = useMemo(() => {
    return !isEmpty(
      filter(
        chain(selections)
          ?.map("subSections")
          ?.flatten()
          ?.map("features")
          ?.flatten()
          ?.value(),
        { isSelected: true },
      ),
    );
  }, [selections]);

  const subGroups = useMemo(() => {
    return selectedGroup?.subSections;
  }, [selectedGroup]);

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      setIsDirty(false);
      return await handleOnSubmit();  
    },
    onSubmit: async () => {
      setIsDirty(false);
      return await handleOnSubmit();
    },
  }));

  const handleOnSubmit = async () => {
    let isSuccess = true;
    const newFeatureSelections = {
      sections: selections,
    } as AnalysisGetFeatureSectionsDTO;
    if (!analysisId) return false;
    if (isDirty) {
      isSuccess = await updateFeatureSelections({
        platformAnalysisId: analysisId,
        featureSelections: newFeatureSelections,
      });
    }

    return isSuccess;
  };

  const loadSelections = async (analysisId?: string) => {
    if (!analysisId || analysisId === "new") return;
    const featureSelections = await loadFeatureSelections({
      platformAnalysisId: analysisId,
    });
    setFeatureSelections(featureSelections?.sections ?? []);
    setIsDirty(featureSelections?.hasRecalculate ?? false);
  };

  useEffect(() => {
    loadSelections(analysisId);
    // eslint-disable-next-line
  }, [analysisId]);

  useEffectOnce(() => {
    return () => {
      setIsValid(true);
      setIsDirty(false);
    };
  });

  useEffect(() => {
    setIsValid(hasSelectedFeature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedFeature]);

  const handleOnSelectGroup = (groupId?: string) => {
    const newGroups = selections?.map((group) => {
      return {
        ...group,
        isSelected: group.id === groupId,
      };
    });
    setFeatureSelections(newGroups ?? []);
    setIsDirty(true);
  };

  // Handle logics
  const handleOnSelectDeselectFeature = (
    subGroupId?: string,
    featureId?: string,
    isDeselect?: boolean,
  ) => {
    const newGroups = selections?.map((group) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature) => {
              if (feature.id !== featureId) return feature;
              return {
                ...feature,
                isSelected: !isDeselect,
              };
            }),
          };
        }),
        totalSelectedFeature: isDeselect
          ? group.totalSelectedFeature - 1
          : group.totalSelectedFeature + 1,
      };
    });
    setFeatureSelections(newGroups ?? []);
    setIsDirty(true);
  };

  const handleOnSelectDeselectAll = (
    subGroupId?: string,
    totalFeatures?: number,
    isDeselect?: boolean,
  ) => {
    const newFeatureGroups = selections?.map((group) => {
      if (group?.id !== selectedGroup?.id) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup) => {
          if (subGroup.id !== subGroupId) return subGroup;
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature) => {
              return {
                ...feature,
                isSelected: !isDeselect,
              };
            }),
          };
        }),
        totalSelectedFeature: !isDeselect
          ? group.totalSelectedFeature + (totalFeatures ?? 0)
          : group.totalSelectedFeature - (totalFeatures ?? 0),
      };
    });
    setFeatureSelections(newFeatureGroups ?? []);
    setIsDirty(true);
  };

  const handleOnSelectDeselectAllGroupFeatures = (groupId?: string) => {
    setSelectedGroupId(groupId ?? "");

    if (selectedGroup?.totalSelectedFeature !== selectedGroup?.totalFeature) {
      if (selectedGroup?.totalSelectedFeature !== 0) {
        setShowConfirmSelectAllDialog(true);
      } else {
        handleOnChangeSelectedAllGroupFeature(true, groupId);
      }
    } else {
      handleOnChangeSelectedAllGroupFeature(false, groupId);
    }
    setIsDirty(true);
  };

  const handleOnChangeSelectedAllGroupFeature = (isSelected?: boolean, groupId?: string) => {
    const newGroups = selections?.map((group) => {
      if (group?.id !== groupId) return group;
      return {
        ...group,
        subSections: group?.subSections?.map((subGroup) => {
          return {
            ...subGroup,
            features: subGroup?.features?.map((feature) => {
              return {
                ...feature,
                isSelected: isSelected,
              };
            }),
          };
        }),
        totalSelectedFeature: isSelected ? group.totalFeature : 0,
      };
    }) as AnalysisConfigurationFeatureSectionDTO[];
    setFeatureSelections(newGroups);
  };

  // Handle renders
  const renderGroupCarousel = () => {
    return (
      <GroupSwiperCarousel
        groups={selections}
        handleOnSelect={handleOnSelectGroup}
        handleOnSelectDeselectAll={handleOnSelectDeselectAllGroupFeatures}
      />
    );
  };

  const renderSubGroupCollapse = () => {
    return subGroups?.map((subGroup, index) => (
      <CollapseSubGroup
        key={index}
        subSection={subGroup}
        isExpanded={
          !collapsedSubGroupIds?.find((item) => item === subGroup?.id)
        }
      >
        <TransferSelectFeatureList
          marginY={3}
          subGroupId={subGroup.id}
          features={subGroup?.features}
          handleOnSelectDeselect={handleOnSelectDeselectFeature}
          handleOnSelectDeselectAll={handleOnSelectDeselectAll}
        />
      </CollapseSubGroup>
    ));
  };

  if (isLoading) return <FeatureSelectionSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <StepName name={"Feature selection"} stepNumber={stepNumber} />
      <SHStack marginY={3}>
        {renderGroupCarousel()}
        {renderSubGroupCollapse()}
        {showConfirmSelectAllDialog && (
          <ConfirmSelectAllDialog
            totalSelectedFeature={selectedGroup?.totalSelectedFeature}
            onCancel={() => setShowConfirmSelectAllDialog(false)}
            onSelectAll={() => {
              handleOnChangeSelectedAllGroupFeature(true, selectedGroupId);
              setShowConfirmSelectAllDialog(false);
            }}
          />
        )}
      </SHStack>
    </SHContainer>
  );
};
