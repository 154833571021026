import { SHButton, SHButtonProps } from "@components/design-systems";
import React from "react";
import { useToggle } from "react-use";
import PublishDraftDialog from "@pages/suppliers/_id/_products/_id/components/dialogs/publish-draft";

interface InvestmentProductApproveButtonProps extends SHButtonProps {
  isApproving?: boolean;
  onApprove: () => Promise<void>;
}

export const InvestmentProductApproveButton = ({
  isApproving,
  onApprove,
  ...others
}: InvestmentProductApproveButtonProps) => {
  const [showDialog, toggleShowDialog] = useToggle(false);

  const handleApprove = async () => {
    await onApprove();
    toggleShowDialog(false);
  };

  return (
    <>
      <SHButton
        {...others}
        onClick={() => toggleShowDialog(true)}
        variant="contained"
        size="extraMedium"
      >
        Approve
      </SHButton>

      {showDialog && (
        <PublishDraftDialog
          isSubmitting={isApproving}
          onClose={() => toggleShowDialog(false)}
          onSubmit={handleApprove}
        />
      )}
    </>
  );
};
