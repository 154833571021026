import {
  SHLabel,
  SHLabelProps,
  SHStack,
  SHTextFieldProps,
} from "@components/design-systems";
import { FormHelperText, SxProps, Theme, useTheme } from "@mui/material";
import { Box as SHBox } from "@mui/system";
import { generateUUID } from "@utils";
import { useCallback, useMemo, useRef, useState } from "react";
import ReactQuill, { UnprivilegedEditor } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  CloneReactQuillProps,
  ReactQuillStyled,
  ToolbarEditorStyled,
} from "./components/ReactQuillStyled";
import { CommentEditorFormats, EditorFormats } from "./constant";
export interface SHRichTextEditorProps
  extends Omit<CloneReactQuillProps, "onChange"> {
  /**
   * The height of editor.
   */
  height?: number;
  /**
   * The maximum letters of editor.
   * @default 500
   */
  maxLength?: number;
  /**
   * The placeholder of editor.
   */
  placeHolder?: string;
  sx?: SxProps<Theme> | undefined;
  fullWidth?: boolean;
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  onChange?: (value: string) => void;
  isCommentBox?: boolean;
}

export const SHRichTextEditor = ({
  height = 200,
  maxLength = 500,
  placeHolder = "",
  label,
  required = false,
  postfixLabel,
  disabled,
  readOnly,
  dotGroupProps,
  sx,
  textFieldProps,
  onChange,
  isCommentBox = false,
  fullWidth = false,
  ...props
}: SHRichTextEditorProps & SHLabelProps) => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const [counter, setCounter] = useState<number>(0);
  const reactQuillRef = useRef<ReactQuill | undefined>();

  const id = useRef(`sh-rte-${generateUUID().replace(/-/g, "")}`).current;

  const getLength = useCallback(
    (unprivilegedEditor?: ReactQuill.UnprivilegedEditor) => {
      if (!unprivilegedEditor) return 0;
      const currentText = unprivilegedEditor.getText();
      return (
        unprivilegedEditor.getLength() -
        (currentText.length && currentText[currentText.length - 1] === "\n"
          ? 1
          : 0)
      );
    },
    [],
  );

  const handleOnChange = useCallback(
    (
      value: string,
      delta: any,
      source: "api" | "user" | "silent",
      editor: UnprivilegedEditor,
    ) => {
      if (source !== "user") return;
      let newValue = value;
      const currentLength = getLength(
        reactQuillRef.current?.unprivilegedEditor,
      );
      if (currentLength > maxLength && reactQuillRef.current?.editor) {
        reactQuillRef.current.editor.deleteText(maxLength, currentLength);
        newValue = reactQuillRef.current?.unprivilegedEditor?.getHTML() ?? "";
      }
      newValue = newValue === "<p><br></p>" ? "" : newValue;
      if (onChange) onChange(newValue);
      setValue(newValue);
    },
    [onChange, getLength, maxLength],
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML
        matchVisual: false,
      },
    }),
    [id],
  );
  const disabledColor = useMemo(
    () =>
      disabled || readOnly
        ? `${theme.palette.text.disabled} !important`
        : undefined,
    [theme.palette.text.disabled, disabled, readOnly],
  );

  return (
    <SHStack>
      <SHBox
        className="text-editor"
        data-testid="sh-richtexteditor"
        sx={{
          width: {
            xs: "100%",
            sm: !fullWidth ? 520 : "100%",
            md: !fullWidth ? 520 : "100%",
          },
        }}
      >
        <SHLabel
          label={label}
          disabled={disabled || readOnly}
          postfixLabel={postfixLabel}
          required={required}
          dotGroupProps={dotGroupProps}
        />
        <ReactQuillStyled
          theme={theme}
          ref={(ref?: ReactQuill) => {
            setCounter(getLength(ref?.unprivilegedEditor));
            reactQuillRef.current = ref;
          }}
          style={{ height }}
          value={value}
          onChange={handleOnChange}
          modules={modules}
          placeholder={placeHolder}
          formats={isCommentBox ? CommentEditorFormats : EditorFormats}
          readOnly={disabled || readOnly}
          sx={sx}
          //preserveWhitespace //Rollback to fix issue with MS Word
          {...props}
        />
        <ToolbarEditorStyled
          theme={theme}
          id={id}
          count={counter}
          maxLength={maxLength}
          sx={{
            "& .ql-snow.ql-toolbar button": {
              "& .ql-fill": {
                fill: disabledColor,
              },
              "& .ql-stroke": {
                stroke: disabledColor,
              },
            },
          }}
          isCommentBox={isCommentBox}
        />
      </SHBox>
      {textFieldProps?.helperText && (
        <FormHelperText error={textFieldProps.error}>
          {textFieldProps.helperText}
        </FormHelperText>
      )}
    </SHStack>
  );
};
