import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants";
import { email } from "@constants/email";
import { useEditProduct } from "@hooks/useEditProduct";
import { useEditInvestmentProduct } from "@hooks/useEditInvestmentProduct";
import { useNotification } from "@hooks/useNotification";
import { HubConnectionState } from "@microsoft/signalr";
import { DefaultSocketErrorMessage } from "@models/core";
import { Link, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import {
  updateEditMode as updatePlatformEditMode,
  updateLoadingEditMode as updatePlatformLoadingEditMode,
  updateShowClaimDeniedDialog as updatePlatformShowClaimDeniedDialog,
} from "@redux/slices/product";
import {
  updateEditMode as updateInvestmentProductEditMode,
  updateLoadingEditMode as updateInvestmentProductLoadingEditMode,
  updateShowClaimDeniedDialog as updateInvestmentProductShowClaimDeniedDialog,
} from "@redux/slices/product/investment-product";
import { SignalRService } from "@services/signalr/signalRService";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { ProductType } from "@models/product/enums/productType";

const ACTION_TYPES = {
  Platform: {
    updateEditMode: updatePlatformEditMode.type,
    updateLoadingEditMode: updatePlatformLoadingEditMode.type,
    updateShowClaimDeniedDialog: updatePlatformShowClaimDeniedDialog.type,
  },
  SMA: {
    updateEditMode: updateInvestmentProductEditMode.type,
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowClaimDeniedDialog: updateInvestmentProductShowClaimDeniedDialog.type,
  },
  MDA: {
    updateEditMode: updateInvestmentProductEditMode.type,
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowClaimDeniedDialog: updateInvestmentProductShowClaimDeniedDialog.type,
  },
} as const;

interface ProductStateActions {
  updateEditMode: (payload: boolean) => void;
  updateLoadingEditMode: (payload: boolean) => void;
  updateShowClaimDeniedDialog: (payload: boolean) => void;
}

interface ProductConfig {
  getShowClaimDeniedDialog: (state: RootState) => boolean | undefined;
  actionTypes: {
    updateEditMode: string;
    updateLoadingEditMode: string;
    updateShowClaimDeniedDialog: string;
  };
  actionCreators: {
    updateEditMode: typeof updatePlatformEditMode | typeof updateInvestmentProductEditMode;
    updateLoadingEditMode: typeof updatePlatformLoadingEditMode | typeof updateInvestmentProductLoadingEditMode;
    updateShowClaimDeniedDialog: typeof updatePlatformShowClaimDeniedDialog | typeof updateInvestmentProductShowClaimDeniedDialog;
  };
  getNavigationPath: (supplierId: string, productId: string) => string;
}

const PRODUCT_CONFIG: Record<ProductType, ProductConfig> = {
  [ProductType.Platform]: {
    getShowClaimDeniedDialog: (state: RootState) => state.product.productUI?.showClaimDeniedDialog,
    actionTypes: ACTION_TYPES.Platform,
    actionCreators: {
      updateEditMode: updatePlatformEditMode,
      updateLoadingEditMode: updatePlatformLoadingEditMode,
      updateShowClaimDeniedDialog: updatePlatformShowClaimDeniedDialog,
    },
    getNavigationPath: (supplierId: string, productId: string) =>
      generatePath(PageRoutes.supplierProducts, { supplierId, productId }),
  },
  [ProductType.SMA]: {
    getShowClaimDeniedDialog: (state: RootState) => state.investmentProduct.investmentProductUI?.showClaimDeniedDialog,
    actionTypes: ACTION_TYPES.SMA,
    actionCreators: {
      updateEditMode: updateInvestmentProductEditMode,
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowClaimDeniedDialog: updateInvestmentProductShowClaimDeniedDialog,
    },
    getNavigationPath: (supplierId: string, investmentProductId: string) =>
      generatePath(PageRoutes.investmentProductsDetail, { supplierId, investmentProductId }),
  },
  [ProductType.MDA]: {
    getShowClaimDeniedDialog: (state: RootState) => state.investmentProduct.investmentProductUI?.showClaimDeniedDialog,
    actionTypes: ACTION_TYPES.MDA,
    actionCreators: {
      updateEditMode: updateInvestmentProductEditMode,
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowClaimDeniedDialog: updateInvestmentProductShowClaimDeniedDialog,
    },
    getNavigationPath: (supplierId: string, investmentProductId: string) =>
      generatePath(PageRoutes.investmentProductsDetail, { supplierId, investmentProductId }),
  },
};

const PRODUCT_CLAIMING_METHODS = (
  handlePlatformOnClaimingEditingPermission: (productId: string, callback?: () => void) => Promise<void>,
  handleInvestmentProductOnClaimingEditingPermission: (productId: string, callback?: () => void) => Promise<void>
) => ({
  [ProductType.Platform]: {
    claimMethod: handlePlatformOnClaimingEditingPermission,
  },
  [ProductType.SMA]: {
    claimMethod: handleInvestmentProductOnClaimingEditingPermission,
  },
  [ProductType.MDA]: {
    claimMethod: handleInvestmentProductOnClaimingEditingPermission,
  },
});

export interface ConfirmKickOutAdminDialogProps {
  supplierId?: string;
  productId?: string;
  productType?: ProductType;
}

// TODO: Temporary fix for 20/1 release
export const ConfirmKickOutAdminDialog = ({
  supplierId,
  productId,
  productType,
}: ConfirmKickOutAdminDialogProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const { palette } = useTheme();
  const { handleOnClaimingEditingPermission: handlePlatformOnClaimingEditingPermission } = useEditProduct();
  const { handleOnClaimingEditingPermission: handleInvestmentProductOnClaimingEditingPermission } = useEditInvestmentProduct();
  const navigate = useNavigate();
  const { notify } = useNotification();
  productType ??= ProductType.Platform;

  const productClaimingMethods = useMemo(() =>
      PRODUCT_CLAIMING_METHODS(
        handlePlatformOnClaimingEditingPermission,
        handleInvestmentProductOnClaimingEditingPermission
      ),
    [handlePlatformOnClaimingEditingPermission, handleInvestmentProductOnClaimingEditingPermission]
  );

  const productConfig = PRODUCT_CONFIG[productType];

  const productActions: ProductStateActions = {
    updateEditMode: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateEditMode, payload: payload }),
    updateLoadingEditMode: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateLoadingEditMode, payload: payload }),
    updateShowClaimDeniedDialog: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateShowClaimDeniedDialog, payload: payload })
  };

  const showClaimDeniedDialog = useSelector(productConfig.getShowClaimDeniedDialog);

  const handleOnClose = () => {
    productActions.updateShowClaimDeniedDialog(false);
    productActions.updateLoadingEditMode(false);
    productActions.updateEditMode(false);
    setIsLoading(false);
  };

  const handleOnClickYes = async () => {
    setIsLoading(true);
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      const productClaimingMethod = productClaimingMethods[productType as ProductType];
      await productClaimingMethod.claimMethod(productId as string, () => {
        productActions.updateEditMode(true);
        navigate(
          productConfig.getNavigationPath(supplierId as string, productId as string)
        );
      });
      setIsLoading(false);
      productActions.updateShowClaimDeniedDialog(false);
    } else {
      ///else show error here
      setIsLoading(false);
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };
  return (
    <SHDialog
      open={showClaimDeniedDialog || false}
      onClose={handleOnClose}
      width={645}
      disableBackdropClick
      disableEscapeKeyDown
      header={
        <SHTypography variant="h2">
          Profile temporarily locked. <br />
          We are touching up your profile
        </SHTypography>
      }
      body={
        <SHStack>
          <SHTypography
            variant="body2"
            sx={{
              width: 430,
            }}
          >
            Hi {user?.name}, someone from the SuitabilityHub admin team is
            currently making updates to your product profile. We only allow one
            user to edit the Profile at one time.
          </SHTypography>
          <br />
          <SHTypography
            variant="body2"
            sx={{
              width: 430,
            }}
          >
            We endeavour to make our changes quickly to limit any inconvenience
            to you. Please check back in half an hour or contact us
            <Link
              variant="body2"
              color={palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                paddingLeft: 0.5,
                cursor: "pointer",
              }}
              href={`mailto:${email.support}`}
            >
              here
            </Link>
            .
          </SHTypography>
          <br />
          <SHTypography
            variant="body2"
            sx={{
              width: 430,
            }}
          >
            Alternatively, you can kick us out. Proceed with kicking out
            SuitabilityHub admin?
          </SHTypography>
        </SHStack>
      }
      footer={
        <SHStack
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={7}
        >
          <SHButton
            color="primary"
            variant="text"
            disabled={isLoading}
            onClick={handleOnClose}
            size="extraMedium"
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleOnClickYes}
            size="extraMedium"
          >
            Yes, kick them out
          </SHButton>
        </SHStack>
      }
    />
  );
};
