import { InformationButton } from "@components/buttons/information";
import { SHLabel, SHStack, SHTypography } from "@components/design-systems";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { DateFormat } from "@constants/format";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import {
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import {
  DEFAULT_MIN_DATE_TIME,
  INVALID_DATE,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { RootState } from "@redux/store";
import { format, isValid, lastDayOfYear, parse } from "date-fns";
import { isEmpty, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
interface ModelFeatureDatePickerProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationModelsFeature;
}
export const FeatureDatePicker = ({
  feature,
  selectedIndex,
  sectionIndex,
  featureIndex,
}: ModelFeatureDatePickerProps) => {
  const originalValue = feature.productData?.value;
  /** Hook */
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );

  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });

  /** Handler */
  const handleDatePickerChange = (inputDateValue: Date | null) => {
    if (!isValid(inputDateValue) && inputDateValue != null) return;
    const inputDateStr = inputDateValue
      ? format(inputDateValue, DateFormat)
      : null;

    setValue(
      featureProductDataPath.isModified,
      !isEqual(inputDateStr, originalValue) &&
        !isEqual(inputDateStr, INVALID_DATE),
    );
    setValue(
      featurePath.isModified,
      !isEqual(inputDateStr, originalValue) &&
        !isEqual(inputDateStr, INVALID_DATE),
    );
    if (isEmpty(inputDateStr)) {
      setValue(featureProductDataPath.value, null);
      setValue(
        featureProductDataPath.dataStatus,
        InvestmentDataStatus.MissingData,
      );
      setValue(featurePath.dataStatus, InvestmentDataStatus.MissingData);
    } else {
      setValue(featureProductDataPath.dataStatus, InvestmentDataStatus.Filled);
      setValue(featurePath.dataStatus, InvestmentDataStatus.Filled);
    }
    const publishedValue = getValues(featureProductDataPath.publishedValue);
    if (!isEqual(publishedValue, inputDateStr)) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    setValue(featureProductDataPath.value, inputDateStr);
    handleUpdateSectionData();
  };

  return (
    <Controller
      name={featureProductDataPath["value"]}
      control={control}
      render={({ field: { ref, ...other }, fieldState: { error } }) => {
        const value = getValues(featureProductDataPath["value"]);
        let date: Date | null = parse(String(value), DateFormat, new Date());
        if (!isValid(date)) {
          date = null;
        }
        return (
          <SHStack>
            <SHLabel
              dotGroupProps={{
                orangeDot: isShowOrangeDot,
                blueDot: isShowBlueDot,
                greenDot: feature.isBusinessMetric,
              }}
              label={
                <SHTypography variant="subtitle2">
                  {feature?.name}
                  {!isEmpty(feature.description) && (
                    <InformationButton content={feature.description} />
                  )}
                </SHTypography>
              }
            />
            <SHDatePicker
              {...other}
              value={date}
              openOnClick
              InputProps={{
                sx: {
                  // 100% width - (text editor width + text editor gap width)
                  // make sure equal width with the text editor description
                  width: {
                    sm: "100%",
                    md: "50%",
                    lg: "calc(100% - 552px)",
                  },
                },
              }}
              disabled={isSaving || isPendingApproval}
              maxDate={lastDayOfYear(new Date())}
              views={["year", "month", "day"]}
              minDate={DEFAULT_MIN_DATE_TIME}
              onChange={(newValue) => {
                handleDatePickerChange(newValue);
              }}
              inputFormat={DateFormat}
            />
          </SHStack>
        );
      }}
    />
  );
};
