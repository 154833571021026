import {
  FeatureGroupCard,
  FeatureGroupCardProps,
} from "@layouts/platform-analysis/feature-group-card";

import { AnalysisConfigurationFeatureSectionDTO } from "@models/platform-analysis/entities/steps/feature";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { ArrowButton } from "@components/buttons/arrow";
import { SHBox, SHStack } from "@components/design-systems";
import { useMediaQuery, useTheme } from "@mui/material";
import { generateUUID, hexToRGBA } from "@utils";
import { useState } from "react";

export interface GroupCarouselProps {
  specifyClass?: string;
  paginationClass?: string;
  groups?: AnalysisConfigurationFeatureSectionDTO[];
}

export const GroupSwiperCarousel = ({
  groups,
  handleOnSelect,
  handleOnSelectDeselectAll,
  specifyClass = `a${generateUUID()}`,
  paginationClass = `a${generateUUID()}`,
  ...others
}: GroupCarouselProps & FeatureGroupCardProps) => {
  const { palette, breakpoints } = useTheme();
  const [initSwiper, setInitSwiper] = useState(true);
  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const isSmallerScreen = useMediaQuery(breakpoints.down("lg2"));

  return (
    <SHStack
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={1.5}
    >
      <ArrowButton
        className={`btnPrev-${specifyClass}`}
        direction="previous"
        buttonHeight="40px"
        buttonWidth="40px"
        iconWidth="13.3px"
        iconHeight="13.3px"
        hidden={!carouselLoaded || isSmallerScreen}
      />
      <SHBox
        sx={{
          position: "relative",

          width: "100%",
          paddingTop: "15px",
          paddingLeft: "15px",
          paddingRight: "15px",
          backgroundColor: hexToRGBA(palette.secondary[200], 0.1),
          border: `1px solid ${palette.secondary[100]}`,
          backdropFilter: "blur(0.5px)",
          "&>.swiper": {
            paddingBottom: "6px",
            paddingLeft: "40px",
            paddingRight: "40px",
          },
          "&>.swiper>.swiper-wrapper>.swiper-slide": {
            height: "auto",
          },
        }}
      >
        <Swiper
          loop
          centeredSlides
          centeredSlidesBounds
          slideToClickedSlide
          spaceBetween={15}
          slidesPerView={2.78}
          modules={[Navigation, Pagination]}
          //add swiper custom navigation
          navigation={{
            prevEl: `.btnPrev-${specifyClass}, .btnPrev-secondary-${specifyClass}`,
            nextEl: `.btnNext-${specifyClass}, .btnNext-secondary-${specifyClass}`,
          }}
          //add swiper custom pagination
          pagination={{
            el: `.swiper-pagination-${specifyClass}`,
            type: "bullets",
            clickable: true,
          }}
          onSlideChange={(swiper) => {
            if (initSwiper) {
              const selectedGroupIndex =
                groups?.findIndex((group) => group?.isSelected) ?? 0;
              swiper.slideToLoop(
                selectedGroupIndex > 0 ? selectedGroupIndex : 0,
              );

              setInitSwiper(false);
              setCarouselLoaded(true);
            } else {
              if (typeof handleOnSelect === "function")
                handleOnSelect(groups && groups[swiper?.realIndex].id);
            }
          }}
        >
          {groups?.map((group, index) => (
            <SwiperSlide key={index}>
              <FeatureGroupCard
                key={index}
                group={group}
                handleOnSelect={handleOnSelect}
                handleOnSelectDeselectAll={handleOnSelectDeselectAll}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <SHBox
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: "16px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            padding: "13px 0",
            width: "234px",
            margin: "auto",
          }}
        >
          <ArrowButton
            className={`btnPrev-secondary-${specifyClass}`}
            direction="previous"
            hidden={!carouselLoaded}
          />
          <SHBox
            className={`${paginationClass} swiper-pagination-${specifyClass}`}
            sx={{
              [`&.${paginationClass}`]: {
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                columnGap: "8px",
                "&>.swiper-pagination-bullet": {
                  display: "inline-block",
                  backgroundColor: "transparent",
                  transition: "all 0.2s ease",
                  width: "10px",
                  height: "10px",
                  border: `1px solid ${palette.text.disabled}`,
                  opacity: 1,
                  columnGap: "8px",
                  cursor: "pointer",

                  "&-active": {
                    backgroundColor: palette.text.third,
                    width: "28px",
                    borderRadius: "24px",
                  },
                },
              },
            }}
          ></SHBox>
          <ArrowButton
            className={`btnNext-secondary-${specifyClass}`}
            direction="next"
            hidden={!carouselLoaded}
          />
        </SHBox>
      </SHBox>
      <ArrowButton
        className={`btnNext-${specifyClass}`}
        direction="next"
        buttonHeight="40px"
        buttonWidth="40px"
        iconWidth="13.3px"
        iconHeight="13.3px"
        hidden={!carouselLoaded || isSmallerScreen}
      />
    </SHStack>
  );
};
