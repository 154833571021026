import { SHLabel } from "@components/design-systems/sh-label";
import { SHStack } from "@components/design-systems/sh-stack";
import { SHTextFieldProps } from "@components/design-systems/sh-text-field";
import { SHTypography } from "@components/design-systems/sh-typography";
import { TextField } from "@mui/material";
import { theme } from "@themes";
import { isEmpty } from "lodash";
import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

export type SHNumericFieldV2Props = Omit<SHTextFieldProps, "value" | "onChange"> &
  NumericFormatProps & {
    min?: number;
    max?: number;
  };
/**
 *
 * For more details, visit {@link https://s-yadav.github.io/react-number-format/docs/props react-number-format docs }.
 * @important when pass the value is string, be sure valueIsNumericString prop is true
 * @important the NumericFormat package has a bug when set null state to value props (not through onChange),
 * can be fix using `value={value ?? ""}` , view this issue {@link https://github.com/s-yadav/react-number-format/issues/727}
 */
export const SHNumericFieldV2 = React.forwardRef<
  HTMLDivElement,
  SHNumericFieldV2Props
>((props, ref) => {
  const {
    required,
    postfixLabel,
    label,
    sx,
    disabled,
    helperText,
    hasCounter,
    maxLength,
    dotGroupProps,
    inputProps,
    value,
    customCounter,
    actionButton,
    min,
    max,
    ...others
  } = props;
  return (
    <NumericFormat
      {...others}
      sx={{
        "&>.MuiInput-root": {
          marginTop: label ? "5px" : 0,
        },
        ...sx,
      }}
      isAllowed={
        others.isAllowed
          ? others.isAllowed
          : (values) => {
              const { floatValue } = values;
              if (floatValue === undefined) return true;
              if (min !== undefined && floatValue < min) return false;
              if (max !== undefined && floatValue > max) return false;
              return true;
            }
      }
      helperText={
        !isEmpty(helperText) || hasCounter ? (
          <SHStack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            {!isEmpty(helperText) && (
              <SHTypography
                variant="body2"
                sx={{
                  padding: 0,
                  fontSize: "0.75rem",
                  color: `${theme.palette.error.light} !important`,
                }}
              >
                {helperText}
              </SHTypography>
            )}
            {hasCounter && (
              <SHTypography
                variant="body4"
                colorVariant="third"
                sx={{
                  paddingRight: 1,
                  whiteSpace: "nowrap",
                }}
              >
                {!isNaN(Number(value)) ? value?.toString().length : 0}/
                {maxLength}
              </SHTypography>
            )}
            {customCounter}
          </SHStack>
        ) : undefined
      }
      label={
        label ? (
          <SHLabel
            label={label}
            disabled={disabled}
            postfixLabel={postfixLabel}
            required={required}
            dotGroupProps={dotGroupProps}
            actionButton={actionButton}
          />
        ) : undefined
      }
      disabled={disabled}
      customInput={TextField}
      getInputRef={ref}
      inputProps={{
        ...inputProps,
        maxLength: maxLength,
      }}
      value={value}
    />
  );
});
