import {
  TopNotificationItem,
  ProductBannerType,
  TopNotificationType,
} from "@components/top-notifications/model";
import {
  ProductListDTO,
  ProductDataBannerInfoDTO,
} from "@models/product/entities/product";
import {
  createProduct,
  loadGroups,
  loadProduct,
  loadProductBannerInfo,
  updateProduct,
  loadProductHistory,
  updateProductBannerInfo,
  loadProductReleaseLog,
  loadSubProducts,
  loadNewSubProduct,
  updateSubProducts,
  loadSubProductReleaseLog,
  loadExistingFeeScheduleThunk,
  loadSubProductThunk,
  updateSubProductHighlightTypeMapping,
  loadProductBrandingData,
} from "@redux/slices/product";
import { updateProductProfileNotifications } from "@redux/slices/top-notifications";
import { RootState, useAppDispatch } from "@redux/store";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useNotification } from "./useNotification";
import { LocalStorage } from "@constants";
import { useLocalStorage } from "./useLocalStorage";
import { DateFormat, TimeFormat } from "@constants/format";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { generateSubProductHighlightTypeMapping } from "@redux/slices/product/util";
import { useUser } from "./useUser";
import { isEmpty } from "lodash";

export const useProduct = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const {
    productData,
    productUI: { selectedGroup },
    productGroups,
  } = useSelector((state: RootState) => state.product);
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();

  const { user } = useUser();

  const productBannerItemKey = `${LocalStorage.productBannerInfo}+${user?.auth0Id}`;

  const updateProductGroups = async (
    productId?: string,
    version?: string,
    configurationVersion?: string,
  ) => {
    const response = await dispatch(
      loadGroups({ productId, version, configurationVersion }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  //@NOTE: Call after do any action in product
  const loadBannerInfo = async (productId: string) => {
    const response = await dispatch(loadProductBannerInfo(productId)).unwrap();
    if (response.isSuccess) {
      if (response.data) {
        const bannerInfo = response.data as ProductDataBannerInfoDTO[];
        dispatch(updateProductBannerInfo(bannerInfo));
        const notifications = bannerInfo?.map(
          (item: ProductDataBannerInfoDTO) => {
            let notification = {} as TopNotificationItem;
            const lastModifiedDate = item.lastModifiedDate ?? new Date();
            switch (item.status) {
              case "Draft":
                notification = {
                  ...notification,
                  title:
                    "Draft in progress. Please follow orange dots to find the changes.",
                  productBannerType: ProductBannerType.Draft,
                  message: `Last updated by ${
                    item.lastUpdatedUserName
                  } at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Missing data":
                notification = {
                  ...notification,
                  title:
                    "One or more fields are missing data. Please follow the blue dots.",
                  productBannerType:
                    ProductBannerType.MissingData,
                  message: `We added or modified fields at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}. Please follow the blue dots to complete the missing fields.`,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Pending approval":
                notification = {
                  ...notification,
                  title: "This platform profile is frozen.",
                  productBannerType: ProductBannerType.Pending,
                  message: `The SuitabilityHub team is now reviewing the changes that were were submitted at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}. Please contact us if you have any questions.`,
                  type: TopNotificationType.Warning,
                };
                break;
              case "Approved":
                notification = {
                  ...notification,
                  title: "Latest submission was approved.",
                  productBannerType: ProductBannerType.Approved,
                  message: `Profile approved at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  type: TopNotificationType.Success,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Rejected":
                notification = {
                  ...notification,
                  title:
                    "Latest submission was not accepted and not published.",
                  productBannerType: ProductBannerType.Rejected,
                  message: `Profile reverted back to draft at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  type: TopNotificationType.Error,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
            }
            return notification;
          },
        );

        const productBannerInfo = getLocalStorageItem(productBannerItemKey);

        if (productBannerInfo) {
          if (!Object.keys(productBannerInfo).includes(productId)) {
            setLocalStorageItem(productBannerItemKey, {
              ...productBannerInfo!,
              [productId]: notifications
                .filter((notification) =>
                  Object.keys(notification).includes("productId"),
                )
                .map((notification) => {
                  return {
                    ...notification,
                    show: true,
                  };
                }),
            });
          } else {
            const newNotifications = notifications.filter((notification) =>
              Object.keys(notification).includes("productId"),
            );
            if (newNotifications.length > 0) {
              setLocalStorageItem(productBannerItemKey, {
                ...productBannerInfo!,
                [productId]: newNotifications.map((notification) => {
                  return {
                    ...notification,
                    show: isShowProductBanner(
                      newNotifications,
                      notification,
                      productBannerInfo[productId],
                      notification.productBannerType,
                    ),
                  };
                }),
              });
            }
          }
        } else {
          setLocalStorageItem(productBannerItemKey, {
            [productId]: notifications
              .filter((notification) =>
                Object.keys(notification).includes("productId"),
              )
              .map((notification) => ({
                ...notification,
                show: true,
              })),
          });
        }

        dispatch(updateProductProfileNotifications(notifications));
        return;
      }

      dispatch(updateProductProfileNotifications([]));
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  const isShowProductBanner = (
    newNotification: TopNotificationItem[],
    productBanner: TopNotificationItem,
    oldProductBanners: TopNotificationItem[],
    productBannerType?: ProductBannerType,
  ) => {
    const oldProductBanner = oldProductBanners.find(
      (item) => item.productBannerType === productBannerType,
    );

    if (productBannerType === ProductBannerType.Draft) {
      var newBannerRejected = newNotification.find(
        (item) => item.productBannerType === ProductBannerType.Rejected,
      );

      var oldBannerRejected = oldProductBanners.find(
        (item) => item.productBannerType === ProductBannerType.Rejected,
      );

      const hasNewBannerRejected =
        newBannerRejected && isEmpty(oldBannerRejected);
      const isLastModifiedDifferent =
        productBanner?.lastModifiedDate !== oldProductBanner?.lastModifiedDate;

      if (hasNewBannerRejected && isLastModifiedDifferent) {
        return true;
      }
      return oldProductBanner?.show ?? true;
    }

    if (productBannerType === ProductBannerType.Approved) {
      if (
        productBanner?.lastModifiedDate !== oldProductBanner?.lastModifiedDate
      ) {
        return true;
      }
      return oldProductBanner?.show ?? true;
    }

    if (productBannerType === ProductBannerType.Rejected) {
      if (
        productBanner?.lastModifiedDate !== oldProductBanner?.lastModifiedDate
      ) {
        return true;
      }
      return oldProductBanner?.show ?? true;
    }

    if (productBannerType === ProductBannerType.MissingData) {
      return oldProductBanner?.show ?? true;
    }

    return true;
  };

  const updateProductBanners = (productId: string, action: string) => {

    const productBannerInfo = getLocalStorageItem(productBannerItemKey);
    const productBannersByProductId = productBannerInfo[
      productId
    ] as TopNotificationItem[];

    if (action === "Revert to draft") {
      // Remove banner draft in localStorage
      const newProductBanners = productBannersByProductId.filter(
        (item) =>
          item.productBannerType !== ProductBannerType.Draft,
      );
      setLocalStorageItem(productBannerItemKey, {
        ...productBannerInfo!,
        [productId]: newProductBanners,
      });
    }
  };

  const resetBannerInfo = () => {
    dispatch(updateProductBannerInfo([]));
    dispatch(updateProductProfileNotifications([]));
  };

  const refreshProductGroups = async () => {
    await updateProductGroups(productData?.id ?? "new");
  };

  const updateProductData = async ({
    productId,
    groupId,
    mode,
  }: {
    productId?: string;
    groupId?: string;
    mode?: "view" | "edit";
  }) => {
    if (!groupId) return;
    const response = await dispatch(
      loadProduct({ productId, groupId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response?.data;
  };

  const refreshProductData = async (mode: "view" | "edit") => {
    if (!productGroups[selectedGroup]) return;
    return await updateProductData({
      productId: productData?.id ?? "new",
      groupId: productGroups[selectedGroup]?.id,
      mode,
    });
  };

  const createNewProduct = async (product: ProductListDTO) => {
    const response = await dispatch(createProduct(product)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    notify(response.message, {
      variant: "success",
      close: true,
    });
    return response.data;
  };

  const updateExtProduct = async (
    product: ProductListDTO,
    isHiddenMessage?: boolean,
  ) => {
    const response = await dispatch(updateProduct(product)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    if (!isHiddenMessage)
      notify(response.message, {
        variant: "success",
        close: true,
      });
    return response.data;
  };

  const loadProductChangeHistory = async (productId: string) => {
    const response = await dispatch(loadProductHistory(productId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
  };

  const viewProductReleaseLog = async ({
    productId,
    version,
    configurationVersion,
    groupId,
  }: {
    productId: string;
    version: string;
    configurationVersion: string;
    groupId: string;
  }) => {
    const response = await dispatch(
      loadProductReleaseLog({
        productId,
        version,
        configurationVersion,
        groupId,
      }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  // Sub Products
  const loadSubProductsFees = async (
    productId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadSubProducts({ productId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadNewSubProductTemplate = async (productId: string) => {
    const response = await dispatch(loadNewSubProduct(productId)).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const updateExtSubProducts = async (
    productId: string,
    subProducts: SubProductDTO[],
    isHiddenMessage?: boolean,
  ) => {
    const response = await dispatch(
      updateSubProducts({ productId: productId, subProducts: subProducts }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    const subProductHighlightTypeMapping =
      generateSubProductHighlightTypeMapping(response.data);
    dispatch(
      updateSubProductHighlightTypeMapping(subProductHighlightTypeMapping),
    );

    if (!isHiddenMessage)
      notify(response.message, {
        variant: "success",
        close: true,
      });

    return response.data;
  };

  const updateSubProductData = async ({
    productId,
    mode,
  }: {
    productId?: string;
    mode?: "view" | "edit";
  }) => {
    const response = await dispatch(
      loadSubProducts({ productId, mode }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const refreshSubProductData = async (
    productId?: string,
    mode?: "view" | "edit",
  ) => {
    return await updateSubProductData({
      productId: productId ?? "new",
      mode,
    });
  };

  const viewSubProductReleaseLog = async ({
    productId,
    version,
    configurationVersion,
  }: {
    productId: string;
    version: string;
    configurationVersion: string;
  }) => {
    const response = await dispatch(
      loadSubProductReleaseLog({
        productId,
        version,
        configurationVersion,
      }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadExistingFeeSchedule = async (productId: string) => {
    const response = await dispatch(
      loadExistingFeeScheduleThunk(productId),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadSubProduct = async (productId: string, subProductId: string) => {
    const response = await dispatch(
      loadSubProductThunk({ productId, subProductId }),
    ).unwrap();
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  const loadProductBrandingInfo = async (productId: string) => {
    const response = await dispatch(
      loadProductBrandingData(productId),
    ).unwrap();

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }
    return response.data;
  };

  return {
    updateProductGroups,
    refreshProductGroups,
    updateProductData,
    refreshProductData,
    createNewProduct,
    updateExtProduct,
    loadBannerInfo,
    resetBannerInfo,
    loadProductChangeHistory,
    viewProductReleaseLog,
    loadSubProductsFees,
    loadNewSubProductTemplate,
    updateExtSubProducts,
    refreshSubProductData,
    viewSubProductReleaseLog,
    loadExistingFeeSchedule,
    loadSubProduct,
    loadProductBrandingInfo,
    updateProductBanners,
    isShowProductBanner,
  };
};
