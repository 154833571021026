import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const TopBarSkeleton = () => {
  return (
    <SHStack direction={"row"} spacing={2} justifyContent="space-between">
      <SHSkeleton height={40} width="25%" delay={false} />
      <SHStack direction={"row"} spacing={2}>
        <SHSkeleton
          height={40}
          width={144}
          sx={{ borderRadius: 16, alignSelf: "center" }}
          delay={false}
        />
        <SHSkeleton
          height={40}
          width={100}
          sx={{ borderRadius: 16, alignSelf: "center" }}
          delay={false}
        />
        <SHSkeleton
          height={40}
          width={144}
          sx={{ borderRadius: 16, alignSelf: "center" }}
          delay={false}
        />
      </SHStack>
    </SHStack>
  );
};
