import { InformationButton } from "@components/buttons/information";
import {
  SHBooleanField,
  SHBox,
  SHDivider,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { GridImageGallery } from "@components/grid-image-gallery";
import { ImageSVG } from "@components/svgs";
import { DateFormat, FullDateFormat } from "@constants/format";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { UserType } from "@models/auth";
import {
  ChartTypeId,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import { useTheme } from "@mui/material";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { ChartTitle } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/chart-title";
import { GaugeChart } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/gauge-chart";
import { InvestmentTableItem } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/investment-table-item";
import { SinglePercentageBarChart } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/single-percentage-bar-chart";
import { UpgradeSubscriptionNotice } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/upgrade-subscription-notice";
import { PercentageChartMetaData } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/configs";
import {
  checkStringIsNumber,
  getProductDataValue,
  roundNumber,
  tryParseJson,
} from "@pages/suppliers/_id/util";
import {
  AdditionalVisualizeTable,
  InvestmentFeatureHiddenInViewMode,
  VisibilityMessage,
} from "@pages/suppliers/constants";
import { RootState } from "@redux/store";
import { lightPalette } from "@themes/light";
import { generateFeatureName } from "@utils/text-format";
import { format, parse } from "date-fns";
import { isArray, isEmpty, isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useToggle } from "react-use";
import { GetAssetAllocationFeatureData } from "../../utils";
import { FeatureViewLongText } from "../feature-view-long-text";
import SectionTitle from "../view-feature/components/sub-section-title";
import { InvestmentProductCollapseMenu } from "./components/investment-collapse-menu";
import { ChartData, SHPieChart } from "./components/pie-chart";
import EyeIconTooltip from "@components/svgs/eye-tooltip";
interface ViewFeatureProps {
  investmentProductModelsData?: InvestmentProductModelsDTO[];
}
/**
 * @description: render feature for view SMA models tab
 */
export const ViewFeatureModel = ({
  investmentProductModelsData,
}: ViewFeatureProps) => {
  const allModelsEmpty = investmentProductModelsData?.every(
    (data) => Array.isArray(data.models) && isEmpty(data.models),
  );
  /**
   * when first entry, auto open the series (section) that
   * - has models
   * - is not view restricted
   */
  const indexOfFirstSeriesWithModelsData =
    investmentProductModelsData?.findIndex(
      (item) =>
        Array.isArray(item.models) &&
        !isEmpty(item.models) &&
        !item.isViewRestricted,
    ) ?? 0;
  const { user } = useSelector((state: RootState) => state.auth);
  const { palette } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const [isShowAssetAllocation, setIsShowAssetAllocation] = useState(false);
  const [isShowPieChart, togglePieChart] = useToggle(true);

  const selectedModel = useMemo(() => {
    return investmentProductModelsData?.[selectedIndex[0]]?.models?.[
      selectedIndex[1]
    ];
  }, [investmentProductModelsData, selectedIndex]);

  useEffect(() => {
    setSelectedIndex([indexOfFirstSeriesWithModelsData, 0]);
  }, [indexOfFirstSeriesWithModelsData]);

  useEffect(() => {
    setIsShowAssetAllocation(!isEmptyAssetAllocationData());
    // eslint-disable-next-line
  }, [selectedModel]);

  const getInvestmentApproachChartData = (
    section: InvestmentProductConfigurationModelSection,
  ) => {
    const standardRiskMeasure = getProductDataValue(
      section.features,
      "Standard risk measure",
    );
    const targetGrowthExposure = getProductDataValue(
      section.features,
      "Target growth exposure",
    );
    const targetDefensiveExposure = getProductDataValue(
      section.features,
      "Target defensive exposure",
    );
    const activeShare = getProductDataValue(section.features, "Active Share");
    const passiveShare = getProductDataValue(section.features, "Passive Share");
    return {
      standardRiskMeasure,
      targetGrowthExposure,
      targetDefensiveExposure,
      activeShare,
      passiveShare,
    };
  };

  const isNoTargetAssetAllocation = () => {
    const assetAllocationSection = selectedModel?.sections?.find(
      (section) => section.name === AdditionalVisualizeTable.ASSET_ALLOCATION,
    );

    const targetExists = assetAllocationSection?.features?.find(
      (feature) =>
        feature.name === InvestmentFeatureHiddenInViewMode.TARGET_EXISTS,
    );

    return !isEqual(targetExists?.productData?.value, "true");
  };

  const isEmptyAssetAllocationData = () => {
    const assetAllocationSection = selectedModel?.sections?.find(
      (section) => section.name === AdditionalVisualizeTable.ASSET_ALLOCATION,
    );

    if (!assetAllocationSection) return true;
    const { features } = assetAllocationSection;
    const productDataValues: ProductModelValueDTO[] =
      features?.map((feature) =>
        tryParseJson(feature.productData?.value as string),
      ) || [];
    const flatValue = productDataValues.filter((item) => isArray(item)).flat();
    const isNotEmptyTarget = flatValue.some(
      (item) => item && item.headerId === "Target" && !isEmpty(item.value),
    );

    return !isNotEmptyTarget;
  };

  const renderPieChart = (
    section: InvestmentProductConfigurationModelSection,
  ) => {
    const isAssetAllocationSection = isEqual(
      section.name,
      AdditionalVisualizeTable.ASSET_ALLOCATION,
    );
    if (!section.chartFeatures) return <></>;

    let chartFeatures = section.chartFeatures;

    if (isAssetAllocationSection) {
      chartFeatures = GetAssetAllocationFeatureData(chartFeatures ?? []);
    }

    const chartData: ChartData[] = chartFeatures.map((feature) => {
      return {
        name: feature.name as string,
        value: parseFloat(
          (!feature.productData ? 0 : feature.productData.value) as string,
        ),
      };
    });

    return (
      <SHStack
        direction="column"
        spacing={2}
        height="100%"
        paddingBottom={"12px"}
      >
        <SHBox paddingTop={"12px"} width={"100%"}>
          {isShowPieChart && <ChartTitle>{section.chartName}</ChartTitle>}
        </SHBox>
        <SHPieChart chartData={chartData} />
      </SHStack>
    );
  };

  const renderFeatureItem = (
    feature: InvestmentProductConfigurationModelsFeature,
  ) => {
    const hasCustomVisibility =
      feature.hasCustomVisibility && !feature.isViewRestricted;
    const isViewRestricted = feature.isViewRestricted;
    const featureName = generateFeatureName(
      feature.name ?? "",
      feature.description,
    );

    const isSlideShow = feature.fieldType?.id === FieldTypeId.Slideshow;
    const value = toNativeValue(
      feature.productData?.value || "",
      isSlideShow ? FieldTypeId.Slideshow : FieldTypeId.YesNo,
    );
    const additionalValue = feature.productData?.additionalValue;
    const hasDescription = isSlideShow || (value === true && additionalValue);

    const getFeatureValue = () => {
      if (isViewRestricted)
        return (
          <SHTypography
            variant="body3"
            colorVariant="disabled"
            key={feature.id}
          >
            {VisibilityMessage.FEATURE_LEVEL}
          </SHTypography>
        );

      if (!feature.productData) return <></>;

      const value = feature.productData?.value;
      if (feature.fieldType?.id === FieldTypeId.YesNo) {
        switch (value) {
          case "true":
            return <SHBooleanIcon isTrue />;
          case "false":
            return <SHBooleanIcon isTrue={false} />;
        }
      }

      if (!value) {
        return (
          <SHTypography variant="body3" colorVariant="fourth" key={feature.id}>
            No data
          </SHTypography>
        );
      }
      const isPercentage =
        (feature.fieldType?.id === FieldTypeId.Percentage ||
          feature.fieldType?.id === FieldTypeId.PercentageText) &&
        checkStringIsNumber(value);
      const isNumeric =
        (feature.fieldType?.id === FieldTypeId.Number ||
          feature.fieldType?.id === FieldTypeId.NumberText) &&
        checkStringIsNumber(value);
      const isCurrency =
        (feature.fieldType?.id === FieldTypeId.Currency ||
          feature.fieldType?.id === FieldTypeId.CurrencyText) &&
        checkStringIsNumber(value);

      if (feature.fieldType?.id === FieldTypeId.DateTime) {
        const dateValue = parse(value, FullDateFormat, new Date());
        return (
          <SHTypography variant="body3" colorVariant="fourth" key={feature.id}>
            {format(dateValue, DateFormat)}
          </SHTypography>
        );
      }
      if (isPercentage) {
        return (
          <SHTypography variant="body3" colorVariant="fourth" key={feature.id}>
            <NumericFormat
              valueIsNumericString
              value={value}
              displayType={"text"}
              decimalScale={2}
              suffix={"%"}
              key={feature.id}
            />
          </SHTypography>
        );
      }
      if (isCurrency) {
        return (
          <SHTypography variant="body3" colorVariant="fourth" key={feature.id}>
            <NumericFormat
              valueIsNumericString
              value={value}
              displayType={"text"}
              decimalScale={2}
              prefix="$"
              key={feature.id}
            />
          </SHTypography>
        );
      }
      if (isNumeric) {
        return (
          <SHTypography variant="body3" colorVariant="fourth" key={feature.id}>
            <NumericFormat
              valueIsNumericString
              value={value}
              displayType={"text"}
              decimalScale={2}
              key={feature.id}
              thousandSeparator={","}
            />
          </SHTypography>
        );
      }
      return (
        <SHHtmlBlock
          variant="body3"
          color={palette.text.fourth}
          content={value}
          key={feature.id}
        />
      );
    };

    const renderFeatureHeading = () => (
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        sx={{ pr: "40px", py: "25px" }}
      >
        <SHStack direction="row" spacing={0.5} alignItems="start">
          <SHTypography color={palette.text.primary}>
            {feature.name}
          </SHTypography>
          {feature?.description && (
            <InformationButton content={feature.description} />
          )}
        </SHStack>
        <SHBox>{getFeatureValue()}</SHBox>
      </SHStack>
    );

    switch (feature.fieldType?.id) {
      case FieldTypeId.YesNo: {
        if (!hasDescription) {
          return (
            <>
              {renderFeatureHeading()}
              <SHDivider />
            </>
          );
        }

        return (
          <>
            <SHCollapse title={renderFeatureHeading()} fullWidthTitle>
              <FeatureViewLongText content={additionalValue} />
            </SHCollapse>
            <SHDivider />
          </>
        );
      }

      case FieldTypeId.Slideshow: {
        const value = toNativeValue(
          feature.productData?.value || "",
          FieldTypeId.Slideshow,
        );
        if (!value || value.length === 0) {
          return <></>;
        }
        return (
          <SHCollapse
            sx={{ pt: "25px" }}
            open
            title={
              <SHStack direction="row" spacing={0.5} alignItems="start">
                <ImageSVG />
                <SHTypography>{feature.name}</SHTypography>
              </SHStack>
            }
          >
            <SHBox sx={{ pt: "25px" }}>
              <GridImageGallery
                previewImagesDialogTitle={feature?.name}
                images={value}
              />
            </SHBox>
          </SHCollapse>
        );
      }

      case FieldTypeId.Image: {
        const value = toNativeValue(
          feature.productData?.value || "",
          FieldTypeId.Image,
        );
        if (!value || value.length === 0) {
          if (!value || value.length === 0) {
            return (
              <>
                <SHStack
                  direction={"row"}
                  justifyContent="space-between"
                  sx={{
                    p: "15px 0",
                    wordBreak: "break-word",
                  }}
                >
                  <SHTypography
                    variant="subtitle2"
                    textAlign="left"
                    width="100%"
                    color={palette.text.primary}
                    pb="5px"
                  >
                    {feature.name}
                    {feature?.description && (
                      <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                        <InformationButton
                          content={feature.description}
                          margin={"-8px 0px 2px 4px !important"}
                        />
                      </SHBox>
                    )}
                  </SHTypography>

                  <SHBox>
                    <SHTypography
                      variant="body2"
                      colorVariant="third"
                      sx={{
                        minWidth: "54px",
                      }}
                    >
                      No data
                    </SHTypography>
                  </SHBox>
                </SHStack>
                <SHDivider />
              </>
            );
          }
        }
        return (
          <>
            <SHCollapse
              sx={{ pt: "25px" }}
              open
              title={
                <SHStack direction="row" spacing={0.5} alignItems="start">
                  <ImageSVG />
                  <SHTypography>{feature.name}</SHTypography>
                </SHStack>
              }
            >
              <SHBox sx={{ pt: "25px" }}>
                <SHImage
                  src={value.url}
                  alt={value.key}
                  height={"250px"}
                  style={{ objectFit: "contain" }}
                />
              </SHBox>
            </SHCollapse>
            <SHDivider />
          </>
        );
      }

      default: {
        return (
          <SHStack
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              color={lightPalette.text?.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-5px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    marginLeft: "8px",
                  },
                }}
                isVisible={hasCustomVisibility}
              />
            </SHTypography>
            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
        );
      }
    }
  };

  const renderHasChartSection = (
    section: InvestmentProductConfigurationModelSection,
    width: number,
  ) => {
    return (
      <SHStack
        width={`${width}%`}
        paddingTop={isShowPieChart ? 0 : "12px"}
        paddingBottom={isShowPieChart ? 0 : "10px"}
      >
        {isShowPieChart ? (
          renderPieChart(section)
        ) : (
          <InvestmentTableItem section={section} width={width} />
        )}
      </SHStack>
    );
  };

  const renderSectionItem = (
    section: InvestmentProductConfigurationModelSection,
    index: number,
  ) => {
    const previousSection = selectedModel?.sections?.[index - 1];
    const previousWidth = previousSection?.displayWidth ?? 100;
    const currentWidth = section.displayWidth ?? 100;
    const width = section.displayWidth ?? 100;
    const previousName = previousSection?.name ?? "";
    const isInvestmentApproach =
      section.name === AdditionalVisualizeTable.INVESTMENT_APPROACH;
    const isAssetAllocationNotes =
      section.name === AdditionalVisualizeTable.ASSET_ALLOCATION_NOTES;
    const widthWithinRange =
      previousWidth + currentWidth >= 90 && previousWidth + currentWidth <= 100;
    const isNotInvestmentApproach =
      previousName !== AdditionalVisualizeTable.INVESTMENT_APPROACH;

    const hasDivider =
      isInvestmentApproach || (widthWithinRange && isNotInvestmentApproach);
    const isAssetAllocation =
      section.name === AdditionalVisualizeTable.ASSET_ALLOCATION;
    const isNoTargetAAChart = isNoTargetAssetAllocation();
    const {
      standardRiskMeasure,
      targetGrowthExposure,
      targetDefensiveExposure,
      activeShare,
      passiveShare,
    } = getInvestmentApproachChartData(section);
    // show active share and passive share chart only when both are number and not empty
    const isShowActiveShare =
      checkStringIsNumber(activeShare) && checkStringIsNumber(passiveShare);
    // show target exposure chart only when both are number and not empty
    const isShowTargetExposure =
      checkStringIsNumber(targetGrowthExposure) &&
      checkStringIsNumber(targetDefensiveExposure);
    const isShowStandardRiskMeasure = checkStringIsNumber(standardRiskMeasure);
    switch (section.displayType?.id) {
      case SectionDisplayTypeId.Table: {
        if (isAssetAllocation && isNoTargetAAChart) {
          return (
            <>
              <SHTypography paddingLeft={"10px"} py="10px">
                No target asset allocation
              </SHTypography>
              <SHDivider sx={{ height: "1px", width: "100%" }} />
            </>
          );
        }

        if (isAssetAllocation && !isShowAssetAllocation) {
          return (
            <>
              <SHTypography paddingLeft={"10px"} py="10px">
                No asset allocation data
              </SHTypography>
              <SHDivider sx={{ height: "1px", width: "100%" }} />
            </>
          );
        }
        if (isAssetAllocationNotes && !isShowAssetAllocation) {
          return null;
        }
        //specific case: display chart for investment approach section, only show when had data
        if (isInvestmentApproach) {
          const targetDefensivePercentage = roundNumber(
            targetDefensiveExposure,
          );
          const targetGrowthPercentage = roundNumber(targetGrowthExposure);
          const activeSharePercentage = roundNumber(activeShare);
          const passiveSharePercentage = roundNumber(passiveShare);
          const targetExposureMetaData = PercentageChartMetaData.TargetExposure;
          const activePassiveShareMetaData =
            PercentageChartMetaData.ActivePassiveShare;
          return (
            <>
              <SHStack width={"100%"} gap={3} flexDirection={"row"}>
                <SHStack width={`${width}%`} paddingBottom="12px">
                  <InvestmentTableItem section={section} width={width} />
                </SHStack>
                {/* Investment approach charts */}
                <SHStack paddingTop={"10px"} gap={2} flex={1}>
                  {/* Standard risk measure  */}
                  {isShowStandardRiskMeasure && (
                    <>
                      <ChartTitle>Standard risk measure</ChartTitle>
                      <GaugeChart
                        highlightedPriority={Number(standardRiskMeasure)}
                      />
                    </>
                  )}
                  {/* Target exposure */}
                  {isShowTargetExposure && (
                    <>
                      <ChartTitle>Target exposure</ChartTitle>
                      <SinglePercentageBarChart
                        leftValue={targetGrowthPercentage}
                        rightValue={targetDefensivePercentage}
                        leftBgColor={targetExposureMetaData.leftColor}
                        rightBgColor={targetExposureMetaData.rightColor}
                        leftLabel={targetExposureMetaData.leftLabel}
                        rightLabel={targetExposureMetaData.rightLabel}
                      />
                    </>
                  )}
                  {/* Active/passive share */}
                  {isShowActiveShare && (
                    <>
                      <ChartTitle>Active/passive share</ChartTitle>
                      <SinglePercentageBarChart
                        leftValue={activeSharePercentage}
                        rightValue={passiveSharePercentage}
                        leftBgColor={activePassiveShareMetaData.leftColor}
                        rightBgColor={activePassiveShareMetaData.rightColor}
                        leftLabel={activePassiveShareMetaData.leftLabel}
                        rightLabel={activePassiveShareMetaData.rightLabel}
                      />
                    </>
                  )}
                </SHStack>
              </SHStack>
              {hasDivider && (
                <SHDivider sx={{ height: "1px", width: "100%" }} />
              )}
            </>
          );
        }
        //specific case: case for display when has pie chart
        if (section.hasChart && section.chartType?.id === ChartTypeId.Pie) {
          return (
            <>
              {renderHasChartSection(section, width)}
              {hasDivider && (
                <SHDivider sx={{ height: "1px", width: "100%" }} />
              )}
            </>
          );
        }

        if (isAssetAllocationNotes) {
          if (isNoTargetAAChart) return null;
          return (
            <>
              <SHStack
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                paddingTop={"12px"}
                paddingBottom={"12px"}
                width={`${width}%`}
                gap={2}
              >
                <SHBooleanField
                  onChange={togglePieChart}
                  yesLabel="Chart"
                  noLabel="Table"
                  isSwitch
                  value={isShowPieChart}
                  width="80px"
                  isShowClearButton={false}
                />
                <InvestmentTableItem
                  section={section}
                  alignEnd={true}
                  width={width}
                />
              </SHStack>
              {hasDivider && (
                <SHDivider sx={{ height: "1px", width: "100%" }} />
              )}
            </>
          );
        }

        //normal case - ony table
        return (
          <>
            <SHStack
              width={`${width}%`}
              paddingBottom="12px"
              paddingTop={"12px"}
            >
              <InvestmentTableItem section={section} width={width} />{" "}
            </SHStack>
            {hasDivider && <SHDivider sx={{ height: "1px", width: "100%" }} />}
          </>
        );
      }

      case SectionDisplayTypeId.MainBodyPage: {
        return (
          <SHStack width="100%" mb="20px">
            {section.features
              ?.filter((feature) => feature.name !== "Model")
              .map((feature) => {
                return (
                  <React.Fragment key={feature.id}>
                    {renderFeatureItem(feature)}
                    <SHDivider />
                  </React.Fragment>
                );
              })}
          </SHStack>
        );
      }
    }
  };

  const showUpgradeToView = () => {
    if (
      user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial
    ) {
      return true;
    }
    return false;
  };

  const renderPageUpdated = () => {
    if (!selectedModel?.pageUpdated) return <></>;
    return (
      <SHTypography variant="body1" colorVariant="third" textAlign={"right"}>
        Page updated: {`${format(selectedModel?.pageUpdated, DateFormat)}`}
      </SHTypography>
    );
  };

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <UpgradeSubscriptionNotice />
      ) : (
        <ProductFeaturesLayout
          hasData={!isEmpty(investmentProductModelsData) && !allModelsEmpty}
          decreaseLeft={true}
          leftSide={
            <InvestmentProductCollapseMenu
              sx={{ width: "219px !important" }}
              investmentProductModelsData={investmentProductModelsData}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              defaultExpandIndexes={[indexOfFirstSeriesWithModelsData]}
            />
          }
          rightSide={
            <SHStack
              direction={"row"}
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <SHStack
                direction={"row"}
                sx={{ justifyContent: "space-between" }}
                width="100%"
              >
                <SectionTitle
                  groupName={
                    !isEmpty(investmentProductModelsData) &&
                    investmentProductModelsData?.[selectedIndex[0]]
                      ? `${
                          investmentProductModelsData[selectedIndex[0]].name ??
                          ""
                        } ${selectedModel?.name ?? ""}`
                      : ""
                  }
                  sectionName={selectedModel?.sections?.[0]?.name || ""}
                  sectionNameColor={palette.text.fourth}
                />
                {renderPageUpdated()}
              </SHStack>
              <SHDivider sx={{ height: "1px", width: "100%" }} />
              {selectedModel?.sections?.map((section, index) => (
                <React.Fragment key={section.id}>
                  {renderSectionItem(section, index)}
                </React.Fragment>
              ))}
            </SHStack>
          }
        />
      )}
    </SHStack>
  );
};
