import { InformationButton } from "@components/buttons/information";
import { SHLabel, SHStack, SHTypography } from "@components/design-systems";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { DateFormat } from "@constants/format";
import {
  useEditESGPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { format, parse } from "date-fns";
import { debounce, isEqual, isNaN } from "lodash";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EditESGInputControllerProps,
  handleOnEditESGFieldChange,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { FieldTypeId } from "@models/configuration";
import { useTheme } from "@mui/material";

export const EditESGDateTimeInput = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { palette } = useTheme();
  const { control, getValues, setValue, setError, clearErrors } =
    useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductEsgDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const [fieldValue, setFieldValue] = useState<string | null>(
    featureData.productData?.value ?? "",
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleOnChange = (newValue: string | null) => {
    const formValue = toStringValue(newValue, FieldTypeId.DateTime);

    const fieldValuePath = featureProductDataPath.value;

    if (isEqual(newValue, EditProductDefaults.InvalidDate)) {
      setError(fieldValuePath, {
        message: EditProductDefaults.InvalidDateFormatMessage,
      });
      setErrorMessage(EditProductDefaults.InvalidDateFormatMessage);
    } else {
      clearErrors(fieldValuePath);
      setErrorMessage(undefined);
    }

    handleOnEditESGFieldChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex, newValue: formValue });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field: { ref, ...other } }) => {
        const date: Date | null = !!other.value
          ? parse(String(other.value), DateFormat, new Date())
          : null;

        return (
          <SHStack
            direction="column"
            gap={1}
            mb={1}
            sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
          >
            <SHLabel
              label={featureData?.name}
              postfixLabel={postfixLabel}
              disabled={isDisabled}
              dotGroupProps={{
                blueDot: isShowBlueDot,
                orangeDot: isShowOrangeDot,
                greenDot: featureData?.isBusinessMetric,
              }}
            />

            <SHDatePicker
              textFieldProps={{
                error: isEqual(fieldValue, EditProductDefaults.InvalidDate),
              }}
              disabled={isDisabled}
              openOnClick
              minDate={EditProductDefaults.MinDateTime}
              maxDate={EditProductDefaults.MaxDateTime}
              value={date}
              inputFormat={DateFormat}
              views={["year", "month", "day"]}
              onChange={(newValue: Date | null) => {
                const newValueString = toStringValue(
                  newValue,
                  FieldTypeId.DateTime,
                );
                setFieldValue(newValueString);

                const isValidDate = !isNaN(Date.parse(newValueString));
                const newDateValue = isValidDate
                  ? format(new Date(newValueString), DateFormat)
                  : newValueString;

                other.onChange(newDateValue);
                debouncedHandleInputChange(newDateValue);
              }}
            />

            {errorMessage && (
              <SHTypography variant={"caption"} color={palette.error.main}>
                {errorMessage}
              </SHTypography>
            )}
          </SHStack>
        );
      }}
    />
  );
};
