import {
  SHDotGroup,
  SHDotGroupProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import EyeIconTooltip from "@components/svgs/eye-tooltip";
import { useCollapseMenu } from "@hooks/useCollapseMenu ";
import { HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationSection } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { useTheme } from "@mui/material";
import { VisibilityMessage } from "@pages/suppliers/constants";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";

export interface ProductCollapseMenuProps {
  investmentProductSeriesData?: InvestmentProductSeriesDTO[];
  selectedIndex?: [number, number]; //[sectionIndex,subSectionIndex]
  defaultExpandIndexes?: number[];
  onChange?: (index: [number, number]) => void;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
}

export const InvestmentProductCollapseMenu = ({
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  defaultExpandIndexes = [],
  onChange = (index) => {},
  dotGroupProps,
}: ProductCollapseMenuProps) => {
  const { palette } = useTheme();
  const { expandedIndex, handleCollapseToggle } =
    useCollapseMenu(defaultExpandIndexes);
  const renderMenuItems = (
    sections: InvestmentProductConfigurationSection[] | undefined,
    isViewRestricted: boolean,
    sectionIndex: number,
  ) => {
    if (isViewRestricted)
      return (
        <SHTypography
          variant="body2"
          sx={{
            padding: "20px 10px",
          }}
        >
          {VisibilityMessage.SERIES_LEVEL}
        </SHTypography>
      );
    if (isEmpty(sections))
      return (
        <SHTypography
          variant="body2"
          sx={{
            padding: "20px 10px",
          }}
        >
          No models
        </SHTypography>
      );

    return (
      <>
        {sections?.map(
          (section: InvestmentProductConfigurationSection, subSectionIndex) => {
            const selectedSubSection =
              selectedIndex[0] === sectionIndex &&
              selectedIndex[1] === subSectionIndex;
            return (
              <SHStack
                key={section.id}
                sx={{
                  padding: "20px 10px",
                  cursor: "pointer",
                  bgcolor: selectedSubSection
                    ? hexToRGBA(palette.primary.light, 0.1)
                    : "transparent",
                  "&:hover": {
                    bgcolor: selectedSubSection
                      ? hexToRGBA(palette.primary.light, 0.15)
                      : hexToRGBA(palette.common.white, 0.5),
                  },
                }}
                onClick={() => {
                  onChange([sectionIndex, subSectionIndex]);
                }}
                direction="row"
                spacing={0.5}
                alignItems="start"
              >
                <SHTypography
                  variant={selectedSubSection ? "subtitle2" : "body1"}
                >
                  {section.name}
                </SHTypography>
                <SHDotGroup
                  blueDot={
                    dotGroupProps?.blueDot &&
                    section.dataStatus === InvestmentDataStatus.MissingData
                  }
                  redDot={
                    dotGroupProps?.redDot &&
                    section.highlightType === HighlightType.Edited
                  }
                  paddingTop={"5px"}
                />
              </SHStack>
            );
          },
        )}
      </>
    );
  };
  return (
    <>
      {investmentProductSeriesData?.map((group, sectionIndex) => {
        const isExpanded = expandedIndex.includes(sectionIndex);
        return (
          <SHCollapse
            key={group.id}
            title={
              <SHStack direction="row" spacing={1} alignItems="center">
                <SHTypography variant="subtitle1">{group.name}</SHTypography>
                <EyeIconTooltip
                  tooltipProps={{
                    title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                  }}
                  isVisible={
                    group.hasCustomVisibility && !group.isViewRestricted
                  }
                />
              </SHStack>
            }
            open={isExpanded}
            onCollapse={(open) => {
              handleCollapseToggle(sectionIndex, open);
            }}
            width={"100%"}
            height="auto"
            sx={{
              padding: "20px 10px",
              cursor: "pointer",
              bgcolor: isExpanded ? palette.common.white : "transparent",
              borderColor: palette.secondary[100],
              borderBottomStyle: "solid",
              borderBottomWidth: isExpanded ? 0 : 1,
            }}
          >
            {renderMenuItems(
              group.sections,
              group.isViewRestricted,
              sectionIndex,
            )}
          </SHCollapse>
        );
      })}
    </>
  );
};
