import { PageRoutes } from "@constants";
import {
  ConfigurationFeature,
  ConfigurationGroup,
  ConfigurationSection,
  FieldTypeId,
  HighlightType,
  SectionType,
} from "@models/configuration";
import { ProductListDTO } from "@models/product/entities/product";
import { SubProductDTO } from "@models/product/entities/subProduct";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { ProductType } from "@models/product/enums/productType";
import { sortAscByOrder } from "@utils";
import { isEmpty, isNil } from "lodash";
import { generatePath } from "react-router";

export const compileProduct = (
  product: ProductListDTO,
  mode?: "view" | "edit",
  isViewReleases?: boolean,
): ProductListDTO => {
  const isViewMode = mode === "view";
  if (product.groups[0]?.sections) {
    //Sort Section
    product.groups[0].dataStatus = ProductDataStatus.Filled;
    if (!isViewMode) product.groups[0].highlightType = HighlightType.Added;

    product.groups[0].sections = sortAscByOrder(product.groups[0].sections);

    product.groups[0].sections.forEach((section) => {
      section.dataStatus = ProductDataStatus.Filled;
      if (!isViewMode) section.highlightType = HighlightType.Added;

      if (product.groups[0].sectionType === SectionType.HasSubSections) {
        //Sort Sub Section
        if (section.subSections)
          section.subSections = sortAscByOrder(section.subSections) as [];

        //Sort feature of sub sections
        section.subSections?.forEach((subSection: ConfigurationSection) => {
          subSection.dataStatus = ProductDataStatus.Filled;
          if (!isViewMode) subSection.highlightType = HighlightType.Added;

          if (subSection.features) {
            subSection.features = sortAscByOrder(subSection.features);

            subSection.features.forEach((feature) => {
              compileProductFeature(feature);

              if (checkIsMissingData(feature)) {
                product.groups[0].dataStatus = ProductDataStatus.MissingData;
                section.dataStatus = ProductDataStatus.MissingData;
                subSection.dataStatus = ProductDataStatus.MissingData;
                feature.dataStatus = ProductDataStatus.MissingData;
              } else {
                feature.dataStatus = ProductDataStatus.Filled;
              }
              if (isViewMode) return;
              if (
                feature?.productData?.flagType === "Current" &&
                !isViewReleases
              ) {
                product.groups[0].highlightType = HighlightType.Added;
                section.highlightType = HighlightType.Added;
                subSection.highlightType = HighlightType.Added;
                feature.highlightType = HighlightType.Added;
                if (feature?.productData?.highlightType)
                  feature.productData.highlightType = HighlightType.Added;
                return;
              }
              if (
                feature?.productData?.highlightType === HighlightType.Edited
              ) {
                product.groups[0].highlightType = HighlightType.Edited;
                section.highlightType = HighlightType.Edited;
                subSection.highlightType = HighlightType.Edited;
                feature.highlightType = HighlightType.Edited;
              }
            });
          }
        });
      } else {
        //Sort feature of section
        if (section.features) {
          section.features = sortAscByOrder(section.features);
          section.features.forEach((feature) => {
            compileProductFeature(feature);
            if (checkIsMissingData(feature)) {
              product.groups[0].dataStatus = ProductDataStatus.MissingData;
              section.dataStatus = ProductDataStatus.MissingData;
              feature.dataStatus = ProductDataStatus.MissingData;
            } else {
              feature.dataStatus = ProductDataStatus.Filled;
            }

            if (isViewMode) return;
            if (
              feature?.productData?.flagType === "Current" &&
              !isViewReleases
            ) {
              product.groups[0].highlightType = HighlightType.Added;
              section.highlightType = HighlightType.Added;
              feature.highlightType = HighlightType.Added;
              if (feature?.productData?.highlightType)
                feature.productData.highlightType = HighlightType.Added;
              return;
            }
            if (feature?.productData?.highlightType === HighlightType.Edited) {
              product.groups[0].highlightType = HighlightType.Edited;
              section.highlightType = HighlightType.Edited;
              feature.highlightType = HighlightType.Edited;
            }
          });
        }
      }
    });
  }
  return product;
};

/**
 * This function compiles a single ConfigurationFeature for a configuration product
 * If the productData property of the feature is not set, the function sets it to default values
 * The highlightType of the feature and its productData is set to Added
 * The other properties of productData are set to empty strings or false as appropriate
 * If the productData exists, the function sets any nil values (null or undefined) of its properties to empty strings.
 * @param feature ConfigurationFeature
 */
const compileProductFeature = (feature: ConfigurationFeature) => {
  if (!feature.productData) {
    feature.highlightType = HighlightType.Added;
    feature.productData = {
      configurationFeatureId: feature.id,
      value: "",
      publishedValue: "",
      additionalValue: "",
      publishedAdditionalValue: "",
      isPositiveScore: false,
      highlightType: HighlightType.Added,
      dataStatus: ProductDataStatus.MissingData,
    };
    return;
  }

  //Set all null or undefined values to empty
  if (isNil(feature.productData.value)) {
    feature.productData.value = "";
  }
  if (isNil(feature.productData.publishedValue)) {
    feature.productData.publishedValue = "";
  }
  if (isNil(feature.productData.additionalValue)) {
    feature.productData.additionalValue = "";
  }
  if (isNil(feature.productData.publishedAdditionalValue)) {
    feature.productData.publishedAdditionalValue = "";
  }
};

/**
 * Returns x raised to the n-th power.
 * This function checks if the data for a given ConfigurationFeature is missing data or not
 * It first checks if the field type is of type Slideshow, if so returns false
 * Then it checks if the dataStatus of the feature is MissingData, if so returns true
 * It also checks if the productData of the feature exists and has a value
 * If the value of the productData is nil or an empty string, the function returns true
 * Otherwise, the function returns false, indicating that the data is not missing.
 * @param feature ConfigurationFeature
 */
const checkIsMissingData = (feature: ConfigurationFeature) => {
  if (feature.fieldType?.id === FieldTypeId.Slideshow) return false;
  if (feature.dataStatus === ProductDataStatus.MissingData) return true;
  if (!feature.productData) return true;
  if (isNil(feature.productData.value)) return true;
  if (isEmpty(feature.productData.value)) return true;
  return false;
};

/**
 *This function compiles product groups for a configuration product
 *If the groups argument is not provided, the function returns undefined
 *Otherwise, it sorts the groups in ascending order by their order property
 *If the isNew argument is true, the dataStatus of each group is set to MissingData
 *Finally, the compiled and sorted (if necessary) groups are returned.
 * @param isNew boolean
 * @param groups  ConfigurationGroup[]
 */
export const compileProductGroups = (
  isNew: boolean,
  groups?: ConfigurationGroup[],
) => {
  if (!groups) return undefined;
  let newGroups: ConfigurationGroup[] = sortAscByOrder(groups);
  if (isNew) {
    newGroups.forEach((group) => {
      group.dataStatus = ProductDataStatus.MissingData;
    });
  }

  return newGroups;
};

export const compileSubProducts = (
  subProducts: SubProductDTO[],
): SubProductDTO[] => {
  subProducts = sortAscByOrder(subProducts) as [];
  subProducts.forEach((subProduct) => {
    //Sort Standard Fee Sections
    if (subProduct.standardFeeSections) {
      subProduct.standardFeeSections = sortAscByOrder(
        subProduct.standardFeeSections,
      ) as [];
      //Sort Features of Standard Fee Sections
      subProduct.standardFeeSections?.forEach((standardFeeSection) => {
        if (standardFeeSection.features)
          standardFeeSection.features = sortAscByOrder(
            standardFeeSection.features,
          );
      });
    }

    //Sort Other Fee Sections
    if (subProduct.otherFeeSections) {
      subProduct.otherFeeSections = sortAscByOrder(
        subProduct.otherFeeSections,
      ) as [];
      //Sort Features of Other Fee Sections
      subProduct.otherFeeSections?.forEach((otherFeeSection) => {
        if (otherFeeSection.features)
          otherFeeSection.features = sortAscByOrder(otherFeeSection.features);
      });
    }
  });

  return subProducts;
};

export const generateProductNavigationPath = ({
  productId,
  supplierId,
  productType,
}: {
  productId?: string;
  supplierId?: string;
  productType?: ProductType | null;
}) => {
  const defaultPath: string = generatePath(PageRoutes.supplierProducts, {
    supplierId: supplierId,
    productId: productId,
  });

  switch (productType) {
    case ProductType.SMA:
    case ProductType.MDA:
      return generatePath(PageRoutes.investmentProductsDetail, {
        supplierId: supplierId,
        investmentProductId: productId,
      });
    default:
      return defaultPath;
  }
};
