import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import {
  SHDivider,
  SHLabel,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { InformationButton } from "@components/buttons/information";
import { Controller, useFormContext } from "react-hook-form";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { DateFormat } from "@constants/format";
import { format, lastDayOfYear, parse } from "date-fns";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { HighlightType } from "@models/configuration";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { getFeatureDataPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/edit-series-right-panel";

interface SeriesFeatureDateTimeProps {
  feature: InvestmentProductConfigurationFeature;
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  selectedIndex: [number, number];
  isDisabled?: boolean;
}

const SeriesFeatureDateTime = ({
  feature,
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  isDisabled = false,
}: SeriesFeatureDateTimeProps) => {
  const { control, setValue, getValues, watch } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const {
    investmentSeriesDataPath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataStatusPath,
    featureProductDataPublishedPath,
    featureProductDataModifiedPath,
    investmentSeriesDataHighlightTypePath,
    featureViewingGroupSelectionsPath,
  } = getFeatureDataPath(selectedIndex, investmentProductSeriesData, feature);
  const productDataValue = watch(featureProductDataPath as SeriesPath);
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductHighlightTypePath as SeriesPath,
      dataStatus: featureProductDataStatusPath as SeriesPath,
    },
  });

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(feature.viewingGroupSelections, newValue);
    setValue(
      `${investmentSeriesDataPath}.isModified` as SeriesPath,
      isModified,
    );
    setValue(sectionModifiedPath as SeriesPath, isModified);
    setValue(featureModifiedPath as SeriesPath, isModified);
    setValue(featureProductDataModifiedPath as SeriesPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const handleInputChange = (inputDateValue: Date | null) => {
    const publishedData = getValues(
      featureProductDataPublishedPath as SeriesPath,
    );
    const inputDateStr = inputDateValue
      ? format(inputDateValue ?? new Date(), DateFormat)
      : "";

    setValue(
      investmentSeriesDataHighlightTypePath as SeriesPath,
      inputDateStr !== publishedData ? HighlightType.Edited.toString() : null,
    );
    setValue(
      featureProductDataStatusPath as SeriesPath,
      isNil(inputDateStr) || isEmpty(inputDateStr)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(
      featureProductHighlightTypePath as SeriesPath,
      inputDateStr !== feature.productData?.value
        ? HighlightType.Edited.toString()
        : null,
    );
    setValue(
      featureProductDataModifiedPath as SeriesPath,
      inputDateStr !== feature.productData?.value,
    );
    setValue(
      featureModifiedPath as SeriesPath,
      inputDateStr !== feature.productData?.value,
    );
    setValue(featureProductDataPath as SeriesPath, inputDateStr);
    setValue(sectionModifiedPath as SeriesPath, true);
    setValue(`${investmentSeriesDataPath}.isModified` as SeriesPath, true);
  };

  if (!investmentProductSeriesData) {
    return <></>;
  }

  return (
    <>
      <SHStack
        key={featureProductDataPath}
        direction={"row"}
        alignItems="center"
        justifyContent={"flex-start"}
        gap={2.5}
        sx={{
          pt: "10px",
          pb: "5px",
          wordBreak: "break-word",
        }}
      >
        <SHStack width={"38%"}>
          <Controller
            name={featureProductDataPath as any}
            control={control}
            render={() => (
              <SHDatePicker
                label={
                  <SHLabel
                    dotGroupProps={{
                      orangeDot: isShowOrangeDot,
                      blueDot: isShowBlueDot,
                    }}
                    label={
                      <SHTypography variant="subtitle2">
                        {feature?.name}
                        {!isEmpty(feature.description) && (
                          <InformationButton content={feature.description} />
                        )}
                      </SHTypography>
                    }
                  />
                }
                openOnClick
                onChange={handleInputChange}
                maxDate={lastDayOfYear(new Date())}
                minDate={new Date("1/1/2000")}
                views={["year", "month", "day"]}
                inputFormat="dd/MM/yy"
                disabled={isDisabled}
                value={
                  Boolean(productDataValue)
                    ? parse(productDataValue as string, DateFormat, new Date())
                    : null
                }
                sxTextField={{
                  "&>.MuiInput-root>input": {
                    padding: "8px 5px 5px 5px",
                  },
                }}
              />
            )}
          />
        </SHStack>
        {feature.allowedVisibilitySetting && (
          <SHStack width={"30%"}>
            <Controller
              name={featureViewingGroupSelectionsPath as SeriesPath}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SelectViewingGroups
                  textFieldProps={{
                    label: `Visible to`,
                  }}
                  onChange={(value) => {
                    other.onChange(value);
                    debounceVisibilityChange(value);
                  }}
                  value={
                    (getValues(
                      featureViewingGroupSelectionsPath as SeriesPath,
                    ) as ViewingGroupSelectionDTO[] | null) || []
                  }
                  viewingGroupData={feature.viewingGroupSelections ?? []}
                  disabled={isDisabled}
                />
              )}
            />
          </SHStack>
        )}
      </SHStack>
      <SHDivider />
    </>
  );
};

export default SeriesFeatureDateTime;
