import {
  SHBox,
  SHButton,
  SHMenuItem,
  SHPopper,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import LogoutDialog from "@components/dialogs/logout";
import { ArrowDownSVG, LogoutSVG, UsersSVG, UserSVG } from "@components/svgs";
import { LocalStorage } from "@constants";
import { PageRoutes } from "@constants/routes";
import { useBroadcastChannel } from "@hooks/useBroadcastChannel";
import { useUserPermissions } from "@hooks/userUserPermission";
import { SupplierUsersGroup } from "@models/users/group";
import { Slide, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { constructUserName, hexToRGBA } from "@utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  generatePath,
  NavigateOptions,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { UserDropdownContainer } from "./portal";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { TopNotificationItem, ProductBannerType } from "@components/top-notifications/model"

export const UserDropDown = (): JSX.Element => {
  const {
    isAdviserGroup,
    canViewSupplierOverview,
    canViewAdminList,
    isSupplierGroup,
    isAdviserAdminGroup,
    isAdviserTrialGroup,
  } = useUserPermissions();
  const { palette } = useTheme();
  const { user } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const isProfilePage = useMatch(
    `${PageRoutes.users}/${encodeURI(user?.auth0Id || "")}`,
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [visibleLogout, setVisibleLogout] = useState(false);
  const { logoutBroadcast } = useBroadcastChannel();
  const { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } = useLocalStorage();

  const productBannerItemKey = `${LocalStorage.productBannerInfo}+${user?.auth0Id}`;
  const investmentProductBannerItemKey = `${LocalStorage.investmentProductBannerInfo}+${user?.auth0Id}`;

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = (route?: string, options?: NavigateOptions) => {
    setOpen(false);
    if (route === PageRoutes.callback) {
      setVisibleLogout(true);
      return;
    }
    if (route) navigate(route, options);
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open]);

  //Product banner behavior user level
  const removeDraftAndMissingBanners = () => {
    const productBanners = getLocalStorageItem(productBannerItemKey);
    
    // eslint-disable-next-line
    const newProductBanners: any = new Object();

    for (const productBanner in productBanners) {
      const notifications: TopNotificationItem[] = productBanners[productBanner];

      const newNotifications = notifications.filter(item => {
        return !(
          item.productBannerType === ProductBannerType.Draft ||
          item.productBannerType === ProductBannerType.MissingData
        );
      });

      if (newNotifications.length > 0) {
        newProductBanners[productBanner] = newNotifications;
      }
    }

    if (Object.keys(newProductBanners).length === 0) {
      removeLocalStorageItem(productBannerItemKey);
    } else {
      setLocalStorageItem(productBannerItemKey, newProductBanners);
    }
  };

  //Product banner behavior user level
  const removeDraftAndMissingInvestmentProductBanners = () => {
    const productBanners = getLocalStorageItem(investmentProductBannerItemKey);
    
    // eslint-disable-next-line
    const newProductBanners: any = new Object();

    for (const productBanner in productBanners) {
      const notifications: TopNotificationItem[] = productBanners[productBanner];

      const newNotifications = notifications.filter(item => {
        return !(
          item.productBannerType === ProductBannerType.Draft ||
          item.productBannerType === ProductBannerType.MissingData
        );
      });

      if (newNotifications.length > 0) {
        newProductBanners[productBanner] = newNotifications;
      }
    }

    if (Object.keys(newProductBanners).length === 0) {
      removeLocalStorageItem(investmentProductBannerItemKey);
    } else {
      setLocalStorageItem(investmentProductBannerItemKey, newProductBanners);
    }
  };

  const handleOnLogout = () => {
    removeDraftAndMissingBanners()
    removeDraftAndMissingInvestmentProductBanners();
    logoutBroadcast(process.env.REACT_APP_AUTH0_REDIRECT_URL);
  };

  const onClickProfile = () => {
    if (!user) return;
    if (
      user.userMetadata?.supplier_id &&
      SupplierUsersGroup.includes(user.userType) &&
      user.auth0Id
    ) {
      return handleClose(
        generatePath(PageRoutes.supplierUser, {
          supplierId: user.userMetadata.supplier_id,
          supplierUserId: user.auth0Id,
        }),
      );
    }
    if (isAdviserGroup && user.userMetadata?.adviser_firm_id) {
      return handleClose(
        generatePath(PageRoutes.practiceUsers, {
          practiceId: user.userMetadata.adviser_firm_id,
          auth0Id: user.auth0Id,
        }),
      );
    }
    return handleClose(`${PageRoutes.users}/${user?.auth0Id}`);
  };

  const onClickUserSubscript = () => {
    if (isSupplierGroup) {
      if (!user?.userMetadata?.supplier_id) return;
      return handleClose(
        generatePath(PageRoutes.supplierOverview, {
          supplierId: user?.userMetadata?.supplier_id,
        }),
      );
    }
    if (isAdviserAdminGroup || isAdviserTrialGroup) {
      if (!user?.userMetadata?.adviser_firm_id) return;
      return handleClose(
        generatePath(PageRoutes.practiceOverview, {
          practiceId: user?.userMetadata?.adviser_firm_id,
        }),
      );
    }
  };

  const showUserSubscriptionTab =
    (canViewSupplierOverview && isSupplierGroup) ||
    isAdviserAdminGroup ||
    isAdviserTrialGroup;

  return (
    <SHBox>
      <SHButton
        onClick={handleOpen}
        ref={buttonRef}
        sx={{
          borderColor: palette.text.primary,
          borderRadius: 0,
          borderWidth: { xs: "0 0 0 1px", lg: "0 1px" },
          "&:active": {
            borderColor: palette.text.primary,
            borderWidth: { xs: "0 0 0 1px", lg: "0 1px" },
          },
          width: { sm: 160, md: 200, lg: 220 },
          height: 80,
          px: { xs: 1, md: 2, lg: 3 },
        }}
        data-testid="user-dropdown-button"
      >
        <SHStack
          direction={"row"}
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ paddingTop: "4px" }}
        >
          <SHTypography
            variant={"subtitle1"}
            sx={{
              fontSize: { xs: "0.875rem", lg: "1rem" },
              whiteSpace: "pre-line",
              textAlign: "center",
            }}
          >
            {constructUserName(user?.name, user?.firstName, user?.lastName)}
          </SHTypography>

          <SHStack
            alignItems={"center"}
            style={{ marginTop: "-4px" }}
            sx={{
              transition: "all ease-in-out 0.2s",
              transform: `rotate(${!open ? 0 : 180}deg)`,
            }}
          >
            <ArrowDownSVG />
          </SHStack>
        </SHStack>
      </SHButton>
      <UserDropdownContainer>
        <SHPopper
          anchorEl={anchorEl}
          open={open}
          placement="bottom-start"
          popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Slide {...TransitionProps} timeout={350}>
              <SHBox
                ref={wrapperRef}
                sx={{
                  width: { sm: 160, md: 200, lg: 220 },
                  boxShadow: "none",
                  borderColor: palette.text.primary,
                  borderStyle: "solid",
                  borderRadius: 0,
                  borderWidth: "0 1px 1px 1px",
                  backgroundColor: hexToRGBA(palette.background.paper, 0.95),
                  boxSizing: "border-box",
                }}
              >
                <SHMenuItem
                  onClick={onClickProfile}
                  selected={!!isProfilePage}
                  sx={{ height: "50px" }}
                  data-testid="user-profile-item"
                >
                  <SHStack spacing={1} direction="row">
                    <UserSVG />
                    <SHTypography variant="body1">Profile</SHTypography>
                  </SHStack>
                </SHMenuItem>
                {canViewAdminList && (
                  <SHMenuItem
                    onClick={() => handleClose(PageRoutes.users)}
                    selected={location.pathname === PageRoutes.users}
                    sx={{ height: "50px" }}
                    data-testid="user-suit-item"
                  >
                    <SHStack spacing={1} direction="row">
                      <UsersSVG />
                      <SHTypography variant="body1">
                        SuitabilityHub users
                      </SHTypography>
                    </SHStack>
                  </SHMenuItem>
                )}
                {showUserSubscriptionTab && (
                  <SHMenuItem
                    sx={{ height: "50px" }}
                    data-testid="user-subscript-item"
                    onClick={onClickUserSubscript}
                  >
                    <SHStack spacing={1} direction="row">
                      <UsersSVG />
                      <SHTypography variant="body1">
                        Users & subscriptions
                      </SHTypography>
                    </SHStack>
                  </SHMenuItem>
                )}
                <SHMenuItem
                  onClick={() => {
                    handleClose(PageRoutes.callback);
                  }}
                  data-testid="user-logout-item"
                  sx={{ height: "50px" }}
                >
                  <SHStack spacing={1} direction="row">
                    <LogoutSVG />
                    <SHTypography variant="body1">Log out</SHTypography>
                  </SHStack>
                </SHMenuItem>
              </SHBox>
            </Slide>
          )}
        </SHPopper>
      </UserDropdownContainer>
      <LogoutDialog
        open={visibleLogout}
        onClose={() => setVisibleLogout(false)}
        onOk={handleOnLogout}
      />
    </SHBox>
  );
};
