import { FRCell } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/table-cell";
import { SHLabel, SHTypography } from "@components/design-systems";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InformationButton } from "@components/buttons/information";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";

interface SeriesFeatureTableLabelProps {
  productFeatureData: InvestmentProductConfigurationFeature;
  featureDataPath: SeriesPath;
}

const SeriesFeatureTableLabel = ({
  productFeatureData,
  featureDataPath,
}: SeriesFeatureTableLabelProps) => {
  const { control, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: `${featureDataPath}.highlightType` as SeriesPath,
      dataStatus: `${featureDataPath}.dataStatus` as SeriesPath,
    },
  });

  return (
    <FRCell key={productFeatureData.id}>
      <SHLabel
        dotGroupProps={{
          orangeDot: isShowOrangeDot,
          blueDot: isShowBlueDot,
        }}
        label={
          <SHTypography variant="subtitle2">
            {productFeatureData.name}
            {!isEmpty(productFeatureData.description) && (
              <InformationButton content={productFeatureData.description} />
            )}
          </SHTypography>
        }
      />
    </FRCell>
  );
};

export default SeriesFeatureTableLabel;
