import { InformationButton } from "@components/buttons/information";
import {
  SHBooleanField,
  SHRichTextEditor,
  SHStack,
} from "@components/design-systems";
import {
  useEditESGPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { debounce, isEqual } from "lodash";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EditESGInputControllerProps,
  handleEditESGAdditionalValueChange,
  handleEditEsgYesNoFieldChange,
  toEditESGNativeValue,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { MAX_LONG_TEXT_LENGTH } from "@constants";

export const EditESGYesNoInput = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const [fieldValue, setFieldValue] = useState<string | undefined>(
    featureData.productData?.value,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductEsgDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const displayAdditionalValueField: boolean = useMemo(() => {
    const fieldValueIsTrue = isEqual(fieldValue, "true");
    return [!!featureData.hasAdditionalValue, fieldValueIsTrue].every(Boolean);
  }, [fieldValue, featureData]);

  const handleOnChange = (newValue: string) => {
    setFieldValue(newValue);
    handleEditEsgYesNoFieldChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const handleOnAdditionalValueChange = (newValue: string) => {
    setFieldValue(newValue);
    handleEditESGAdditionalValueChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  const debouncedHandleEditEsgAdditionalValueChange = debounce(
    handleOnAdditionalValueChange,
    EditProductDefaults.DebounceTime,
  );

  const renderAdditionalValueField = () => {
    if (!displayAdditionalValueField) return <></>;

    return (
      <Controller
        name={featureProductDataPath.additionalValue as any}
        control={control}
        render={({ field: { ref, ...other } }) => (
          <SHRichTextEditor
            {...other}
            disabled={isDisabled}
            maxLength={MAX_LONG_TEXT_LENGTH}
            label={EditProductDefaults.AdditionalFieldTitle}
            height={165}
            onChange={(value) => {
              other.onChange(value);
              debouncedHandleEditEsgAdditionalValueChange(value);
            }}
          />
        )}
      />
    );
  };

  return (
    <SHStack direction="column" gap={2}>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { ref, ...other } }) => {
          return (
            <SHBooleanField
              {...other}
              sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
              disabled={isDisabled}
              value={toEditESGNativeValue(other.value, FieldTypeId.YesNo)}
              onChange={(_, value?: boolean | null) => {
                const newValue = toStringValue(value, FieldTypeId.YesNo);
                other.onChange(newValue);
                debouncedHandleInputChange(newValue);
              }}
              label={featureData.name}
              postfixLabel={postfixLabel}
              dotGroupProps={{
                blueDot: isShowBlueDot,
                orangeDot: isShowOrangeDot,
                greenDot: featureData?.isBusinessMetric,
              }}
            />
          );
        }}
      />

      {renderAdditionalValueField()}
    </SHStack>
  );
};
