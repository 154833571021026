import { FieldTypeId, HighlightType } from "@models/configuration";
import {
  InvestmentProductConfigurationFeature,
  InvestmentProductConfigurationGroup,
} from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { defaultTo, isEmpty, isEqual } from "lodash";
import {
  FieldPath,
  UseFormGetValues,
  UseFormSetValue
} from "react-hook-form";

export type EditManagerInputControllerProps = {
  isCreatingInvestmentProduct?: boolean;
  featureData: InvestmentProductConfigurationFeature;
  featureIndex: number;
  sectionIndex: number;
  isManagerCodeField?: boolean;
  isManagerNameField?: boolean;
  isProductTypeDropdown?: boolean;
  isDisabled?: boolean;
  onChange?: ({
    sectionIndex,
    featureIndex,
    newValue,
  }: {
    sectionIndex: number;
    featureIndex: number;
    newValue?: string;
  }) => void;
};

export type EditManagerNumberTextInputControllerProps =
  EditManagerInputControllerProps & {
    suffix?: string;
    prefix?: string;
    placeholder?: string;
  };

export type ManagerGroupDataPath = {
  name: EditManagerFieldPath;
  highlightType: EditManagerFieldPath;
  dataStatus: EditManagerFieldPath;
  isModified: EditManagerFieldPath;
  viewingGroupSelections: EditManagerFieldPath;
  sections?: EditManagerFieldPath[];
};

export type ManagerSectionDataPath = Omit<
  ManagerGroupDataPath,
  "viewingGroupSelections"
> & {
  features?: EditManagerFieldPath[];
};

export type ManagerFeatureDataPath = {
  highlightType: EditManagerFieldPath;
  dataStatus: EditManagerFieldPath;
  isModified: EditManagerFieldPath;
  viewingGroupSelections: EditManagerFieldPath;
};

export type EditManagerFieldPath = FieldPath<{
  group: InvestmentProductConfigurationGroup;
}>;

export type ManagerFeatureProductDataPath = {
  value: EditManagerFieldPath;
  publishedValue: EditManagerFieldPath;
  highlightType: EditManagerFieldPath;
  dataStatus: EditManagerFieldPath;
  isModified: EditManagerFieldPath;
  additionalValue: EditManagerFieldPath;
  publishedAdditionalValue: EditManagerFieldPath;
  isPositiveScore: EditManagerFieldPath;
};

export const toEditManagerNativeValue = (
  value: any,
  fieldType: FieldTypeId,
) => {
  switch (fieldType) {
    case FieldTypeId.Number:
      return isEmpty(value) ? null : +value;
    case FieldTypeId.Slideshow:
    case FieldTypeId.Image:
      return isEmpty(value) ? null : JSON.parse(value);
    case FieldTypeId.YesNo:
      return isEmpty(value) ? null : isEqual(value, "true");
    case FieldTypeId.NumberText:
      return isEmpty(value) ? null : isEqual(value, "N/A") ? value : +value;
    default:
      return value;
  }
};

export const getEditManagerGroupDataFieldPath = (): ManagerGroupDataPath => ({
  name: `group.name` as EditManagerFieldPath,
  highlightType: `group.highlightType` as EditManagerFieldPath,
  isModified: `group.isModified` as EditManagerFieldPath,
  dataStatus: `group.dataStatus` as EditManagerFieldPath,
  viewingGroupSelections:
    `group.viewingGroupSelections` as EditManagerFieldPath,
});

export const getEditManagerSectionDataPath = (
  sectionIndex: number,
): ManagerSectionDataPath => ({
  name: `group.sections.${sectionIndex}.name` as EditManagerFieldPath,
  highlightType:
    `group.sections.${sectionIndex}.highlightType` as EditManagerFieldPath,
  isModified:
    `group.sections.${sectionIndex}.isModified` as EditManagerFieldPath,
  dataStatus:
    `group.sections.${sectionIndex}.dataStatus` as EditManagerFieldPath,
});

export const getEditManagerFeaturePath = (
  sectionIndex: number,
  featureIndex: number,
): EditManagerFieldPath =>
  `group.sections.${sectionIndex}.features.${featureIndex}` as EditManagerFieldPath;

export const getEditManagerFeatureDataPath = (
  sectionIndex: number,
  featureIndex: number,
): ManagerFeatureDataPath => {
  const featurePath = getEditManagerFeaturePath(sectionIndex, featureIndex);

  return {
    highlightType: `${featurePath}.highlightType` as EditManagerFieldPath,
    dataStatus: `${featurePath}.dataStatus` as EditManagerFieldPath,
    isModified: `${featurePath}.isModified` as EditManagerFieldPath,
    viewingGroupSelections:
      `${featurePath}.viewingGroupSelections` as EditManagerFieldPath,
  };
};

export const getEditManagerFeatureProductDataPath = (
  sectionIndex: number,
  featureIndex: number,
): ManagerFeatureProductDataPath => {
  const featurePath = getEditManagerFeaturePath(sectionIndex, featureIndex);

  return {
    value: `${featurePath}.productData.value` as EditManagerFieldPath,
    publishedValue:
      `${featurePath}.productData.publishedValue` as EditManagerFieldPath,
    dataStatus: `${featurePath}.productData.dataStatus` as EditManagerFieldPath,
    additionalValue:
      `${featurePath}.productData.additionalValue` as EditManagerFieldPath,
    highlightType:
      `${featurePath}.productData.highlightType` as EditManagerFieldPath,
    isModified: `${featurePath}.productData.isModified` as EditManagerFieldPath,
    publishedAdditionalValue:
      `${featurePath}.productData.publishedAdditionalValue` as EditManagerFieldPath,
    isPositiveScore:
      `${featurePath}.productData.isPositiveScore` as EditManagerFieldPath,
  };
};

export const handleOnEditManagerGoodScoreFieldChange = ({
  originalValue,
  newValue,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: boolean | null;
  newValue?: boolean | null;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
}) => {
  setValue(
    featureProductDataPath.isModified,
    !isEqual(!!originalValue, newValue),
  );
  setValue(featureProductDataPath.isPositiveScore, newValue);
};

export const handleOnEditManagerFieldChange = ({
  originalValue,
  isCreatingInvestmentProduct,
  newValue,
  getValues,
  setValue,
  featureProductDataPath,
  ignoreDataStatus = false,
}: {
  originalValue?: string | null;
  isCreatingInvestmentProduct?: boolean;
  newValue: string;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
  ignoreDataStatus?: boolean;
}) => {
  const publishedValue = defaultTo(
    getValues(featureProductDataPath.publishedValue),
    "",
  );

  let highlightType: HighlightType | null;
  let dataStatus: InvestmentDataStatus | null;

  if (isCreatingInvestmentProduct) {
    highlightType = !!newValue ? HighlightType.Added : null;
  } else {
    highlightType = !isEqual(publishedValue, newValue)
      ? HighlightType.Edited
      : null;
  }

  if (ignoreDataStatus) {
    dataStatus = InvestmentDataStatus.Filled;
  } else {
    dataStatus = !!newValue
      ? InvestmentDataStatus.Filled
      : InvestmentDataStatus.MissingData;
  }

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(featureProductDataPath.dataStatus, dataStatus);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};

export const handleEditManagerYesNoFieldChange = ({
  originalValue,
  newValue,
  isCreatingInvestmentProduct,
  getValues,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: string | null;
  newValue: string;
  isCreatingInvestmentProduct?: boolean;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
}) => {
  const publishedValue = defaultTo(
    getValues(featureProductDataPath.publishedValue),
    "",
  );

  let highlightType: HighlightType | null;
  let dataStatus: InvestmentDataStatus | null;

  if (isCreatingInvestmentProduct) {
    highlightType = !!newValue ? HighlightType.Added : null;
  } else {
    highlightType = !isEqual(publishedValue, newValue)
      ? HighlightType.Edited
      : null;
  }

  dataStatus = !!newValue
    ? InvestmentDataStatus.Filled
    : InvestmentDataStatus.MissingData;

  const newValueIsTrue = isEqual(newValue, "true");
  if (newValueIsTrue) {
    const publishedAdditionalValue =
      getValues(featureProductDataPath.publishedAdditionalValue) ?? "";
    const additionalValue =
      getValues(featureProductDataPath.additionalValue) ?? "";

    const additionalValueEdited = !isEqual(
      publishedAdditionalValue,
      additionalValue,
    );

    highlightType = additionalValueEdited
      ? HighlightType.Edited
      : highlightType;
  }

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(featureProductDataPath.dataStatus, dataStatus);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};

export const handleEditManagerAdditionalValueChange = ({
  originalValue,
  isCreatingInvestmentProduct,
  newValue,
  getValues,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: string | null;
  isCreatingInvestmentProduct?: boolean;
  newValue?: string | null;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
}) => {
  const publishedAdditionalValue = defaultTo(
    getValues(featureProductDataPath.publishedAdditionalValue),
    "",
  );

  let highlightType: HighlightType | null;

  if (isCreatingInvestmentProduct) {
    highlightType = !!newValue ? HighlightType.Added : null;
  } else {
    highlightType = !isEqual(publishedAdditionalValue, newValue)
      ? HighlightType.Edited
      : null;
  }

  const mainValue = getValues(featureProductDataPath.value) ?? "";
  const publishedMainValue =
    getValues(featureProductDataPath.publishedValue) ?? "";
  const mainValueEdited = !isEqual(mainValue, publishedMainValue);

  highlightType = mainValueEdited ? HighlightType.Edited : highlightType;

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};
