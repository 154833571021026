import {
  SHCheckbox,
  SHFormControlLabel,
  SHStack,
  SHTextField,
} from "@components/design-systems";
import {
  SHNumericFieldV2,
  SHNumericFieldV2Props,
} from "@components/design-systems/sh-numeric-field-v2";
import { FieldTypeId } from "@models/configuration";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { checkStringIsNumber } from "@pages/suppliers/_id/util";
import { isEmpty, isEqual, toString } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useToggle } from "react-use";

type SHNumberTextFieldProps = Omit<
  SHNumericFieldV2Props,
  "onValueChange" | "onChange"
> & {
  onChange: (value: string | null) => void;

  fixedTextValue?: string;
};

export const SHNumberTextField = ({
  value,
  fixedTextValue = "N/A",
  placeholder,
  postfixLabel,
  dotGroupProps,
  label,
  onChange,
  disabled,
  ...others
}: SHNumberTextFieldProps) => {
  const previousNumberValue = useRef(value);
  useEffect(() => {
    if (checkStringIsNumber(toString(value)) || isEmpty(value)) {
      previousNumberValue.current = value;
    }
    toggleIsNotApplicable(isEqual(value, fixedTextValue));
    // eslint-disable-next-line
  }, [value]);
  const [isNotApplicable, toggleIsNotApplicable] = useToggle(
    isEqual(value, fixedTextValue),
  );

  const fieldValueIsNumber = useMemo(() => {
    return checkStringIsNumber(toStringValue(value, FieldTypeId.NumberText));
  }, [value]);

  const renderInputField = () => {
    if (isNotApplicable) return renderFixedTextField();
    return renderNumericField();
  };

  const renderFixedTextField = () => (
    <SHTextField
      {...others}
      value={fixedTextValue}
      label={label}
      hasCounter={false}
      postfixLabel={postfixLabel}
      dotGroupProps={dotGroupProps}
      disabled={true}
    />
  );

  const renderNumericField = () => {
    let parsedValue: string | number | null | undefined;
    const isReturnFormFixedValue = isEqual(value, fixedTextValue);
    if (isReturnFormFixedValue) {
      parsedValue = previousNumberValue.current ?? "";
    } else {
      parsedValue = fieldValueIsNumber ? value : "";
    }

    return (
      <SHNumericFieldV2
        {...others}
        value={parsedValue}
        label={label}
        disabled={disabled}
        placeholder={placeholder}
        postfixLabel={postfixLabel}
        dotGroupProps={dotGroupProps}
        valueIsNumericString
        onValueChange={({ value, floatValue }) => {
          onChange(value);
        }}
      />
    );
  };

  return (
    <SHStack
      direction="row"
      sx={{
        alignItems: "end",
        position: "relative",
        ".MuiInput-root input": {
          marginRight: "60px",
        },
      }}
    >
      <SHStack sx={{ width: "100%" }}>{renderInputField()}</SHStack>

      <SHStack
        sx={{ alignItems: "start", position: "absolute", right: -5, bottom: 4 }}
      >
        <SHFormControlLabel
          size="small"
          control={
            <SHCheckbox
              disabled={disabled}
              checked={isNotApplicable}
              size={"small"}
              onClick={() => {
                if (isNotApplicable) {
                  toggleIsNotApplicable(false);
                  onChange(
                    toStringValue(
                      previousNumberValue.current,
                      FieldTypeId.NumberText,
                    ),
                  );
                  return;
                }

                toggleIsNotApplicable(true);
                onChange(fixedTextValue);
              }}
            />
          }
          data-testid="sh-head-filter-all-button"
          label={fixedTextValue}
        />
      </SHStack>
    </SHStack>
  );
};
