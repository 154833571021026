import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { generateFeatureName } from "@utils/text-format";
import {
  SHDivider,
  SHLabel,
  SHRichTextEditor,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { HighlightType } from "@models/configuration";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { getFeatureDataPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/edit-series-right-panel";

interface SeriesFeatureTextLongProps {
  feature: InvestmentProductConfigurationFeature;
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  selectedIndex: [number, number];
  isDisabled?: boolean;
}

const SeriesFeatureTextLong = ({
  feature,
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  isDisabled = false,
}: SeriesFeatureTextLongProps) => {
  const featureName = generateFeatureName(
    feature.name ?? "",
    feature.description,
  );
  const { control, setValue, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const {
    investmentSeriesDataPath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataStatusPath,
    featureProductDataModifiedPath,
    investmentSeriesDataHighlightTypePath,
    featureProductDataPublishedPath,
    featureViewingGroupSelectionsPath,
  } = getFeatureDataPath(selectedIndex, investmentProductSeriesData, feature);
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductHighlightTypePath as SeriesPath,
      dataStatus: featureProductDataStatusPath as SeriesPath,
    },
  });

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(feature.viewingGroupSelections, newValue);

    setValue(
      `${investmentSeriesDataPath}.isModified` as SeriesPath,
      isModified,
    );
    setValue(sectionModifiedPath as SeriesPath, isModified);
    setValue(featureModifiedPath as SeriesPath, isModified);
    setValue(featureProductDataModifiedPath as SeriesPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const handleInputChange = (inputValue: string) => {
    const publishedData = getValues(
      featureProductDataPublishedPath as SeriesPath,
    );

    setValue(sectionModifiedPath as SeriesPath, true);
    setValue(`${investmentSeriesDataPath}.isModified` as SeriesPath, true);
    setValue(
      investmentSeriesDataHighlightTypePath as SeriesPath,
      inputValue !== publishedData ? HighlightType.Edited.toString() : "",
    );
    setValue(
      featureModifiedPath as SeriesPath,
      inputValue !== feature.productData?.value,
    );
    setValue(
      featureProductHighlightTypePath as SeriesPath,
      inputValue !== feature.productData?.value
        ? HighlightType.Edited.toString()
        : "",
    );
    setValue(
      featureProductDataStatusPath as SeriesPath,
      isNil(inputValue) || isEmpty(inputValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(
      featureProductDataModifiedPath as SeriesPath,
      inputValue !== feature.productData?.value,
    );
    setValue(featureProductDataPath as SeriesPath, inputValue);
  };

  if (!investmentProductSeriesData) {
    return <></>;
  }

  return (
    <>
      <SHDivider />
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        gap={2}
        sx={{
          pt: "10px",
          pb: "5px",
          wordBreak: "break-word",
        }}
        key={featureProductDataPath}
        width={"100%"}
      >
        <SHStack justifyContent="space-between">
          <SHStack>
            <SHLabel
              dotGroupProps={{
                orangeDot: isShowOrangeDot,
                blueDot: isShowBlueDot,
              }}
              label={
                <SHTypography variant="subtitle2">
                  {featureName.first}
                  {featureName.last}
                </SHTypography>
              }
            />
            {feature?.description && (
              <SHTypography>{feature.description}</SHTypography>
            )}
          </SHStack>

          {feature.allowedVisibilitySetting && (
            <SHStack direction={"row"} alignItems={"center"}>
              <SHLabel
                label={
                  <SHTypography disabled={isDisabled} variant="subtitle2">
                    Visible to
                  </SHTypography>
                }
              />
              <Controller
                name={featureViewingGroupSelectionsPath as SeriesPath}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SelectViewingGroups
                    sx={{
                      width: "70%",
                    }}
                    onChange={(value) => {
                      other.onChange(value);
                      debounceVisibilityChange(value);
                    }}
                    value={
                      (getValues(
                        featureViewingGroupSelectionsPath as SeriesPath,
                      ) as ViewingGroupSelectionDTO[] | null) || []
                    }
                    viewingGroupData={feature.viewingGroupSelections ?? []}
                    disabled={isDisabled}
                  />
                )}
              />
            </SHStack>
          )}
        </SHStack>
        <SHStack>
          <Controller
            control={control}
            name={featureProductDataPath as any}
            render={({ field: { ref, onChange, ...other } }) => {
              return (
                <SHRichTextEditor
                  {...other}
                  label={EditProductDefaults.AdditionalFieldTitle}
                  maxLength={MAX_LONG_TEXT_LENGTH}
                  onChange={handleInputChange}
                  disabled={isDisabled}
                />
              );
            }}
          />
        </SHStack>
      </SHStack>
    </>
  );
};

export default SeriesFeatureTextLong;
