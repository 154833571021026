import { APIExtRoutes } from "@constants";
import { InvestmentProductBannerInfoDTO } from "@models/product/investment-product/entities/bannerInfo";
import { InvestmentProductBrandingDataDTO } from "@models/product/investment-product/entities/brandingData";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getInvestmentProductBannerInfo,
  getInvestmentProductConfigurationModel,
  getInvestmentProductDots,
  getInvestmentProductESGData,
  getInvestmentProductManager,
  getInvestmentProductModels,
  getOrUpdateInvestmentProductSeries,
  getProductManagedBrandingInfo,
  investmentProductPublishDraft,
  investmentProductRecallSubmission,
  investmentProductRevertToDraft,
  investmentProductSubmitForReview,
  postInvestmentProduct,
  putInvestmentProductEsgData,
  putInvestmentProductManagerData,
  putInvestmentProductModels,
} from "@services/product/investment-product/investmentProductService";
import {
  investmentProductEditTabsData,
  investmentProductViewTabsData,
} from "@services/product/investment-product/investmentProductTab";
import { InvestmentProductDTO } from "@models/product/investment-product/entities/investmentProduct";
import {
  getEditInvestmentProductTabIndex,
  InvestmentProductSelectedTab,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/util";
import { ProductType } from "@models/product/enums/productType";

interface InvestmentProductStore {
  investmentProductGroups: InvestmentConfigurationGroup[];
  investmentProductViewTabs: InvestmentConfigurationGroup[];
  investmentProductEditTabs: InvestmentConfigurationGroup[];
  investmentProductUI: {
    isEditMode?: boolean;
    isLoading?: boolean;
    isManagerDataLoading?: boolean;
    isSeriesDataLoading?: boolean;
    isModelsDataLoading?: boolean;
    isEsgDataLoading?: boolean;
    isLoadingEditMode?: boolean;
    isLoadingInvestmentProductGroups?: boolean;
    isSaveDraftButtonDisabled?: boolean;
    isSaving?: boolean;
    investmentProductName?: string;
    beingKickedBy?: string;
    currentEditor?: string;
    productType?: ProductType;
    showClaimDeniedDialog?: boolean;
    showBeingKickedDialog?: boolean;
    showKickoutDialog?: boolean;
    selectedTab: number;
    selectedEditTab: number;
    isForbidden?: boolean;
  };
  investmentProductData?: InvestmentProductDTO;
  investmentProductBannerInfo?: InvestmentProductBannerInfoDTO[];
  investmentProductBrandingData?: InvestmentProductBrandingDataDTO;
  investmentProductManagerData?: InvestmentProductManagerDTO;
  investmentProductSeriesData?: InvestmentProductSeriesDTO[];
  investmentProductModelsData?: InvestmentProductModelsDTO[];
  investmentProductEsgData?: InvestmentProductEsgDTO;
}

const initialState: InvestmentProductStore = {
  investmentProductGroups: [],
  investmentProductViewTabs: investmentProductViewTabsData,
  investmentProductEditTabs: investmentProductEditTabsData,
  investmentProductUI: {
    investmentProductName: undefined,
    isEditMode: false,
    isLoading: undefined,
    isManagerDataLoading: undefined,
    isSeriesDataLoading: undefined,
    isModelsDataLoading: undefined,
    isEsgDataLoading: undefined,
    isLoadingEditMode: false,
    isLoadingInvestmentProductGroups: undefined,
    isSaveDraftButtonDisabled: undefined,
    isSaving: false,
    selectedTab: 0,
    selectedEditTab: 0,
    beingKickedBy: "",
    currentEditor: "",
    productType: undefined,
    showClaimDeniedDialog: false,
    showBeingKickedDialog: false,
    showKickoutDialog: false,
    isForbidden: false,
  },
  investmentProductData: undefined,
  investmentProductBannerInfo: undefined,
  investmentProductBrandingData: undefined,
  investmentProductManagerData: undefined,
  investmentProductSeriesData: undefined,
  investmentProductModelsData: undefined,
};

const investmentProductSlice = createSlice({
  name: "investmentProduct",
  initialState,
  reducers: {
    updateSelectedTab: (
      state,
      action: PayloadAction<InvestmentProductSelectedTab>,
    ) => {
      const isEditMode = action.payload.isEditMode;
      const payloadSelectedIndex = action.payload.selectedTab;

      state.investmentProductUI.selectedTab = payloadSelectedIndex;
      state.investmentProductUI.selectedEditTab = isEditMode
        ? payloadSelectedIndex
        : getEditInvestmentProductTabIndex(payloadSelectedIndex);
    },
    updateInvestmentProductManagerData: (
      state,
      action: PayloadAction<InvestmentProductManagerDTO | undefined>,
    ) => {
      state.investmentProductManagerData = action.payload;
    },
    updateInvestmentProductSeriesData: (
      state,
      action: PayloadAction<InvestmentProductSeriesDTO[] | undefined>,
    ) => {
      state.investmentProductSeriesData = action.payload;
    },
    updateInvestmentProductModelsData: (
      state,
      action: PayloadAction<InvestmentProductModelsDTO[] | undefined>,
    ) => {
      state.investmentProductModelsData = action.payload;
    },
    updateInvestmentProductEsgData: (
      state,
      action: PayloadAction<InvestmentProductManagerDTO | undefined>,
    ) => {
      state.investmentProductEsgData = action.payload;
    },
    updateInvestmentProductBannerInfo: (
      state,
      action: PayloadAction<InvestmentProductBannerInfoDTO[] | undefined>,
    ) => {
      state.investmentProductBannerInfo = action.payload;
    },
    updateInvestmentProductName: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.investmentProductUI.investmentProductName = action.payload;
    },
    toggleSaveDraftButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.isSaveDraftButtonDisabled = action.payload;
    },
    toggleAccessDeniedDialog: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.isForbidden = action.payload;
    },
    updateEditMode: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.isEditMode = action.payload;
    },
    updateLoadingEditMode: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.isLoadingEditMode = action.payload;
    },
    updateShowClaimDeniedDialog: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.showClaimDeniedDialog = action.payload;
    },
    updateShowBeingKickedDialog: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.showBeingKickedDialog = action.payload;
    },
    updateShowKickoutDialog: (state, action: PayloadAction<boolean>) => {
      state.investmentProductUI.showKickoutDialog = action.payload;
    },
    updateCurrentEditor: (state, action: PayloadAction<string>) => {
      state.investmentProductUI.currentEditor = action.payload;
    },
    updateBeingKickedBy: (state, action: PayloadAction<string>) => {
      state.investmentProductUI.beingKickedBy = action.payload;
    },
    updateProductType: (state, action: PayloadAction<ProductType>) => {
      state.investmentProductUI.productType = action.payload;
    },
    updateActiveInvestmentGroup: (
      state,
      action: PayloadAction<Partial<InvestmentConfigurationGroup>>,
    ) => {
      const currentSelectedGroup =
        state.investmentProductGroups[
          state.investmentProductUI.selectedEditTab
        ];

      if (!currentSelectedGroup) return;

      state.investmentProductGroups[state.investmentProductUI.selectedEditTab] =
        {
          ...currentSelectedGroup,
          ...action.payload,
        };
    },
    updateProductBannerInfo: (
      state,
      action: PayloadAction<InvestmentProductBannerInfoDTO[]>,
    ) => {
      state.investmentProductBannerInfo = action.payload;
    },
    resetInvestmentProduct: (state) => {
      state.investmentProductManagerData = undefined;
      state.investmentProductSeriesData = undefined;
      state.investmentProductModelsData = undefined;
      state.investmentProductEsgData = undefined;
    },
    resetForbidden: (state) => {
      state.investmentProductUI.isForbidden = false;
    },
  },
  extraReducers(builder) {
    // Load investment product groups
    builder.addCase(loadInvestmentGroupsThunk.pending, (state, action) => {
      if (!state.investmentProductGroups.length) {
        state.investmentProductUI.isLoadingInvestmentProductGroups = true;
      }
    });
    builder.addCase(loadInvestmentGroupsThunk.fulfilled, (state, action) => {
      state.investmentProductUI.isLoadingInvestmentProductGroups = false;
      state.investmentProductGroups = action.payload.isSuccess
        ? action.payload?.data ?? []
        : [];
    });
    builder.addCase(loadInvestmentGroupsThunk.rejected, (state, action) => {
      state.investmentProductUI.isLoadingInvestmentProductGroups = false;
    });

    // Load investment product manager data
    builder.addCase(
      loadInvestmentProductManagerThunk.pending,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductManagerThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = false;

        if (action.payload?.isForbidden) {
          state.investmentProductUI.isForbidden = true;
          return;
        }
        state.investmentProductManagerData = action.payload?.isSuccess
          ? action.payload?.data
          : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductManagerThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isManagerDataLoading = false;
      },
    );

    // Load investment product branding data
    builder.addCase(
      loadInvestmentProductBrandingDataThunk.pending,
      (state, action) => {
        state.investmentProductUI.isLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductBrandingDataThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isLoading = false;
        if (action.payload.isForbidden) {
          state.investmentProductUI.isForbidden = true;
          return;
        }
        state.investmentProductBrandingData = action.payload?.data;
      },
    );
    builder.addCase(
      loadInvestmentProductBrandingDataThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isLoading = false;
      },
    );

    // Load investment product ESG data
    builder.addCase(
      loadInvestmentProductESGDataThunk.pending,
      (state, action) => {
        state.investmentProductUI.isEsgDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductESGDataThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isEsgDataLoading = false;

        if (action.payload?.isForbidden) {
          state.investmentProductUI.isForbidden = true;
          return;
        }

        state.investmentProductEsgData = action.payload?.isSuccess
          ? action.payload?.data
          : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductESGDataThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isEsgDataLoading = false;
      },
    );

    // Load investment product series data
    builder.addCase(
      loadInvestmentProductSeriesThunk.pending,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = false;

        if (action.payload?.isForbidden) {
          state.investmentProductUI.isForbidden = true;
          return;
        }

        state.investmentProductSeriesData = action.payload?.isSuccess
          ? action.payload?.data
          : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductSeriesThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isSeriesDataLoading = false;
      },
    );

    // Load investment product models data
    builder.addCase(
      loadInvestmentProductModelsThunk.pending,
      (state, action) => {
        state.investmentProductUI.isModelsDataLoading = true;
      },
    );
    builder.addCase(
      loadInvestmentProductModelsThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isModelsDataLoading = false;

        if (action.payload?.isForbidden) {
          state.investmentProductUI.isForbidden = true;
          return;
        }

        state.investmentProductModelsData = action.payload?.isSuccess
          ? action.payload.data
          : undefined;
      },
    );
    builder.addCase(
      loadInvestmentProductModelsThunk.rejected,
      (state, action) => {
        state.investmentProductUI.isModelsDataLoading = false;
      },
    );

    // Create investment product
    builder.addCase(createInvestmentProductThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(createInvestmentProductThunk.fulfilled, (state) => {
      state.investmentProductUI.isSaving = false;
    });
    builder.addCase(createInvestmentProductThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Update investment product manager data
    builder.addCase(updateInvestmentProductManagerThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(
      updateInvestmentProductManagerThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isSaving = false;
        if (action.payload.isSuccess) {
          state.investmentProductManagerData = action.payload.data;
        }
      },
    );
    builder.addCase(updateInvestmentProductManagerThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Update investment product ESG data
    builder.addCase(updateInvestmentProductEsgThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(
      updateInvestmentProductEsgThunk.fulfilled,
      (state, action) => {
        state.investmentProductUI.isSaving = false;
        if (action.payload.isSuccess) {
          state.investmentProductEsgData = action.payload.data;
        }
      },
    );
    builder.addCase(updateInvestmentProductEsgThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Update investment product models data
    builder.addCase(updateInvestmentProductModelsThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(updateInvestmentProductModelsThunk.fulfilled, (state) => {
      state.investmentProductUI.isSaving = false;
    });
    builder.addCase(updateInvestmentProductModelsThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Update investment product series data
    builder.addCase(updateInvestmentProductSeriesThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(updateInvestmentProductSeriesThunk.fulfilled, (state) => {
      state.investmentProductUI.isSaving = false;
    });
    builder.addCase(updateInvestmentProductSeriesThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Submit for review
    builder.addCase(investmentProductSubmitForReviewThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(
      investmentProductSubmitForReviewThunk.fulfilled,
      (state) => {
        state.investmentProductUI.isSaving = false;
      },
    );
    builder.addCase(investmentProductSubmitForReviewThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Revert to draft
    builder.addCase(investmentProductRevertToDraftThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(investmentProductRevertToDraftThunk.fulfilled, (state) => {
      state.investmentProductUI.isSaving = false;
    });
    builder.addCase(investmentProductRevertToDraftThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Approve
    builder.addCase(investmentProductPublishDraftThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(investmentProductPublishDraftThunk.fulfilled, (state) => {
      state.investmentProductUI.isSaving = false;
    });
    builder.addCase(investmentProductPublishDraftThunk.rejected, (state) => {
      state.investmentProductUI.isSaving = false;
    });

    // Recall Submission
    builder.addCase(investmentProductRecallSubmissionThunk.pending, (state) => {
      state.investmentProductUI.isSaving = true;
    });
    builder.addCase(
      investmentProductRecallSubmissionThunk.fulfilled,
      (state) => {
        state.investmentProductUI.isSaving = false;
      },
    );
    builder.addCase(
      investmentProductRecallSubmissionThunk.rejected,
      (state) => {
        state.investmentProductUI.isSaving = false;
      },
    );
  },
});

export const {
  updateSelectedTab,
  updateInvestmentProductManagerData,
  updateInvestmentProductSeriesData,
  updateInvestmentProductModelsData,
  updateInvestmentProductEsgData,
  updateInvestmentProductBannerInfo,
  updateInvestmentProductName,
  toggleSaveDraftButtonDisabled,
  updateShowClaimDeniedDialog,
  updateEditMode,
  updateLoadingEditMode,
  updateShowBeingKickedDialog,
  updateShowKickoutDialog,
  updateCurrentEditor,
  updateBeingKickedBy,
  updateProductType,
  updateActiveInvestmentGroup,
  resetInvestmentProduct,
  resetForbidden,
  toggleAccessDeniedDialog,
} = investmentProductSlice.actions;

//Thunk functions
export const loadInvestmentProductManagerThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsManager,
  async ({
    investmentProductId,
    mode,
    supplierId,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
    supplierId?: string;
  }) => {
    return await getInvestmentProductManager(
      investmentProductId,
      mode,
      supplierId,
    );
  },
);

export const loadInvestmentProductBrandingDataThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsBrandingData,
  async (investmentProductId: string) => {
    return await getProductManagedBrandingInfo(investmentProductId);
  },
);

export const loadInvestmentProductESGDataThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsESG,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductESGData(investmentProductId, mode);
  },
);

export const loadInvestmentProductSeriesThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsSeries,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getOrUpdateInvestmentProductSeries(investmentProductId, mode);
  },
);

export const loadInvestmentProductBannerInfoThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsBannerInfo,
  async (productId: string) => {
    return await getInvestmentProductBannerInfo(productId);
  },
);

export const loadInvestmentProductModelsThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsModels,
  async ({
    investmentProductId,
    mode,
  }: {
    investmentProductId?: string;
    mode?: "view" | "edit";
  }) => {
    return await getInvestmentProductModels(investmentProductId, mode);
  },
);

export const loadInvestmentGroupsThunk = createAsyncThunk(
  APIExtRoutes.investmentProductGroups,
  async ({
    productId,
    version,
    configurationVersion,
    investmentConfigurationVersion,
  }: {
    productId?: string;
    version?: string;
    configurationVersion?: string;
    investmentConfigurationVersion?: string;
  }) => {
    return await getInvestmentProductDots(
      productId,
      version,
      configurationVersion,
      investmentConfigurationVersion,
    );
  },
);

export const updateInvestmentProductModelsThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsModels + "/update/models",
  async ({
    investmentProductId,
    investmentProductData,
  }: {
    investmentProductId: string;
    investmentProductData: InvestmentProductModelsDTO[];
  }) => {
    return await putInvestmentProductModels(
      investmentProductId,
      investmentProductData,
    );
  },
);

export const updateInvestmentProductSeriesThunk = createAsyncThunk(
  "updateSeries",
  async ({
    investmentProductId,
    seriesDto,
    mode,
  }: {
    investmentProductId: string;
    seriesDto: InvestmentProductSeriesDTO[];
    mode: "view" | "edit";
  }) => {
    return await getOrUpdateInvestmentProductSeries(
      investmentProductId,
      mode ?? "edit",
      seriesDto,
    );
  },
);

export const createInvestmentProductConfigurationModelThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsConfigurationModel + "/new",
  async ({
    seriesId,
    investmentProductId,
  }: {
    seriesId: string;
    investmentProductId: string;
  }) => {
    return await getInvestmentProductConfigurationModel(
      seriesId,
      investmentProductId,
    );
  },
);

export const createInvestmentProductThunk = createAsyncThunk(
  APIExtRoutes.investmentProducts + "/new",
  async (investmentProduct: InvestmentProductManagerDTO) => {
    return await postInvestmentProduct(investmentProduct);
  },
);

export const updateInvestmentProductManagerThunk = createAsyncThunk(
  APIExtRoutes.investmentProducts + "/update/manager",
  async ({
    productId,
    managerData,
  }: {
    productId: string;
    managerData: InvestmentProductManagerDTO;
  }) => {
    return await putInvestmentProductManagerData(productId, managerData);
  },
);

export const updateInvestmentProductEsgThunk = createAsyncThunk(
  APIExtRoutes.investmentProducts + "/update/esg",
  async ({
    productId,
    esgData,
  }: {
    productId: string;
    esgData: InvestmentProductEsgDTO;
  }) => {
    return await putInvestmentProductEsgData(productId, esgData);
  },
);

export const investmentProductSubmitForReviewThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsSubmitForReview,
  async (investmentProductId: string) => {
    return await investmentProductSubmitForReview(investmentProductId);
  },
);

export const investmentProductRevertToDraftThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsRevertToDraft,
  async (investmentProductId: string) => {
    return await investmentProductRevertToDraft(investmentProductId);
  },
);

export const investmentProductPublishDraftThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsPublishDraft,
  async (investmentProductId: string) => {
    return await investmentProductPublishDraft(investmentProductId);
  },
);

export const investmentProductRecallSubmissionThunk = createAsyncThunk(
  APIExtRoutes.investmentProductsRecallSubmission,
  async (investmentProductId: string) => {
    return await investmentProductRecallSubmission(investmentProductId);
  },
);

export default investmentProductSlice.reducer;
