import { SHTextField } from "@components/design-systems";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import {
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { RootState } from "@redux/store";
import { debounce, isEmpty, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
interface ModelFeatureTextShortProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationModelsFeature;
}

export const ModelFeatureTextShort = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: ModelFeatureTextShortProps) => {
  const {
    investmentProductUI: { isSaving },
  } = useSelector((state: RootState) => state.investmentProduct);
  const originalValue = feature.productData?.value;
  /** Hook */
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );
  /** State */

  /** Handler */
  const handleInputChange = (newValue: string | null) => {
    setValue(
      featureProductDataPath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(
      featurePath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(featurePath.isModified, !isEqual(newValue, originalValue));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalValue),
    );
    const publishedValue = getValues(featureProductDataPath.publishedValue);

    if (!isEqual(publishedValue, newValue)) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  };
  const debouncedHandleInputChange = debounce(
    handleInputChange,
    DEBOUNCE_DELAY,
  );

  /** Side effect */

  return (
    <Controller
      control={control}
      name={featureProductDataPath["value"]}
      render={({ field: { ref, ...other } }) => (
        <SHTextField
          disabled={isSaving}
          hasCounter
          maxLength={100}
          dotGroupProps={{
            orangeDot: isShowOrangeDot,
            blueDot: isShowBlueDot,
            greenDot: feature.isBusinessMetric,
          }}
          fullWidth
          placeholder="Please enter detail"
          label={feature.name}
          onChange={(e) => {
            const actualValue = isEmpty(e.target.value) ? null : e.target.value;
            other.onChange(actualValue);
            debouncedHandleInputChange(actualValue);
          }}
          value={getValues(featureProductDataPath.value)}
        />
      )}
    />
  );
};
