import { SHStack } from "@components/design-systems";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { FieldTypeId, SectionDisplayTypeId } from "@models/configuration";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import {
  EditInvestmentProductFormRef,
  EditInvestmentProductSaveDraftResult,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit";
import { EditModelsCollapseMenu } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/edit-model-collapse-menu";
import { FeatureDatePicker } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/date-picker";
import { ModelTitleTextField } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/model-title";
import { FeatureTextEditor } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/text-editor";
import { ModelFeatureTextShort } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/text-field";
import ModelPageTableEdit from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/table";
import { updateInvestmentProductModelsData } from "@redux/slices/product/investment-product";
import { RootState, useAppDispatch } from "@redux/store";
import { isEmpty } from "lodash";
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ModelsTabSkeleton } from "../../skeletons/ModelsTabSkeleton";
import { useIsNew } from "@hooks/useIsNew";

interface EditModelsTabProps {}

export const EditModelsTab = forwardRef<
  EditInvestmentProductFormRef,
  EditModelsTabProps
>((props: EditModelsTabProps, ref) => {
  /** Hooks */
  const dispatch = useAppDispatch();
  const formMethod = useForm<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    mode: "onChange",
    defaultValues: {
      investmentProductModels: [],
    },
  });
  const { updateInvestmentProductModels } = useInvestmentProduct();
  const { reset, getValues } = formMethod;
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const { isAdviserTrialGroup } = useUserPermissions();
  const {
    investmentProductModelsData,
    investmentProductUI: { isModelsDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductModels } = useInvestmentProduct();
  const isNewInvestmentProduct = useIsNew();
  const [productsIndex, modelsIndex] = selectedIndex;
  const selectedModel = useMemo(() => {
    return investmentProductModelsData?.[productsIndex]?.models?.[modelsIndex];
  }, [investmentProductModelsData, modelsIndex, productsIndex]);

  const {
    investmentProductUI: { isSaveDraftButtonDisabled },
  } = useSelector((state: RootState) => state.investmentProduct);

  const handleSaveDraft =
    async (): Promise<EditInvestmentProductSaveDraftResult> => {
      if (!investmentProductId) return { isSuccess: false };
      if (isSaveDraftButtonDisabled) return { isSuccess: true };
      const formData = getValues("investmentProductModels");
      try {
        const response = await updateInvestmentProductModels(
          investmentProductId,
          formData,
        );
        if (!response) return { isSuccess: false };

        dispatch(updateInvestmentProductModelsData(response));

        return { isSuccess: true, reloadBanner: true };
      } catch {
        return { isSuccess: false };
      }
    };
  useImperativeHandle(ref, () => ({
    getValue() {
      return getValues();
    },
    submit: async () => {},
    resetForm() {
      reset();
    },
    changeTab: async () => await handleSaveDraft(),
    saveDraft: async () => await handleSaveDraft(),
    onPostSubmit: async () => {
      await loadInvestmentProductModels(investmentProductId ?? "new", "edit");
    },
  }));
  /** Side Effect */
  useEffect(() => {
    if (isNewInvestmentProduct) return;
    if (isAdviserTrialGroup) return;
    loadInvestmentProductModels(investmentProductId ?? "new", "edit");
    // eslint-disable-next-line
  }, [investmentProductId]);
  useEffect(() => {
    reset({
      investmentProductModels: investmentProductModelsData,
    });
    // eslint-disable-next-line
  }, [investmentProductModelsData]);

  /** Render */
  const renderFeatureFormFields = (
    feature: InvestmentProductConfigurationModelsFeature,
    featureIndex: number,
    sectionIndex: number,
  ) => {
    // Case first model -> model title
    if (featureIndex === 0 && sectionIndex === 0)
      return (
        <ModelTitleTextField feature={feature} selectedIndex={selectedIndex} />
      );

    switch (feature.fieldType?.id) {
      case FieldTypeId.TextLong: {
        return (
          <FeatureTextEditor
            feature={feature}
            selectedIndex={selectedIndex}
            featureIndex={featureIndex}
            sectionIndex={sectionIndex}
          />
        );
      }
      case FieldTypeId.DateTime: {
        return (
          <FeatureDatePicker
            feature={feature}
            featureIndex={featureIndex}
            sectionIndex={sectionIndex}
            selectedIndex={selectedIndex}
          />
        );
      }
      // Default text short
      default: {
        return (
          <ModelFeatureTextShort
            feature={feature}
            selectedIndex={selectedIndex}
            featureIndex={featureIndex}
            sectionIndex={sectionIndex}
          />
        );
      }
    }
  };
  const renderSection = (
    section: InvestmentProductConfigurationModelSection,
    sectionIndex: number,
  ) => {
    switch (section.displayType?.id) {
      case SectionDisplayTypeId.MainBodyPage: {
        return (
          <SHStack width={"100%"} gap={"25px"} marginBottom={"10px"}>
            {section.features?.map((feature, featureIndex) => {
              return (
                <Fragment key={feature.id}>
                  {renderFeatureFormFields(feature, featureIndex, sectionIndex)}
                </Fragment>
              );
            })}
          </SHStack>
        );
      }
      case SectionDisplayTypeId.Table: {
        return (
          <SHStack width={"100%"} paddingBottom="10px">
            <ModelPageTableEdit
              section={section}
              sectionIndex={sectionIndex}
              selectedIndex={selectedIndex}
            />
          </SHStack>
        );
      }
    }
  };

  if (isModelsDataLoading) return <ModelsTabSkeleton />;
  return (
    <FormProvider {...formMethod}>
      <form>
        <ProductFeaturesLayout
          emptyMessage="Please create a Series first"
          hasData={!isEmpty(investmentProductModelsData)}
          leftSide={
            <EditModelsCollapseMenu
              investmentProductModelsData={investmentProductModelsData}
              dotGroupProps={{
                orangeDot: true,
                blueDot: true,
              }}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              defaultExpandIndexes={[0]}
            />
          }
          rightSide={
            <SHStack
              justifyContent="space-between"
              flexWrap="wrap"
              direction="row"
            >
              {selectedModel?.sections?.map((section, index) => (
                <Fragment key={section.id}>
                  {renderSection(section, index)}
                </Fragment>
              ))}
            </SHStack>
          }
        />
      </form>
    </FormProvider>
  );
});
