import { faker } from "@faker-js/faker";
import { SupplierLovDTO } from "@models/supplier/entities/suppliers";
import { generateUUID } from "@utils";
export const mockSupplierLov: SupplierLovDTO[] = Array.from(
  { length: 10 },
  () => ({
    id: generateUUID(),
    afsl: faker.number.int({ min: 100000, max: 999999 }).toString(),
    firmName: faker.company.name(),
    linkedPractices: [],
    isLicensee: true,
  }),
);
