import { AnalysisToolBarPlacement } from "@components/analysis-nav-bar/portal";
import { SHBox, SHContainer, SHStack } from "@components/design-systems";
import { SHLink } from "@components/design-systems/sh-link";
import { LogoSVG } from "@components/svgs";
import { TopNotification } from "@components/top-notifications";
import {
  TopNotificationGroup,
  TopNotificationItem,
} from "@components/top-notifications/model";
import { PageRoutes } from "@constants/routes";
import { StepperPlacement } from "@layouts/stepper/portal";
import { AppBar, Toolbar, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import {
  removeConfigurationNotification,
  removeGlobalNotification,
  removeProductProfileNotification,
} from "@redux/slices/top-notifications";
import { hexToRGBA } from "@utils";
import { concat } from "lodash";
import { useDispatch, useSelector } from "react-redux";

export const AnalysisNavBars = (): JSX.Element => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const {
    globalNotifications,
    configurationNotifications,
    productProfileNotifications,
  } = useSelector((state: RootState) => state.topNotification);
  const notifications = concat(
    globalNotifications,
    configurationNotifications,
    productProfileNotifications,
  );

  return (
    <AppBar
      position="sticky"
      data-testid="sh-top-navbar"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          borderColor: palette.text.primary,
          borderWidth: "1px 0",
          borderStyle: "solid",
          boxShadow: "none",
          backgroundColor: hexToRGBA(palette.background.default, 0.5),
          backdropFilter: "blur(30px)",
          zIndex: 2,
          minHeight: "80px !important",
        }}
      >
        <SHContainer maxWidth="lg2" sx={{ px: { xs: 0 } }}>
          <SHStack
            spacing={"25px"}
            direction="row"
            justifyContent={"space-between"}
            alignItems={"stretch"}
          >
            <SHBox
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                borderColor: palette.text.primary,
                borderWidth: { xs: "0 1px 0 0", lg: "0 1px" },
                borderStyle: "solid",
                width: { sm: 120, md: 160, lg: 220 },
                height: "80px",
                px: { xs: 1, md: 2, lg: 3 },
              }}
            >
              <SHLink href={PageRoutes.home}>
                <LogoSVG />
              </SHLink>
            </SHBox>

            <SHStack
              flexGrow={1}
              sx={{
                borderColor: palette.text.primary,
                borderWidth: { xs: 0, lg: "0 1px 0 0" },
                borderStyle: "solid",
                pr: "25px",
              }}
            >
              <AnalysisToolBarPlacement />
            </SHStack>
          </SHStack>
        </SHContainer>
      </Toolbar>
      <StepperPlacement />
      {notifications?.map((notification: TopNotificationItem, index) => (
        <TopNotification
          key={notification.id}
          notificationItem={notification}
          onClose={() => {
            switch (notification.group) {
              case TopNotificationGroup.Configuration:
                dispatch(removeConfigurationNotification(notification?.id));
                break;
              case TopNotificationGroup.PlatformProfile:
                dispatch(removeProductProfileNotification(notification?.id));
                break;
              default:
                dispatch(removeGlobalNotification(notification?.id));
                break;
            }
          }}
        />
      ))}
    </AppBar>
  );
};
