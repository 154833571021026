import { SHDivider, SHStack } from "@components/design-systems";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationSection } from "@models/product/investment-product/entities/investmentProduct";
import { RootState, useAppDispatch } from "@redux/store";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import { useMount, useToggle } from "react-use";
import {
  EditInvestmentProductFormRef,
  EditInvestmentProductSaveDraftResult,
} from "../../..";
import { ManagerTabSkeleton } from "../../skeletons/ManagerTabSkeleton";
import {
  EditManagerInputControllerProps,
  getEditManagerFeatureDataPath,
  getEditManagerFeatureProductDataPath,
  getEditManagerGroupDataFieldPath,
  getEditManagerSectionDataPath,
} from "./util";
import { EditManagerSectionTitle } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/section-title-controller";
import { EditManagerDropDownInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/drop-down-input-controller";
import { EditManagerShortTextInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/short-text-input-controller";
import { EditManagerImageField } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/image-field-controller";
import { EditManagerNumberInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/number-input-controller";
import { EditManagerNumberMillionInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/number-million-input-controller";
import { EditManagerTextLongInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/long-text-input-controller";
import { EditManagerYesNoInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/yes-no-input-controller";
import { EditManagerDateTimeInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/date-time-input-controller";
import { useUserPermissions } from "@hooks/userUserPermission";
import { EditManagerGoodScoreInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/good-score-input-controller";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
  InvestmentProductDataTab,
} from "@models/product/investment-product/enums/status";
import { isEmpty, isEqual } from "lodash";
import {
  toggleSaveDraftButtonDisabled,
  updateActiveInvestmentGroup,
  updateEditMode,
} from "@redux/slices/product/investment-product";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { generateUUID } from "@utils";
import { useIsNew } from "@hooks/useIsNew";
import {
  DEFAULT_MANAGER_CODE_ERROR_MESSAGE,
  MAX_MANAGER_CODE_NUM_OF_CHARS,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/constant";
import { useNotification } from "@hooks/useNotification";
import { PageRoutes } from "@constants";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { EditManagerImageGalleryInput } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/image-gallery-input-controller";
import { EditManagerNumberTextInput } from "src/pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/number-text-input-controller";
import { renderEditTabTitle } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/util";
import ManagerViewingGroupSelection from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/components/viewing-group-controller";

interface EditManagerTabProps {}

export const EditManagerTab = forwardRef<
  EditInvestmentProductFormRef,
  EditManagerTabProps
>((props: EditManagerTabProps, ref) => {
  const {
    loadInvestmentProductManager,
    createNewInvestmentProductAsync,
    updateExistingInvestmentProductManagerAsync,
  } = useInvestmentProduct();
  const {
    investmentProductUI: { isManagerDataLoading },
    investmentProductManagerData,
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const { supplierData } = useSelector((state: RootState) => state.supplier);
  const { investmentProductId, supplierId } = useParams();
  const { isAdminGroup } = useUserPermissions();
  const dispatch = useAppDispatch();
  const isNewInvestmentProduct = useIsNew();
  const { notify } = useNotification();
  const navigate = useNavigate();
  const isCreatingInvestmentProduct = useIsNew();

  const formMethod = useForm({
    mode: "onChange",
    defaultValues: investmentProductManagerData,
  });

  useImperativeHandle(ref, () => ({
    getValue() {
      return formMethod.getValues();
    },
    submit: async () => {
      await handleOnSubmit();
    },
    resetForm() {
      resetFormData({});
    },
    saveDraft: async () => {
      return await handleOnSaveDraft({});
    },
    changeTab: async () => {
      return await handleOnSaveDraft({ isChangeTab: true });
    },
    onPostSubmit: async () => {
      await loadInvestmentProductManagerData(investmentProductId);
    },
  }));

  const [managerCode, setManagerCode] = useState<string | undefined>();
  const [elementKey, setElementKey] = useState<string>(generateUUID());
  const [isRenderingForm, toggleRenderingForm] = useToggle(false);
  const [isFormModified, toggleFormModified] = useToggle(false);
  const [isSubmittingForm, toggleSubmittingForm] = useToggle(false);

  const managerGroupData = useMemo(
    () => investmentProductManagerData?.group,
    [investmentProductManagerData],
  );

  const isDisabledSaveDraftButton = useMemo(() => {
    const formErrors = formMethod.formState.errors.group;
    return [
      isRenderingForm,
      !isFormModified,
      !isEmpty(formErrors),
      isSubmittingForm,
    ].some(Boolean);
  }, [
    isFormModified,
    isRenderingForm,
    formMethod.formState.errors.group,
    isSubmittingForm,
  ]);

  const isDisableAllFields = useMemo(() => {
    const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
      isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
    );

    return isSubmittingForm || isPendingApproval;
  }, [investmentProductBannerInfo, isSubmittingForm]);

  useEffect(() => {
    dispatch(toggleSaveDraftButtonDisabled(isDisabledSaveDraftButton));
    // eslint-disable-next-line
  }, [isDisabledSaveDraftButton]);

  useMount(async () => {
    await loadInvestmentProductManagerData(investmentProductId, supplierId);
  });

  const loadInvestmentProductManagerData = async (
    id?: string,
    supplierId?: string,
  ) => {
    toggleRenderingForm(true);
    const data = await loadInvestmentProductManager(
      id || "new",
      "edit",
      supplierId,
    );
    resetFormData({ formData: data });
    toggleRenderingForm(false);
  };

  const resetFormData = ({
    formData,
    updateDots,
  }: {
    formData?: InvestmentProductManagerDTO;
    updateDots?: boolean;
  }) => {
    formMethod.reset(formData);
    setElementKey(generateUUID());
    checkFormIsModified();

    updateDots && updateFormDataDots();
  };

  const handleOnSubmit = async () => {};

  const handleOnSaveDraft = async ({
    isChangeTab,
  }: {
    isChangeTab?: boolean;
  }): Promise<EditInvestmentProductSaveDraftResult> => {
    toggleSubmittingForm(true);

    try {
      if (!isNewInvestmentProduct && !isFormModified) {
        return { isSuccess: true };
      }

      const response = isNewInvestmentProduct
        ? await createNewInvestmentProduct({ isChangeTab })
        : await updateInvestmentProduct({ isChangeTab });

      return { isSuccess: response, reloadBanner: response };
    } finally {
      toggleSubmittingForm(false);
    }
  };

  const createNewInvestmentProduct = async ({
    isChangeTab,
  }: {
    isChangeTab?: boolean;
  }): Promise<boolean> => {
    const formData = formMethod.getValues();

    const validManagerCode = [
      !!managerCode,
      isEqual(String(managerCode).length, MAX_MANAGER_CODE_NUM_OF_CHARS),
    ].every(Boolean);

    if (!validManagerCode) {
      notify(DEFAULT_MANAGER_CODE_ERROR_MESSAGE, {
        variant: "error",
        close: true,
      });

      return false;
    }

    if (!supplierData) return false;

    formData.supplierId = supplierData.id;
    const responseData = await createNewInvestmentProductAsync(formData);

    if (!responseData) return false;

    navigate(
      generatePath(PageRoutes.investmentProductsDetail, {
        supplierId: supplierData.id,
        investmentProductId: responseData?.id ?? "",
      }),
      {
        replace: true,
      },
    );

    dispatch(updateEditMode(true));

    if (isChangeTab) {
      loadInvestmentProductManagerData(responseData.id);
    } else {
      await loadInvestmentProductManagerData(responseData.id);
    }

    return true;
  };

  const updateInvestmentProduct = async ({
    isChangeTab,
  }: {
    isChangeTab?: boolean;
  }): Promise<boolean> => {
    if (!investmentProductId) return false;

    const formData = formMethod.getValues();

    const updateResponse = await updateExistingInvestmentProductManagerAsync(
      investmentProductId,
      formData,
    );

    if (!updateResponse) return false;
    if (isChangeTab) return true;

    resetFormData({ formData: updateResponse, updateDots: true });

    return true;
  };

  const handleOnEditManagerFieldChange = ({
    sectionIndex,
    featureIndex,
  }: {
    sectionIndex: number;
    featureIndex: number;
  }) => {
    updateFeatureDataDots(sectionIndex, featureIndex);
    updateSectionDataDots(sectionIndex);
    updateGroupDataDots();
    checkFormIsModified();
  };

  const handleOnEditManagerCodeFieldChange = (value?: string) => {
    setManagerCode(value);
  };

  const checkFormIsModified = () => {
    const isModified = !!formMethod.getValues().group?.isModified;
    toggleFormModified(isModified);
  };

  const updateFormDataDots = () => {
    if (!investmentProductManagerData) return;

    managerGroupData?.sections?.forEach((_, sectionIndex) =>
      updateSectionDataDots(sectionIndex),
    );
    updateGroupDataDots();
  };

  const updateFeatureDataDots = (
    sectionIndex: number,
    featureIndex: number,
  ) => {
    const featureDataPath = getEditManagerFeatureDataPath(
      sectionIndex,
      featureIndex,
    );
    const productDataPath = getEditManagerFeatureProductDataPath(
      sectionIndex,
      featureIndex,
    );

    formMethod.setValue(
      featureDataPath.isModified,
      formMethod.getValues(productDataPath.isModified),
    );
    formMethod.setValue(
      featureDataPath.highlightType,
      formMethod.getValues(productDataPath.highlightType),
    );
    formMethod.setValue(
      featureDataPath.dataStatus,
      formMethod.getValues(productDataPath.dataStatus),
    );
  };

  const updateSectionDataDots = (sectionIndex: number) => {
    let highlightType: HighlightType = HighlightType.Added;
    let dataStatus: InvestmentDataStatus = InvestmentDataStatus.Filled;
    let isModified: boolean = false;
    managerGroupData?.sections
      ?.at(sectionIndex)
      ?.features?.forEach((feature, featureIndex) => {
        const ignoreDataStatus = isEqual(
          feature.fieldType?.id,
          FieldTypeId.Slideshow,
        );
        const dataPath = getEditManagerFeatureProductDataPath(
          sectionIndex,
          featureIndex,
        );

        const featureDataHighLightType = formMethod.getValues(
          dataPath.highlightType,
        );
        const featureDataStatus = ignoreDataStatus
          ? InvestmentDataStatus.Filled
          : formMethod.getValues(dataPath.dataStatus);
        const isDataModified = !!formMethod.getValues(dataPath.isModified);

        if (isEqual(featureDataHighLightType, HighlightType.Edited)) {
          highlightType = HighlightType.Edited;
        }

        if (
          !featureDataStatus ||
          isEqual(featureDataStatus, InvestmentDataStatus.MissingData)
        ) {
          dataStatus = InvestmentDataStatus.MissingData;
        }

        if (isDataModified) isModified = true;
      });

    const sectionDataPath = getEditManagerSectionDataPath(sectionIndex);
    formMethod.setValue(sectionDataPath.highlightType, highlightType);
    formMethod.setValue(sectionDataPath.dataStatus, dataStatus);
    formMethod.setValue(sectionDataPath.isModified, isModified);
  };

  const updateGroupDataDots = () => {
    let highlightType: HighlightType = HighlightType.Added;
    let dataStatus: InvestmentDataStatus = InvestmentDataStatus.Filled;
    let isModified: boolean = false;

    managerGroupData?.sections?.forEach((_, sectionIndex) => {
      const dataPath = getEditManagerSectionDataPath(sectionIndex);

      const sectionHighlightType = formMethod.getValues(dataPath.highlightType);
      const sectionDataStatus = formMethod.getValues(dataPath.dataStatus);
      const isDataModified = !!formMethod.getValues(dataPath.isModified);

      if (isEqual(sectionHighlightType, HighlightType.Edited)) {
        highlightType = HighlightType.Edited;
      }

      if (
        !sectionDataStatus ||
        isEqual(sectionDataStatus, InvestmentDataStatus.MissingData)
      ) {
        dataStatus = InvestmentDataStatus.MissingData;
      }

      if (isDataModified) isModified = true;
    });

    const groupDataPath = getEditManagerGroupDataFieldPath();
    formMethod.setValue(groupDataPath.highlightType, highlightType);
    formMethod.setValue(groupDataPath.dataStatus, dataStatus);
    formMethod.setValue(groupDataPath.isModified, isModified);

    dispatch(
      updateActiveInvestmentGroup({
        dataStatus: dataStatus,
        highlightType: highlightType,
        isModified: isModified,
      } as InvestmentConfigurationGroup),
    );
  };

  const renderSectionData = ({
    sectionData,
    sectionIndex,
  }: {
    sectionData: InvestmentProductConfigurationSection;
    sectionIndex: number;
  }) => {
    return (
      <SHStack key={sectionData.id}>
        <SHDivider />
        <SHStack
          spacing={3}
          sx={{ paddingY: 3, paddingTop: 3 }}
          alignItems={"stretch"}
          justifyContent={"space-between"}
          direction={{ xs: "column", md: "row" }}
        >
          <EditManagerSectionTitle
            title={sectionData.name}
            sectionIndex={sectionIndex}
          />
          <SHStack direction="column" alignItems={"flex-end"} gap={3}>
            {sectionData.features?.map((featureData, featureIndex) => (
              <SHStack
                key={featureData.id}
                direction={"column"}
                gap={1}
                alignItems={"flex-end"}
              >
                {renderFeatureData({
                  featureData,
                  featureIndex,
                  sectionIndex,
                })}

                <SHStack
                  sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
                  alignItems={"flex-start"}
                >
                  {renderGoodScoreData({
                    featureData,
                    featureIndex,
                    sectionIndex,
                  })}
                </SHStack>
              </SHStack>
            ))}
          </SHStack>
        </SHStack>
      </SHStack>
    );
  };

  const renderFeatureData = ({
    featureData,
    featureIndex,
    sectionIndex,
  }: EditManagerInputControllerProps) => {
    const defaultInputProps = {
      isCreatingInvestmentProduct: isCreatingInvestmentProduct,
      featureData: featureData,
      featureIndex: featureIndex,
      sectionIndex: sectionIndex,
      isDisabled: isDisableAllFields,
      onChange: handleOnEditManagerFieldChange,
    };

    switch (featureData.fieldType?.id) {
      case FieldTypeId.TextShort:
        const isManagerCodeField = isEqual(featureData.id, "ManagerCode");
        const isManagerNameField = isEqual(featureData.name, "Manager");

        return (
          <EditManagerShortTextInput
            {...defaultInputProps}
            isManagerCodeField={isManagerCodeField}
            isManagerNameField={isManagerNameField}
            onChange={({ featureIndex, sectionIndex, newValue }) => {
              handleOnEditManagerFieldChange({ featureIndex, sectionIndex });
              isManagerCodeField &&
                handleOnEditManagerCodeFieldChange(newValue);
            }}
          />
        );
      case FieldTypeId.TextLong:
        return <EditManagerTextLongInput {...defaultInputProps} />;
      case FieldTypeId.Number:
        return <EditManagerNumberInput {...defaultInputProps} />;
      case FieldTypeId.NumberMillion:
        return <EditManagerNumberMillionInput {...defaultInputProps} />;
      case FieldTypeId.YesNo:
        return <EditManagerYesNoInput {...defaultInputProps} />;
      case FieldTypeId.Image:
        return <EditManagerImageField {...defaultInputProps} />;
      case FieldTypeId.Slideshow:
        return <EditManagerImageGalleryInput {...defaultInputProps} />;
      case FieldTypeId.DateTime:
        return <EditManagerDateTimeInput {...defaultInputProps} />;
      case FieldTypeId.PercentageText:
        return (
          <EditManagerNumberTextInput
            {...defaultInputProps}
            suffix={"%"}
            placeholder={"Please enter Percentage"}
          />
        );
      case FieldTypeId.NumberText:
        return (
          <EditManagerNumberTextInput
            {...defaultInputProps}
            placeholder={"Please enter Number"}
          />
        );
      case FieldTypeId.Percentage:
        return <EditManagerNumberInput {...defaultInputProps} suffix={"%"} />;
      case FieldTypeId.Currency:
        return <EditManagerNumberInput {...defaultInputProps} prefix={"$"} />;
      case FieldTypeId.CurrencyText:
        return (
          <EditManagerNumberTextInput {...defaultInputProps} prefix={"$"} />
        );
      case FieldTypeId.Dropdown:
        const isProductTypeDropdown = isEqual(
          featureData.name,
          "Investment type",
        );
        return (
          <EditManagerDropDownInput
            {...defaultInputProps}
            isProductTypeDropdown={isProductTypeDropdown}
          />
        );
      default:
        return <></>;
    }
  };

  const renderGoodScoreData = ({
    featureData,
    featureIndex,
    sectionIndex,
  }: EditManagerInputControllerProps) => {
    const isNullGoodScore = ![
      isAdminGroup,
      featureData.isBusinessMetric,
      featureData.isPositiveScore,
    ].every(Boolean);

    if (isNullGoodScore) return <></>;

    return (
      <EditManagerGoodScoreInput
        featureData={featureData}
        featureIndex={featureIndex}
        sectionIndex={sectionIndex}
        onChange={handleOnEditManagerFieldChange}
      />
    );
  };

  if (isManagerDataLoading || isRenderingForm) return <ManagerTabSkeleton />;

  return (
    <FormProvider key={elementKey} {...formMethod}>
      <SHStack direction="column" mt={3}>
        <SHStack
          direction={{ sx: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={{
            sx: "flex-start",
            md: "center",
          }}
        >
          {renderEditTabTitle(InvestmentProductDataTab.Manager)}
          <ManagerViewingGroupSelection
            disabled={isDisableAllFields}
            onChange={() => {
              checkFormIsModified();
            }}
            viewingGroupData={managerGroupData?.viewingGroupSelections ?? null}
          />
        </SHStack>

        {managerGroupData?.sections?.map((sectionData, sectionIndex) =>
          renderSectionData({ sectionData, sectionIndex }),
        )}
      </SHStack>
    </FormProvider>
  );
});
