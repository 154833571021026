import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { useMemo, useState } from "react";
import { InformationButton } from "@components/buttons/information";
import { debounce, isEqual, isNaN } from "lodash";
import { format, parse } from "date-fns";
import { DateFormat } from "@constants/format";
import { SHLabel, SHStack, SHTypography } from "@components/design-systems";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { FieldTypeId } from "@models/configuration";
import { useTheme } from "@mui/material";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";

export const EditManagerDateTimeInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { palette } = useTheme();
  const { control, getValues, setValue, setError, clearErrors } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const [fieldValue, setFieldValue] = useState<string | null>(
    featureData.productData?.value ?? "",
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleOnChange = (newValue: string | null) => {
    const formValue = toStringValue(newValue, FieldTypeId.DateTime);

    const fieldValuePath = featureProductDataPath.value;

    if (isEqual(newValue, EditProductDefaults.InvalidDate)) {
      setError(fieldValuePath, {
        message: EditProductDefaults.InvalidDateFormatMessage,
      });
      setErrorMessage(EditProductDefaults.InvalidDateFormatMessage);
    } else {
      clearErrors(fieldValuePath);
      setErrorMessage(undefined);
    }

    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue: formValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex, newValue: formValue });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack sx={{ width: { xs: "100%", md: 520 } }} direction={"row"} gap={2}>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { ref, ...other } }) => {
          const date: Date | null = !!other.value
            ? parse(String(other.value), DateFormat, new Date())
            : null;

          return (
            <SHStack direction="column" sx={{ flex: 1 }}>
              <SHDatePicker
                textFieldProps={{
                  error: isEqual(fieldValue, EditProductDefaults.InvalidDate),
                }}
                openOnClick
                label={
                  <SHLabel
                    label={featureData?.name}
                    postfixLabel={postfixLabel}
                    disabled={isDisabled}
                    dotGroupProps={{
                      blueDot: isShowBlueDot,
                      orangeDot: isShowOrangeDot,
                      greenDot: !!featureData?.isBusinessMetric,
                    }}
                  />
                }
                disabled={isDisabled}
                minDate={EditProductDefaults.MinDateTime}
                maxDate={EditProductDefaults.MaxDateTime}
                value={date}
                inputFormat={DateFormat}
                views={["year", "month", "day"]}
                onChange={(newValue: Date | null) => {
                  const newValueString = toStringValue(
                    newValue,
                    FieldTypeId.DateTime,
                  );
                  setFieldValue(newValueString);

                  const isValidDate = !isNaN(Date.parse(newValueString));
                  const newDateValue = isValidDate
                    ? format(new Date(newValueString), DateFormat)
                    : newValueString;

                  other.onChange(newDateValue);
                  debouncedHandleInputChange(newDateValue);
                }}
              />

              {errorMessage && (
                <SHTypography variant={"caption"} color={palette.error.main}>
                  {errorMessage}
                </SHTypography>
              )}
            </SHStack>
          );
        }}
      />
      {featureData.allowedVisibilitySetting && (
        <Controller
          name={featureDataPath.viewingGroupSelections}
          control={control}
          render={({ field: { ref, ...other } }) => (
            <SelectViewingGroups
              disabled={isDisabled}
              textFieldProps={{
                label: `Visible to`,
              }}
              sx={{
                flexBasis: "40%",
              }}
              onChange={(value) => {
                other.onChange(value);
                debounceVisibilityChange(value);
              }}
              value={
                (getValues(featureDataPath.viewingGroupSelections) as
                  | ViewingGroupSelectionDTO[]
                  | null) || []
              }
              viewingGroupData={featureData.viewingGroupSelections ?? []}
            />
          )}
        />
      )}
    </SHStack>
  );
};
