import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useEditProduct } from "@hooks/useEditProduct";
import { useEditInvestmentProduct } from "@hooks/useEditInvestmentProduct";
import { useNotification } from "@hooks/useNotification";
import { HubConnectionState } from "@microsoft/signalr";
import { DefaultSocketErrorMessage } from "@models/core/socket";
import { RootState } from "@redux/store";
import {
  updateEditMode as updatePlatformEditMode,
  updateLoadingEditMode as updatePlatformLoadingEditMode,
  updateShowKickoutDialog as updatePlatformShowKickoutDialog,
} from "@redux/slices/product";
import {
  updateEditMode as updateInvestmentProductEditMode,
  updateLoadingEditMode as updateInvestmentProductLoadingEditMode,
  updateShowKickoutDialog as updateInvestmentProductShowKickoutDialog,
} from "@redux/slices/product/investment-product";
import { SignalRService } from "@services/signalr/signalRService";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { ProductType } from "@models/product/enums/productType";

const ACTION_TYPES = {
  Platform: {
    updateEditMode: updatePlatformEditMode.type,
    updateLoadingEditMode: updatePlatformLoadingEditMode.type,
    updateShowKickoutDialog: updatePlatformShowKickoutDialog.type,
  },
  SMA: {
    updateEditMode: updateInvestmentProductEditMode.type,
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowKickoutDialog: updateInvestmentProductShowKickoutDialog.type,
  },
  MDA: {
    updateEditMode: updateInvestmentProductEditMode.type,
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowKickoutDialog: updateInvestmentProductShowKickoutDialog.type,
  },
} as const;

interface ProductStateActions {
  updateEditMode: (payload: boolean) => void;
  updateLoadingEditMode: (payload: boolean) => void;
  updateShowKickoutDialog: (payload: boolean) => void;
}

interface ProductConfig {
  getShowKickoutDialog: (state: RootState) => boolean | undefined;
  getCurrentEditor: (state: RootState) => string | undefined;
  actionTypes: {
    updateEditMode: string;
    updateLoadingEditMode: string;
    updateShowKickoutDialog: string;
  };
  actionCreators: {
    updateEditMode: typeof updatePlatformEditMode | typeof updateInvestmentProductEditMode;
    updateLoadingEditMode: typeof updatePlatformLoadingEditMode | typeof updateInvestmentProductLoadingEditMode;
    updateShowKickoutDialog: typeof updatePlatformShowKickoutDialog | typeof updateInvestmentProductShowKickoutDialog;
  };
  getNavigationPath: (supplierId: string, productId: string) => string;
}

const PRODUCT_CONFIG: Record<ProductType, ProductConfig> = {
  [ProductType.Platform]: {
    getShowKickoutDialog: (state: RootState)=> state.product.productUI?.showKickoutDialog,
    getCurrentEditor: (state: RootState)=> state.product.productUI?.currentEditor,
    actionTypes: ACTION_TYPES.Platform,
    actionCreators: {
      updateEditMode: updatePlatformEditMode,
      updateLoadingEditMode: updatePlatformLoadingEditMode,
      updateShowKickoutDialog: updatePlatformShowKickoutDialog,
    },
    getNavigationPath: (supplierId: string, productId: string) =>
      generatePath(PageRoutes.supplierProducts, { supplierId, productId }),
  },
  [ProductType.SMA]: {
    getShowKickoutDialog: (state: RootState)=> state.investmentProduct.investmentProductUI?.showKickoutDialog,
    getCurrentEditor: (state: RootState)=> state.investmentProduct.investmentProductUI?.currentEditor,
    actionTypes: ACTION_TYPES.SMA,
    actionCreators: {
      updateEditMode: updateInvestmentProductEditMode,
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowKickoutDialog: updateInvestmentProductShowKickoutDialog,
    },
    getNavigationPath: (supplierId: string, investmentProductId: string) =>
      generatePath(PageRoutes.investmentProductsDetail, { supplierId, investmentProductId }),
  },
  [ProductType.MDA]: {
    getShowKickoutDialog: (state: RootState)=> state.investmentProduct.investmentProductUI?.showKickoutDialog,
    getCurrentEditor: (state: RootState)=> state.investmentProduct.investmentProductUI?.currentEditor,
    actionTypes: ACTION_TYPES.MDA,
    actionCreators: {
      updateEditMode: updateInvestmentProductEditMode,
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowKickoutDialog: updateInvestmentProductShowKickoutDialog,
    },
    getNavigationPath: (supplierId: string, investmentProductId: string) =>
      generatePath(PageRoutes.investmentProductsDetail, { supplierId, investmentProductId }),
  },
};

const PRODUCT_CLAIMING_METHODS = (
  handlePlatformOnClaimingEditingPermission: (productId: string, callback?: () => void) => Promise<void>,
  handleInvestmentProductOnClaimingEditingPermission: (productId: string, callback?: () => void) => Promise<void>
) => ({
  [ProductType.Platform]: {
    claimMethod: handlePlatformOnClaimingEditingPermission,
  },
  [ProductType.SMA]: {
    claimMethod: handleInvestmentProductOnClaimingEditingPermission,
  },
  [ProductType.MDA]: {
    claimMethod: handleInvestmentProductOnClaimingEditingPermission,
  },
});

export interface ConfirmKickOutDialogProps {
  supplierId?: string;
  productId?: string;
  productType?: ProductType;
}

export const ConfirmKickOutDialog = ({
  supplierId,
  productId,
  productType,
}: ConfirmKickOutDialogProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { handleOnClaimingEditingPermission: handlePlatformOnClaimingEditingPermission } = useEditProduct();
  const { handleOnClaimingEditingPermission: handleInvestmentProductOnClaimingEditingPermission } = useEditInvestmentProduct();
  const navigate = useNavigate();
  const { notify } = useNotification();
  productType ??= ProductType.Platform;

  const productClaimingMethods = useMemo(() =>
      PRODUCT_CLAIMING_METHODS(
        handlePlatformOnClaimingEditingPermission,
        handleInvestmentProductOnClaimingEditingPermission
      ),
    [handlePlatformOnClaimingEditingPermission, handleInvestmentProductOnClaimingEditingPermission]
  );

  const productConfig = PRODUCT_CONFIG[productType];

  const productActions: ProductStateActions = {
    updateEditMode: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateEditMode, payload: payload }),
    updateLoadingEditMode: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateLoadingEditMode, payload: payload }),
    updateShowKickoutDialog: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateShowKickoutDialog, payload: payload }),
};

  const showKickoutDialog = useSelector(productConfig.getShowKickoutDialog);

  const currentEditor = useSelector(productConfig.getCurrentEditor);

  const handleOnClickYes = async () => {
    setIsLoading(true);
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      const productClaimingMethod = productClaimingMethods[productType as ProductType];
      await productClaimingMethod.claimMethod(productId as string, () => {
        productActions.updateEditMode(true);
        navigate(
          productConfig.getNavigationPath(supplierId as string, productId as string)
        );
      });
      setIsLoading(false);
      productActions.updateShowKickoutDialog(false);
    } else {
      ///else show error here
      setIsLoading(false);
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnClose = () => {
    productActions.updateShowKickoutDialog(false);
    productActions.updateLoadingEditMode(false);
    productActions.updateEditMode(false);
    setIsLoading(false);
  };

  return (
    <>
      <SHDialog
        open={showKickoutDialog || false}
        onClose={handleOnClose}
        width={600}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              WARNING: Another user is editing the Profile
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {currentEditor} is currently editing the Profile. We only allow
              one user to edit the Profile at one time. If you continue, you
              will kick them out of edit mode, and their unsaved changes will be
              discarded.
            </SHTypography>
            <br />
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Proceed with kicking out {currentEditor}?
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="text"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Cancel
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleOnClickYes}
              size="extraMedium"
            >
              Yes, kick them out
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
