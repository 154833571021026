import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface ArchiveConfirmDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
}

export const ArchiveConfirmDialog = ({
  open,
  isLoading,
  onClose,
  onOk,
}: ArchiveConfirmDialogProps) => {
  return (
    <SHDialog
      open={open}
      onClose={onClose}
      width={600}
      header={"Archive viewing group?"}
      body={"You are about to archive the viewing group. Are you sure?"}
      footer={
        <SHStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            color="secondary"
            onClick={onClose}
            variant="text"
            size="extraMedium"
          >
            Cancel
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onOk}
          >
            Yes, archive viewing group
          </SHButton>
        </SHStack>
      }
    />
  );
};
