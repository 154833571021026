import {
  SHCheckbox,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import {
  availabilitySubHeaderCellBorderIds,
  occupiedSpace,
} from "@pages/managed-accounts/sma-list/config";
import { LinearProgress, useTheme } from "@mui/material";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CalculatedAvailabilityInvestmentDto } from "@pages/managed-accounts/sma-list/components/tables/model";
import { SMAFavouritedButton } from "@pages/managed-accounts/sma-list/components/buttons/favourited";
import { AvailabilitySubHeader } from "@models/managed-accounts/enums/subHeader";
import {
  SMAInvestmentCell,
  SMAModelCell,
  SMAPlatformCell,
} from "@pages/managed-accounts/sma-list/components/tables/components/sma-cell";
import {
  modelSortingFn,
  platformCodeSortingFn,
  platformSortingFn,
} from "@pages/managed-accounts/sma-list/util";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { isEmpty, isNaN } from "lodash";
import { hexToRGBA } from "@utils";
import { SMAHeadCell } from "@pages/managed-accounts/sma-list/components/tables/components/head-cell";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  putInvestmentFavourited,
  updateSmaList,
} from "@services/investment/investmentService";
import { useNotification } from "@hooks/useNotification";
import "./index.scss";
import { AvailabilityMainHeaderColumn } from "@models/managed-accounts/enums/mainHeader";
import { useParams } from "react-router";
import { TableContext } from "./AvailabilityPageLayout";
import { compareAsc, parse } from "date-fns";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { useAppDispatch } from "@redux/store";
import { toggleAccessDeniedDialog } from "@redux/slices/product/investment-product";

const SmaAvailabilityTable = () => {
  const {
    setOriginalTableData,
    currentTableData,
    setCurrentTableData,
    selectedTableRowIds,
    setSelectedTableRowIds,
    isFetchingData,
    setIsFetchingData,
    tableFilter,
    displayFeeType,
  } = useContext(TableContext);
  const { palette } = useTheme();
  const { notify } = useNotification();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { investmentProductId } = useParams<{ investmentProductId: string }>();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [favoriteShCodeStatus, setFavoriteShCodeStatus] = useState({
    shCode: "",
    isFavorite: false,
  });
  const dispatch = useAppDispatch();

  const columnHelper =
    createColumnHelper<CalculatedAvailabilityInvestmentDto>();
  const customSortingTable = (rowA: any, rowB: any, columnId: any) => {
    const investmentValueA = rowA.original[columnId].value;
    const investmentValueB = rowB.original[columnId].value;
    const fieldType = rowA.original[columnId].fieldTypeId;
    const isDesc = table.getState().sorting[0].desc;

    if (!isDesc) {
      if (investmentValueA === "No data") {
        return 1;
      }

      if (investmentValueB === "No data") {
        return -1;
      }
    } else {
      if (investmentValueB === "No data") {
        return 1;
      }

      if (investmentValueA === "No data") {
        return -1;
      }
    }

    switch (fieldType) {
      case ManagedAccountFieldType.Percentage:
      case ManagedAccountFieldType.Currency:
        if (investmentValueA === investmentValueB) {
          return 0;
        }

        if (!isNaN(+investmentValueA) && !isNaN(+investmentValueB)) {
          return +investmentValueA - +investmentValueB;
        }

        return investmentValueA.localeCompare(investmentValueB);
      case ManagedAccountFieldType.DateTime:
        const dateA = parse(investmentValueA, "dd/MM/yy", new Date());
        const dateB = parse(investmentValueB, "dd/MM/yy", new Date());

        return compareAsc(dateA, dateB);
      default:
        return 0;
    }
  };

  const columns = useMemo<
    ColumnDef<CalculatedAvailabilityInvestmentDto>[]
  >(() => {
    return [
      columnHelper.group({
        id: "SelectCol",
        header: (info) => (
          <SHTypography
            variant="body1"
            colorVariant="third"
            lineHeight={1.2}
            fontSize={11}
          >
            Showing {info.table.getRowModel().rows.length}
          </SHTypography>
        ),
        columns: [
          columnHelper.accessor((row) => row, {
            id: "Select",
            header: "Select",
            size: 100,
            cell: ({ row }) => {
              const { id, isFavourite, shCode } = row.original;
              const onFavoriteChange = (isFavourite: boolean) => {
                setFavoriteShCodeStatus({
                  shCode: shCode,
                  isFavorite: isFavourite,
                });
              };

              return (
                <SHStack
                  flexDirection={"row"}
                  alignItems={"center"}
                  marginLeft={"-10px"}
                >
                  <SHCheckbox
                    checked={selectedTableRowIds.includes(id)}
                    onChange={() => {
                      setSelectedTableRowIds((ids) =>
                        ids.includes(id)
                          ? ids.filter((item) => item !== id)
                          : [...selectedTableRowIds, id],
                      );
                    }}
                  />
                  <SMAFavouritedButton
                    isFavourite={isFavourite}
                    onHandleFavoriteStatus={onFavoriteChange}
                  />
                </SHStack>
              );
            },
          }),
        ],
      }),
      columnHelper.group({
        header: () => <SHStack margin="1px 0 0 -10px">Model details</SHStack>,
        id: AvailabilityMainHeaderColumn.ModelDetails,
        columns: [
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.Model,
            header: () => <SHStack marginLeft="-10px">Model</SHStack>,
            size: 250,
            cell: ({ row }) => {
              const { managerName, name, shCode } = row.original;
              return (
                <SHStack marginLeft="-10px">
                  <SMAModelCell
                    name={name}
                    code={shCode}
                    managerName={managerName}
                  />
                </SHStack>
              );
            },
            sortingFn: modelSortingFn,
          }),
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.Platform,
            header: "Platform",
            size: 160,
            cell: ({ row }) => {
              const { productLogoUrl, productName, subProductName } =
                row.original;

              return (
                <SMAPlatformCell
                  productName={productName}
                  productLogoUrl={productLogoUrl}
                  subProductName={subProductName}
                />
              );
            },
            sortingFn: platformSortingFn,
          }),
        ],
      }),
      columnHelper.group({
        header: "Fees",
        id: AvailabilityMainHeaderColumn.Fees,
        columns: [
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.PDS,
            header: "PDS",
            size: 85,
            cell: ({ row }) => (
              <SMAInvestmentCell investmentData={row.original.column0} />
            ),
            sortingFn: customSortingTable,
          }),
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.AnnualTotal,
            header: "Annual total fee",
            size: 120,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column1}
                feesDisplayStyle={displayFeeType}
                variant="subtitle1"
              />
            ),
            sortingFn: customSortingTable,
          }),
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.IMFee,
            header: "IM fee",
            size: 77,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column2}
                feesDisplayStyle={displayFeeType}
              />
            ),
            sortingFn: customSortingTable,
          }),
        ],
      }),
      columnHelper.group({
        header: "Details",
        id: AvailabilityMainHeaderColumn.Details,
        columns: [
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.MinInvestment,
            header: "Minimum investment",
            size: 110,
            sortingFn: customSortingTable,
            cell: ({ row }) => (
              <SMAInvestmentCell
                investmentData={row.original.column3}
                feesDisplayStyle={FeesDisplayStyle.Dollar}
              />
            ),
          }),
          columnHelper.accessor((row) => row, {
            id: AvailabilitySubHeader.PlatformCode,
            header: "Platform Code",
            size: 85,
            sortingFn: platformCodeSortingFn,
            cell: ({ row }) => (
              <SHTypography variant="body2">
                {row.original.platformCode}
              </SHTypography>
            ),
          }),
        ],
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayFeeType, selectedTableRowIds]);
  const table = useReactTable({
    data: currentTableData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });
  const { rows } = table.getRowModel();
  const HEIGHT_ROW = 45;
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: React.useCallback(() => HEIGHT_ROW, []),
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 1,
  });
  const virtualRows = rowVirtualizer.getVirtualItems();

  const getSmaAvailabilityListData = async () => {
    setIsFetchingData(true);
    tableFilter.investmentProductId = investmentProductId;

    const { data, isSuccess, message, isForbidden } = await updateSmaList(
      tableFilter,
    );

    if (isForbidden) {
      dispatch(toggleAccessDeniedDialog(true));
      return;
    }

    setIsFetchingData(false);

    if (!isSuccess || !data) {
      notify(message, {
        variant: "error",
        close: true,
      });

      return;
    }

    setOriginalTableData(data?.calculatedAvailabilityInvestments ?? []);
  };

  const renderTableHeader = () => {
    return (
      <SHTableHead
        className="SMAAvailabilityListTableHead"
        sx={{
          display: "grid",
          position: "sticky",
          top: 0,
          zIndex: 1,
          paddingTop: "10px important",
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => {
          const isMainHeader = headerGroup.depth === 0;
          return (
            <SHTableRow
              key={headerGroup.id}
              sx={{
                width: "100%",
                display: "flex",
                borderBottomWidth: isMainHeader ? "0px !important" : undefined,
                "&:first-of-type": {
                  backgroundColor: isMainHeader
                    ? hexToRGBA(palette.secondary[100], 0.2)
                    : undefined,
                },
              }}
            >
              {headerGroup.headers.map((header, index) => {
                return (
                  <SMAHeadCell
                    key={header.id}
                    header={header}
                    isMainHeader={isMainHeader}
                    isLastedColumn={false}
                    cellIndex={index}
                    isAvailability={true}
                  />
                );
              })}
            </SHTableRow>
          );
        })}
      </SHTableHead>
    );
  };
  const renderTableBody = () => {
    if (isFetchingData) {
      return (
        <SHTableRow
          style={{
            height: "13px",
            border: "none",
            background: "transparent",
          }}
        >
          <LinearProgress style={{ height: "2px" }} />
        </SHTableRow>
      );
    }

    return isEmpty(currentTableData) ? (
      <SHStack width={"100%"} alignItems="center" justifyContent={"center"}>
        <SHTypography variant="body4">
          No results match your criteria.
        </SHTypography>
      </SHStack>
    ) : (
      <SHTableBody
        className={"SMAAvailabilityListTableBody"}
        style={{
          display: "grid",
          height: !isFetchingData
            ? `${rowVirtualizer.getTotalSize()}px`
            : "auto",
          position: "relative",
          borderBottomWidth: "1px",
        }}
      >
        {virtualRows.map((virtualRow) => {
          const row = rows[
            virtualRow.index
          ] as Row<CalculatedAvailabilityInvestmentDto>;
          return (
            <SHTableRow
              data-index={virtualRow.index}
              ref={(node: any) => rowVirtualizer.measureElement(node)}
              key={row.id}
              style={{
                display: "flex",
                position: "absolute",
                transform: `translateY(${virtualRow.start}px)`,
                width: "100%",
                height: HEIGHT_ROW,
                backgroundColor:
                  virtualRow.index % 2 === 0 ? palette.secondary[50] : "white",
              }}
            >
              {row.getVisibleCells().map((cell) => {
                const cellId = cell.id.split("_")[1];
                const isShowBorder =
                  availabilitySubHeaderCellBorderIds.find(
                    (colId) => colId === cellId,
                  ) !== undefined;
                return (
                  <SHTableCell
                    key={cell.id}
                    style={{
                      display: "flex",
                      width: cell.column.getSize(),
                      height: HEIGHT_ROW,
                      alignItems: "center",
                      padding: cell.id.includes("Model") ? "0" : "10px",
                      borderRight: isShowBorder
                        ? `1px solid ${palette.divider}`
                        : undefined,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </SHTableCell>
                );
              })}
            </SHTableRow>
          );
        })}
      </SHTableBody>
    );
  };
  const handleOnScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const selectors = [
      ".SMAAvailabilityListHead",
      ".SMAAvailabilityListTableContainer",
      ".SMAAvailabilityListTableBody",
      ".SMAAvailabilityListTableHead",
      ".SMAListFilter",
      ".SMAListSetting",
    ];

    const elements = selectors.map((selector) =>
      document.querySelector(selector),
    );

    const [head, tableContainer, tableBody, tableHead, filterEl, settingEl] =
      elements;

    const row = (tableContainer?.clientHeight ?? 0) / HEIGHT_ROW;

    const scrollLeft = event.currentTarget.scrollLeft;
    const scrollTop = Math.floor(event.currentTarget.scrollTop);

    if (scrollLeft > 0) {
      tableBody?.classList.add("sticky");
      tableHead?.classList.add("stickyFirstCell");
    } else {
      tableBody?.classList.remove("sticky");
      tableHead?.classList.remove("stickyFirstCell");
    }

    if (scrollTop > 0) {
      const elements = [head, tableContainer, tableHead, filterEl, settingEl];

      if (currentTableData.length > row) {
        elements.forEach((element) => element?.classList.add("sticky"));
      } else if (scrollTop > 15) {
        tableHead?.classList.add("scroll");
      }
    } else {
      [head, tableContainer, tableHead, filterEl, settingEl].forEach(
        (element) => element?.classList.remove("sticky"),
      );
      tableHead?.classList.remove("scroll");
    }
  };

  useEffect(() => {
    getSmaAvailabilityListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter.investmentSize]);

  useEffect(() => {
    setCurrentTableData((prevInvestmentData) => {
      return prevInvestmentData.map((investment) => {
        if (investment.shCode === favoriteShCodeStatus.shCode) {
          return {
            ...investment,
            isFavourite: !investment.isFavourite,
          };
        }
        return investment;
      });
    });
    setOriginalTableData((prevInvestmentData) => {
      return prevInvestmentData.map((investment) => {
        if (investment.shCode === favoriteShCodeStatus.shCode) {
          if (investment.shCode === favoriteShCodeStatus.shCode) {
            return {
              ...investment,
              isFavourite: !investment.isFavourite,
            };
          }
        }
        return investment;
      });
    });

    if (favoriteShCodeStatus.shCode !== "") {
      putInvestmentFavourited(
        [favoriteShCodeStatus.shCode],
        investmentProductId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteShCodeStatus]);

  return (
    <SHTableContainer
      className="SMAAvailabilityListTableContainer"
      ref={tableContainerRef}
      style={{
        position: "relative",
        overflow:
          isFetchingData || isEmpty(currentTableData) ? "hidden" : "overlay",
        height: "100vh",
        maxHeight: `calc(100vh - ${occupiedSpace})`,
        marginTop: "25px",
      }}
      onScroll={handleOnScroll}
    >
      <SHTable
        style={{ display: "grid" }}
        sx={{
          th: {
            borderLeft: "none",
            "&:first-child": {
              borderLeft: `1px solid ${palette.secondary[100]}`,
            },
          },
          td: {
            borderTop: "none",
            borderLeft: "none",
          },
        }}
      >
        {renderTableHeader()}
        <SHTableRow
          style={{
            height: "13px",
            border: "none",
            background: "transparent",
          }}
        ></SHTableRow>
        {renderTableBody()}
      </SHTable>
    </SHTableContainer>
  );
};

export default SmaAvailabilityTable;
