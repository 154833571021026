import BusinessMetricFeature from "@components/business-metric-feature";
import {
  SHButton,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { ArrowDownSVG, EditSVG } from "@components/svgs";
import DollarSVG from "@components/svgs/dollar";
import FireSVG from "@components/svgs/fire";
import NetFlowsSVG from "@components/svgs/net-flows";
import UsersArSVG from "@components/svgs/users-ar";
import { useEditProduct } from "@hooks/useEditProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ConfigurationFeature } from "@models/configuration";
import { useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { updateBannerFeatures, updateProductType } from "@redux/slices/product";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useProduct } from "@hooks/useProduct";
import { ProductType } from "@models/product/enums/productType";

interface ProductBannerProps {
  isOtherSupplierUser?: boolean;
}

export const ProductBanner = ({
  isOtherSupplierUser = false,
}: ProductBannerProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const {
    productBrandingData,
    productData,
    bannerFeatures,
    productUI: { isLoadingEditMode, isLoadingProductBranding },
  } = useSelector((state: RootState) => state.product);
  const { productId } = useParams();
  const { checkEditProduct } = useEditProduct();
  const { canGoToEditProduct } = useUserPermissions();
  const { loadProductBrandingInfo } = useProduct();

  dispatch(updateProductType(ProductType.Platform));

  useEffect(() => {
    loadProductBrandingInfo(productId || "");
    // eslint-disable-next-line
  }, [productId]);

  useEffect(() => {
    let features: ConfigurationFeature[] = [];
    if (bannerFeatures && bannerFeatures.length > 0) {
      return;
    }
    if (!productData?.groups) {
      dispatch(updateBannerFeatures(features));
      return;
    }
    for (const group of productData.groups) {
      if (!group?.sections) {
        dispatch(updateBannerFeatures(features));
        return;
      }
      for (const section of group.sections) {
        if (
          (section.name === "Branding" || section.name === "Banner") &&
          section.features
        ) {
          features.push(...section.features);
        }
      }
      dispatch(updateBannerFeatures(features));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData?.groups]);

  if (!productBrandingData || isLoadingProductBranding) {
    return (
      <SHStack
        height={400}
        width="100%"
        sx={{
          padding: "24px 30px 30px",
          justifyContent: "flex-end",
        }}
      >
        <SHStack spacing={2}>
          <SHSkeleton height={30} width={"60%"} />
          <SHStack direction="row" spacing={2}>
            <SHSkeleton height={100} width={100} />
            <SHStack spacing={2} width={"100%"}>
              <SHSkeleton height={30} width={"30%"} />
              <SHSkeleton height={52} width={"100%"} />
            </SHStack>
          </SHStack>
        </SHStack>
      </SHStack>
    );
  }

  const {
    banner,
    logo,
    name,
    website,
    launch,
    fua,
    netFlows,
    numberOfArUsers,
  } = productBrandingData;

  return (
    <SHStack
      direction={"column"}
      spacing={2}
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 31.58%, rgba(0, 0, 0, 0.282) 47.04%, rgba(0, 0, 0, 0.42) 63.99%, rgba(0, 0, 0, 0.6) 100%), url("${banner}")`,
        backgroundColor: palette.common.white,
        height: "400px",
        width: "100%",
        padding: "24px 30px 30px",
        gap: "10px",
        justifyContent: "flex-end",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <SHStack direction="row" spacing="32px" alignItems="flex-end">
        <SHTypography
          variant="h1"
          colorVariant="white"
          sx={{
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          {name}
        </SHTypography>
        <SHTypography
          variant="subtitle1"
          colorVariant="white"
          sx={{
            fontSize: "15px",
            paddingBottom: "9px",
            columnGap: "10px",
          }}
        >
          <span style={{ paddingRight: "9px" }}>{website}</span>
          <ArrowDownSVG
            color={palette.common.white}
            style={{
              paddingLeft: "5px",
              translate: "0px 5px ",
              transform: "rotate(270deg)",
            }}
          />
        </SHTypography>
      </SHStack>
      <SHStack direction="row">
        <SHStack sx={{ flexDirection: "column-reverse" }}>
          <SHImage height={107} width={107} src={logo} />
        </SHStack>

        <SHStack
          justifyContent={"space-between"}
          alignItems="flex-start"
          marginLeft={"30px"}
          display={"flex"}
          spacing={"25px"}
          sx={(theme) => ({
            [theme.breakpoints.down(1050)]: {
              width: "100%",
            },
          })}
        >
          <SHButton
            variant="contained"
            size="extraMedium"
            sx={{
              fontWeight: 700,
              visibility:
                !isOtherSupplierUser && canGoToEditProduct(productId)
                  ? "initial"
                  : "hidden",
            }}
            disabled={isLoadingEditMode}
            isLoading={isLoadingEditMode}
            startIcon={<EditSVG color={palette.common.white} />}
            onClick={() => {
              checkEditProduct(productId || "");
            }}
          >
            {`Edit ${name || ""}`}
          </SHButton>
          <SHStack
            gap="40px"
            direction="row"
            flexWrap={"wrap"}
            sx={(theme) => ({
              [theme.breakpoints.down(1050)]: {
                gap: "6px",
                display: "grid",
                gridTemplateColumns: "2fr 4fr",
                width: "100%",
              },
            })}
          >
            <BusinessMetricFeature
              title="Launch"
              value={launch}
              icon={<FireSVG />}
            />
            <BusinessMetricFeature
              title="FUA"
              value={fua}
              icon={<DollarSVG />}
            />
            <BusinessMetricFeature
              title="Net flows in last 12 months"
              value={netFlows}
              icon={<NetFlowsSVG />}
            />
            <BusinessMetricFeature
              title="Number of AR users"
              value={numberOfArUsers}
              icon={<UsersArSVG />}
            />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
