import { SHDivider, SHStack } from "@components/design-systems";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationSection } from "@models/product/investment-product/entities/investmentProduct";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
  InvestmentProductDataTab,
} from "@models/product/investment-product/enums/status";
import {
  EditInvestmentProductFormRef,
  EditInvestmentProductSaveDraftResult,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit";
import { RootState, useAppDispatch } from "@redux/store";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useToggle } from "react-use";
import { ESGTabSkeleton } from "../../skeletons/ESGTabSkeleton";
import { renderEditTabTitle } from "../util";
import { EditESGDateTimeInput } from "./components/date-time-input-controller";
import { EditESGDropDownInput } from "./components/drop-down-input-controller";
import { EditESGGoodScoreInput } from "./components/good-score-input-controller";
import { EditESGImageField } from "./components/image-field-controller";
import { EditESGTextLongInput } from "./components/long-text-input-controller";
import { EditESGNumberInput } from "./components/number-input-controller";
import { EditESGNumberMillionInput } from "./components/number-million-input-controller";
import { EditESGSectionTitle } from "./components/section-title-controller";
import { EditESGShortTextInput } from "./components/short-text-input-controller";
import { EditESGYesNoInput } from "./components/yes-no-input-controller";
import {
  EditESGInputControllerProps,
  getEditESGFeatureDataPath,
  getEditESGFeatureProductDataPath,
  getEditESGGroupDataFieldPath,
  getEditESGSectionDataPath,
} from "./util";
import { useUserPermissions } from "@hooks/userUserPermission";
import { isEmpty, isEqual } from "lodash";
import {
  toggleSaveDraftButtonDisabled,
  updateActiveInvestmentGroup,
} from "@redux/slices/product/investment-product";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { generateUUID } from "@utils";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { useIsNew } from "@hooks/useIsNew";

interface EditESGTabProps {}

export const EditESGTab = forwardRef<
  EditInvestmentProductFormRef,
  EditESGTabProps
>((props: EditESGTabProps, ref) => {
  useImperativeHandle(ref, () => ({
    getValue() {},
    submit: async () => {},
    resetForm(data) {},
    saveDraft: async () => {
      return await handleOnSaveDraft({});
    },
    changeTab: async () => {
      return await handleOnSaveDraft({ isChangeTab: true });
    },
    onPostSubmit: async () => {
      await loadInvestmentProductEsgData();
    },
  }));
  const { investmentProductId } = useParams();
  const isNewInvestmentProduct = useIsNew();
  const {
    investmentProductUI: { isEsgDataLoading },
    investmentProductEsgData,
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);

  const { loadInvestmentProductEsg, updateExistingInvestmentProductEsgAsync } =
    useInvestmentProduct();

  const dispatch = useAppDispatch();

  const { isAdminGroup } = useUserPermissions();

  const [isFormModified, toggleFormModified] = useToggle(false);
  const [isRenderingForm, toggleRenderingForm] = useToggle(false);
  const [elementKey, setElementKey] = useState<string>(generateUUID());
  const [isSubmittingForm, toggleSubmittingForm] = useToggle(false);

  const formMethod = useForm({
    mode: "onChange",
    defaultValues: investmentProductEsgData,
  });

  const isDisabledSaveDraftButton = useMemo(() => {
    const formErrors = formMethod.formState.errors.group;
    return [
      isRenderingForm,
      !isFormModified,
      !isEmpty(formErrors),
      isSubmittingForm,
    ].some(Boolean);
  }, [
    isFormModified,
    isRenderingForm,
    formMethod.formState.errors.group,
    isSubmittingForm,
  ]);

  useEffect(() => {
    dispatch(toggleSaveDraftButtonDisabled(isDisabledSaveDraftButton));
    // eslint-disable-next-line
  }, [isDisabledSaveDraftButton]);

  const isDisableAllFields = useMemo(() => {
    const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
      isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
    );

    return isSubmittingForm || isPendingApproval;
  }, [investmentProductBannerInfo, isSubmittingForm]);

  const resetFormData = ({
    formData,
    updateDots,
  }: {
    formData?: InvestmentProductEsgDTO;
    updateDots?: boolean;
  }) => {
    formMethod.reset(formData);
    setElementKey(generateUUID());
    checkFormIsModified();

    updateDots && updateFormDataDots();
  };

  const loadInvestmentProductEsgData = async () => {
    toggleRenderingForm(true);
    const data = await loadInvestmentProductEsg(
      investmentProductId ?? "",
      "edit",
    );
    resetFormData({ formData: data });
    toggleRenderingForm(false);
  };

  useEffect(() => {
    if (isNewInvestmentProduct) return;
    loadInvestmentProductEsgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId]);

  const updateFormDataDots = () => {
    if (!investmentProductEsgData) return;

    investmentProductEsgData.group?.sections?.forEach((_, sectionIndex) =>
      updateSectionDataDots(sectionIndex),
    );
    updateGroupDataDots();
  };

  const handleOnSaveDraft = async ({
    isChangeTab = false,
  }: {
    isChangeTab?: boolean;
  }): Promise<EditInvestmentProductSaveDraftResult> => {
    toggleSubmittingForm(true);
    try {
      if (!isFormModified) return { isSuccess: true };

      const response = await updateInvestmentProduct({ isChangeTab });

      return { isSuccess: response, reloadBanner: response };
    } catch {
      return { isSuccess: false };
    } finally {
      toggleSubmittingForm(false);
    }
  };

  const updateInvestmentProduct = async ({
    isChangeTab,
  }: {
    isChangeTab?: boolean;
  }): Promise<boolean> => {
    if (!investmentProductId) return false;

    const formData = formMethod.getValues();

    const updateResponse = await updateExistingInvestmentProductEsgAsync(
      investmentProductId,
      formData,
    );

    if (!updateResponse) return false;
    if (isChangeTab) return true;

    resetFormData({ formData: updateResponse, updateDots: true });

    return true;
  };

  const updateSectionDataDots = (sectionIndex: number) => {
    let highlightType: HighlightType = HighlightType.Added;
    let dataStatus: InvestmentDataStatus = InvestmentDataStatus.Filled;
    let isModified: boolean = false;
    investmentProductEsgData?.group?.sections
      ?.at(sectionIndex)
      ?.features?.forEach((_, featureIndex) => {
        const dataPath = getEditESGFeatureProductDataPath(
          sectionIndex,
          featureIndex,
        );

        const featureDataHighLightType = formMethod.getValues(
          dataPath.highlightType,
        );
        const featureDataStatus = formMethod.getValues(dataPath.dataStatus);
        const isDataModified = !!formMethod.getValues(dataPath.isModified);

        if (isEqual(featureDataHighLightType, HighlightType.Edited)) {
          highlightType = HighlightType.Edited;
        }

        if (
          !featureDataStatus ||
          isEqual(featureDataStatus, InvestmentDataStatus.MissingData)
        ) {
          dataStatus = InvestmentDataStatus.MissingData;
        }

        if (isDataModified) isModified = true;
      });

    const sectionDataPath = getEditESGSectionDataPath(sectionIndex);
    formMethod.setValue(sectionDataPath.highlightType, highlightType);
    formMethod.setValue(sectionDataPath.dataStatus, dataStatus);
    formMethod.setValue(sectionDataPath.isModified, isModified);
  };

  const updateGroupDataDots = () => {
    let highlightType: HighlightType = HighlightType.Added;
    let dataStatus: InvestmentDataStatus = InvestmentDataStatus.Filled;
    let isModified: boolean = false;

    investmentProductEsgData?.group?.sections?.forEach((_, sectionIndex) => {
      const dataPath = getEditESGSectionDataPath(sectionIndex);

      const sectionHighlightType = formMethod.getValues(dataPath.highlightType);
      const sectionDataStatus = formMethod.getValues(dataPath.dataStatus);
      const isDataModified = !!formMethod.getValues(dataPath.isModified);

      if (isEqual(sectionHighlightType, HighlightType.Edited)) {
        highlightType = HighlightType.Edited;
      }

      if (
        !sectionDataStatus ||
        isEqual(sectionDataStatus, InvestmentDataStatus.MissingData)
      ) {
        dataStatus = InvestmentDataStatus.MissingData;
      }

      if (isDataModified) isModified = true;
    });

    const groupDataPath = getEditESGGroupDataFieldPath();
    formMethod.setValue(groupDataPath.highlightType, highlightType);
    formMethod.setValue(groupDataPath.dataStatus, dataStatus);
    formMethod.setValue(groupDataPath.isModified, isModified);

    dispatch(
      updateActiveInvestmentGroup({
        dataStatus: dataStatus,
        highlightType: highlightType,
        isModified: isModified,
      } as InvestmentConfigurationGroup),
    );
  };

  const updateFeatureDataDots = (
    sectionIndex: number,
    featureIndex: number,
  ) => {
    const featureDataPath = getEditESGFeatureDataPath(
      sectionIndex,
      featureIndex,
    );
    const productDataPath = getEditESGFeatureProductDataPath(
      sectionIndex,
      featureIndex,
    );

    formMethod.setValue(
      featureDataPath.isModified,
      formMethod.getValues(productDataPath.isModified),
    );
    formMethod.setValue(
      featureDataPath.highlightType,
      formMethod.getValues(productDataPath.highlightType),
    );
    formMethod.setValue(
      featureDataPath.dataStatus,
      formMethod.getValues(productDataPath.dataStatus),
    );
  };

  const checkFormIsModified = () => {
    const isModified = !!formMethod.getValues().group?.isModified;
    toggleFormModified(isModified);
  };

  const handleOnEditESGFieldChange = ({
    sectionIndex,
    featureIndex,
  }: {
    sectionIndex: number;
    featureIndex: number;
  }) => {
    updateFeatureDataDots(sectionIndex, featureIndex);
    updateSectionDataDots(sectionIndex);
    updateGroupDataDots();
    checkFormIsModified();
  };

  const renderSection = ({
    section,
    sectionIndex,
  }: {
    section: InvestmentProductConfigurationSection;
    sectionIndex: number;
  }) => {
    return (
      <SHStack key={section.id}>
        <SHDivider />
        <SHStack
          spacing={3}
          sx={{ paddingY: 3, paddingTop: 3 }}
          alignItems={"stretch"}
          justifyContent={"space-between"}
          direction={{ xs: "column", md: "row" }}
        >
          <EditESGSectionTitle
            title={section.name}
            sectionIndex={sectionIndex}
          />
          <SHStack direction="column" alignItems={"flex-end"} gap={3}>
            {section.features?.map((featureData, featureIndex) => (
              <SHStack
                key={featureData.id}
                direction={"column"}
                gap={1}
                alignItems={"flex-end"}
              >
                {renderFeature({
                  featureData,
                  featureIndex,
                  sectionIndex,
                })}

                <SHStack
                  sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
                  alignItems={"flex-start"}
                >
                  {renderGoodScore({
                    featureData,
                    featureIndex,
                    sectionIndex,
                  })}
                </SHStack>
              </SHStack>
            ))}
          </SHStack>
        </SHStack>
      </SHStack>
    );
  };

  const renderFeature = ({
    featureData,
    featureIndex,
    sectionIndex,
  }: EditESGInputControllerProps) => {
    const defaultProps: EditESGInputControllerProps = {
      featureData: featureData,
      featureIndex: featureIndex,
      sectionIndex: sectionIndex,
      isDisabled: isDisableAllFields,
      onChange: handleOnEditESGFieldChange,
    };

    switch (featureData.fieldType?.id) {
      case FieldTypeId.TextShort:
        return <EditESGShortTextInput {...defaultProps} />;
      case FieldTypeId.Image:
        return <EditESGImageField {...defaultProps} />;
      case FieldTypeId.Number:
        return <EditESGNumberInput {...defaultProps} />;
      case FieldTypeId.NumberMillion:
        return <EditESGNumberMillionInput {...defaultProps} />;
      case FieldTypeId.TextLong:
        return <EditESGTextLongInput {...defaultProps} />;
      case FieldTypeId.YesNo:
        return <EditESGYesNoInput {...defaultProps} />;
      case FieldTypeId.DateTime:
        return <EditESGDateTimeInput {...defaultProps} />;
      case FieldTypeId.Dropdown:
        return <EditESGDropDownInput {...defaultProps} />;
      default:
        return <></>;
    }
  };

  const renderGoodScore = ({
    featureData,
    featureIndex,
    sectionIndex,
  }: EditESGInputControllerProps) => {
    const isNullGoodScore = ![
      isAdminGroup,
      featureData.isBusinessMetric,
      featureData.isPositiveScore,
    ].every(Boolean);

    if (isNullGoodScore) return <></>;

    return (
      <EditESGGoodScoreInput
        featureData={featureData}
        featureIndex={featureIndex}
        sectionIndex={sectionIndex}
        isDisabled={isDisableAllFields}
        onChange={handleOnEditESGFieldChange}
      />
    );
  };

  if (isEsgDataLoading) return <ESGTabSkeleton />;

  return (
    <FormProvider key={elementKey} {...formMethod}>
      <SHStack direction="column" mt={3}>
        {renderEditTabTitle(InvestmentProductDataTab.ESG)}
        {investmentProductEsgData?.group?.sections?.map(
          (section, sectionIndex) => renderSection({ section, sectionIndex }),
        )}
      </SHStack>
    </FormProvider>
  );
});
