export class EditProductDefaults {
  static readonly DebounceTime = 300;
  static readonly MinDateTime = new Date("1/1/2000");
  static readonly MaxDateTime = new Date("12/31/2050");
  static readonly PlaceHolder = "Please enter detail";
  static readonly AdditionalFieldTitle = "Please enter details";
  static readonly MaxNumOfChars = 100;
  static readonly InvalidDate = "Invalid Date";
  static readonly InvalidDateFormatMessage = "Invalid date format";
}

export enum SpecialViewingGroup {
  AllSuitabilityHubUsers = "All SuitabilityHub users",
  IMAPJudges = "IMAP Judges",
}

export class SpecialModelsSection {
  static readonly AssetAllocation = "Asset allocation";
}
