import { SHButton, SHButtonProps } from "@components/design-systems";
import React from "react";

export interface InvestmentProductRecallSubmissionButtonProps
  extends SHButtonProps {
  isLoading?: boolean;
  onRecallSubmission: () => Promise<void>;
}

export const InvestmentProductRecallSubmissionButton = ({
  isLoading,
  onRecallSubmission,
  ...others
}: InvestmentProductRecallSubmissionButtonProps) => {
  const handleRecallSubmission = async () => {
    await onRecallSubmission();
  };

  return (
    <SHButton
      {...others}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={handleRecallSubmission}
      variant="contained"
      size="extraMedium"
    >
      Recall submission
    </SHButton>
  );
};
