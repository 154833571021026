import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const ESGTabSkeleton = () => {
  return (
    <SHStack sx={{ paddingY: 3 }} spacing={3}>
      <SHStack spacing={1}>
        <SHSkeleton height={24} width="30%" delay={false} />
        <SHSkeleton height={20} width="100%" delay={false} />
      </SHStack>
      <SHSkeleton height={2} width="100%" delay={false} />
      <SHStack direction={"row"} justifyContent={"space-between"}>
        <SHStack width="30%" spacing={1}>
          <SHSkeleton height={24} width="60%" delay={false} />
        </SHStack>
        <SHStack width={520} spacing={4}>
          <SHStack spacing={2}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={200} width="100%" delay={false} />
          </SHStack>
          <SHStack spacing={1} direction="row">
            <SHSkeleton height={32} width="30%" delay={false} />
            <SHSkeleton
              height={32}
              width={100}
              sx={{ borderRadius: 16, alignSelf: "center" }}
              delay={false}
            />
          </SHStack>
        </SHStack>
      </SHStack>
      <SHSkeleton height={2} width="100%" delay={false} />
      <SHStack direction={"row"} justifyContent={"space-between"}>
        <SHStack width="30%" spacing={1}>
          <SHSkeleton height={24} width="60%" delay={false} />
        </SHStack>
        <SHStack width={520} spacing={4}>
          <SHStack spacing={2}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={200} width="100%" delay={false} />
          </SHStack>
          <SHStack spacing={1} direction="row">
            <SHSkeleton height={32} width="30%" delay={false} />
            <SHSkeleton
              height={32}
              width={100}
              sx={{ borderRadius: 16, alignSelf: "center" }}
              delay={false}
            />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
