import { SHBox } from "@components/design-systems/sh-box";
import {
  SHTextField,
  SHTextFieldProps,
} from "@components/design-systems/sh-text-field";
import { ArrowDownSVG, CloseSVG } from "@components/svgs";
import { Tooltip, tooltipClasses, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { find, isNil } from "lodash";
import React from "react";
import { SHMenuItem } from "../sh-menu-item";
import { SHStack } from "../sh-stack";
import { SHTypography } from "../sh-typography";

export type SHSelectProps = SHTextFieldProps & {
  data?: any[];
  displayField?: string;
  dataItemKey?: string;
  customSelectItem?: React.ReactNode;
  allowClear?: boolean;
  onClear?: (value: string | undefined) => void;
};

export const SHSelect = React.forwardRef<HTMLDivElement, SHSelectProps>(
  (
    {
      data = [],
      SelectProps,
      value,
      placeholder,
      displayField = "value",
      dataItemKey = "key",
      customSelectItem,
      allowClear = false,
      onClear,
      ...others
    },
    ref,
  ) => {
    const { palette } = useTheme();
    return (
      <SHTextField
        {...others}
        ref={ref}
        value={value}
        select
        InputProps={{
          endAdornment: allowClear && !others.disabled ? (
            <Tooltip
              title={
                <SHTypography
                  variant="caption"
                  color={"white"}
                  marginTop="-20px"
                >
                  Clear
                </SHTypography>
              }
              placement={"top"}
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                      {
                        marginBottom: "5px",
                      },
                  },
                },
              }}
            >
              <SHStack
                style={{
                  width: "10px",
                  height: "15px",
                  right: "30px",
                  position: "absolute",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onClear && onClear("");
                }}
              >
                <CloseSVG width={"10px"} height={"15px"} />
              </SHStack>
            </Tooltip>
          ) : (
            <></>
          ),
        }}
        SelectProps={{
          displayEmpty: true,
          IconComponent: (props: any) => (
            <ArrowDownSVG width={"10px"} height={"15px"} {...props} />
          ),
          renderValue: !isNil(value)
            ? customSelectItem
              ? undefined
              : () => (
                  <SHTypography variant="body3" pt="4px" truncate>
                    {find(data, { [dataItemKey]: value })?.[displayField]}
                  </SHTypography>
                )
            : () => {
                return (
                  <SHBox
                    className="sh-select-placeholder"
                    display={"inline-block"}
                    style={{
                      color: hexToRGBA(palette.text.disabled, 0.75),
                      fontWeight: 300,
                      paddingRight: 10,
                    }}
                  >
                    {placeholder}
                  </SHBox>
                );
              },
          ...SelectProps,
        }}
      >
        {data && data.length > 0 ? (
          customSelectItem ??
          data.map((option) => (
            <SHMenuItem
              divider
              key={option[dataItemKey]}
              value={option[dataItemKey]}
              disabled={!!option.isDisabled}
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                paddingLeft: "10px",
              }}
            >
              {option[displayField]}
            </SHMenuItem>
          ))
        ) : (
          <SHStack
            p={2}
            alignItems={"center"}
            justifyContent={"center"}
            color={palette.text.disabled}
          >
            No Data Found.
          </SHStack>
        )}
      </SHTextField>
    );
  },
);
