import React, { useMemo } from "react";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { FieldTypeId } from "@models/configuration";
import SeriesFeatureTable from "../table-input-controller";
import SeriesFeatureDateTime from "../date-time-input-controller";
import SeriesFeatureTextLong from "../long-text-input-controller";
import SeriesFeatureYesNo from "../yes-no-input-controller";
import SeriesFeatureGallery from "../image-gallery-input-controller";
import SeriesFeatureDropdown from "../drop-down-input-controller";

interface EditSeriesPageRightPaneProps {
  selectedIndex?: [number, number];
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  isDisabled?: boolean;
}

const EditSeriesRightPanel = ({
  selectedIndex = [0, 0],
  investmentProductSeriesData,
  isDisabled = false,
}: EditSeriesPageRightPaneProps) => {
  const selectedSection = useMemo(() => {
    return investmentProductSeriesData!.sections?.[selectedIndex[1]];
  }, [investmentProductSeriesData, selectedIndex]);
  const textShortFeature = useMemo(() => {
    return (
      selectedSection?.features?.filter(
        (feature) => feature.fieldType?.id === FieldTypeId.TextShort,
      ) ?? []
    );
  }, [selectedSection]);

  const renderFeatureItem = (
    feature: InvestmentProductConfigurationFeature,
    featureIdx: number,
  ) => {
    switch (feature.fieldType?.id) {
      case FieldTypeId.Slideshow: {
        return (
          <SeriesFeatureGallery
            feature={feature}
            selectedIndex={selectedIndex}
            investmentProductSeriesData={investmentProductSeriesData}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.TextLong: {
        return (
          <SeriesFeatureTextLong
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.TextShort: {
        if (feature.order === textShortFeature[0]?.order) {
          return (
            <SeriesFeatureTable
              features={textShortFeature}
              selectedIndex={selectedIndex}
              tableHeaderName={investmentProductSeriesData?.name}
              isDisabled={isDisabled}
            />
          );
        }
        return;
      }
      case FieldTypeId.DateTime: {
        return (
          <SeriesFeatureDateTime
            key={featureIdx}
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.YesNo: {
        return (
          <SeriesFeatureYesNo
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.Dropdown: {
        return (
          <SeriesFeatureDropdown
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      {selectedSection?.features?.map(
        (
          feature: InvestmentProductConfigurationFeature,
          featureIdx: number,
        ) => (
          <React.Fragment key={feature.id}>
            {renderFeatureItem(feature, featureIdx)}
          </React.Fragment>
        ),
      )}
    </>
  );
};

export function getFeatureDataPath(
  selectedIndex: [number, number],
  investmentSeriesData: InvestmentProductSeriesDTO,
  selectedFeature: InvestmentProductConfigurationFeature,
) {
  const investmentSeriesDataPath = `investmentProductSeriesData.${selectedIndex[0]}`;
  const investmentSeriesDataStatusPath = `${investmentSeriesDataPath}.dataStatus`;
  const investmentSeriesDataHighlightTypePath = `${investmentSeriesDataPath}.highlightType`;
  const sectionPath = `${investmentSeriesDataPath}.sections.${selectedIndex[1]}`;
  const sectionModifiedPath = `${sectionPath}.isModified`;
  const featureIndex = investmentSeriesData.sections![
    selectedIndex[1]
  ].features!.findIndex(
    (item: InvestmentProductConfigurationFeature) =>
      item.id === selectedFeature.id,
  );
  const featurePath = `${sectionPath}.features.${featureIndex}`;
  const featureAllowedVisibilitySettingPath = `${featurePath}.allowedVisibilitySetting`;
  const featureViewingGroupSelectionsPath = `${featurePath}.viewingGroupSelections`;
  const featureModifiedPath = `${featurePath}.isModified`;
  const featureProductDataPath = `${featurePath}.productData.value`;
  const featureProductHighlightTypePath = `${featurePath}.productData.highlightType`;
  const featureProductDataStatusPath = `${featurePath}.productData.dataStatus`;
  const featureProductDataModifiedPath = `${featurePath}.productData.isModified`;
  const featureProductDataPublishedPath = `${featurePath}.productData.publishedValue`;
  const featureProductDataAdditionDataPath = `${featurePath}.productData.additionalValue`;
  const featureProductDataPublishedAdditionDataPath = `${featurePath}.productData.publishedAdditionalValue`;

  return {
    sectionPath,
    featurePath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataStatusPath,
    featureProductDataModifiedPath,
    featureProductDataAdditionDataPath,
    investmentSeriesDataStatusPath,
    investmentSeriesDataHighlightTypePath,
    featureProductDataPublishedPath,
    featureProductDataPublishedAdditionDataPath,
    investmentSeriesDataPath,
    featureAllowedVisibilitySettingPath,
    featureViewingGroupSelectionsPath,
  };
}

export default EditSeriesRightPanel;
