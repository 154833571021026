import { EyeIcon } from "@components/svgs/eye";
import { DELAY_TIME } from "@constants";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { SVGProps } from "react";

interface EyeTooltipProps {
  iconProps?: SVGProps<SVGSVGElement>;
  isActive?: boolean;
  tooltipProps?: Omit<TooltipProps, "children">;
  isVisible?: boolean;
}
const EyeIconTooltip = ({
  iconProps,
  isActive = false,
  tooltipProps,
  isVisible,
}: EyeTooltipProps) => {
  if (!isVisible) return null;
  return (
    <Tooltip
      {...tooltipProps}
      title={tooltipProps?.title || ""}
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      placement="top"
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: "5px",
              },
          },
        },
      }}
    >
      <EyeIcon style={iconProps?.style} isActive={isActive} />
    </Tooltip>
  );
};

export default EyeIconTooltip;
