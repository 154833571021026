import { InformationButton } from "@components/buttons/information";
import { SHLabel, SHStack, SHTypography } from "@components/design-systems";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import {
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { getFeatureProductDataPaths } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import Balancer from "react-wrap-balancer";

interface TableFieldLabelProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationModelsFeature;
}

export const TableFieldLabel = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: TableFieldLabelProps) => {
  const { control, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );

  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });

  return (
    <SHStack direction="row" alignItems={"center"}>
      <Balancer>
        <SHLabel
          dotGroupProps={{
            orangeDot: isShowOrangeDot,
            blueDot: isShowBlueDot,
            greenDot: feature.isBusinessMetric,
          }}
          label={
            <SHTypography variant="body4" lineHeight="1rem !important">
              {feature.name}
            </SHTypography>
          }
        />
      </Balancer>
      {!isEmpty(feature.description) && (
        <InformationButton content={feature.description} />
      )}
    </SHStack>
  );
};
