import { PlusButton } from "@components/buttons/plus";
import { SHStack, SHTypography } from "@components/design-systems";
import { TopBar } from "@layouts/top-bar";
import { ProductStatus } from "@models/product/enums/status";
import { EditInvestmentProductFormRef } from "@pages/suppliers/_id/_products/investment-product/_id/edit";
import ViewingGroupDialog from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/viewing-group-dialog";
import ViewingGroupTable from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/viewing-group-table";
import { DialogTitles } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/config";
import { forwardRef, useImperativeHandle } from "react";
import { useToggle } from "react-use";

interface EditVisibilityTabProps {}

export const EditVisibilityTab = forwardRef<
  EditInvestmentProductFormRef,
  EditVisibilityTabProps
>((props: EditVisibilityTabProps, ref) => {
  const [openCreateDialog, toggleOpenCreateDialog] = useToggle(false);
  useImperativeHandle(ref, () => ({
    getValue() {},
    submit: async () => {},
    resetForm(data) {},
    saveDraft: async () => {
      return { isSuccess: true };
    },
    onPostSubmit: async () => {},
    changeTab: async () => {
      return { isSuccess: true };
    },
  }));

  return (
    <SHStack spacing={2} flexDirection={"column"} mt={2}>
      <TopBar
        title={<SHTypography variant="subtitle1">Viewing Groups</SHTypography>}
        tools={
          <PlusButton
            disabled
            variant="contained"
            onClick={toggleOpenCreateDialog}
            size="extraMedium"
          >
            New Viewing Group
          </PlusButton>
        }
      />
      <ViewingGroupTable />
      <ViewingGroupDialog
        dialogTitle={DialogTitles.CREATE_VIEWING_GROUP}
        isOpen={openCreateDialog}
        onClose={toggleOpenCreateDialog}
        initialData={{
          createdDate: new Date(),
          id: "",
          lastModifiedDate: new Date(),
          name: "",
          status: ProductStatus.Active,
          suppliers: [],
        }}
      />
    </SHStack>
  );
});
