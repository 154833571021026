import { SHDotGroup, SHHtmlBlock, SHStack } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import React, { useMemo } from "react";
import { getEditManagerSectionDataPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { useFormContext } from "react-hook-form";

export const EditManagerSectionTitle = ({
  title,
  subTitle,
  icon,
  postfixLabel,
  sectionIndex,
}: {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  postfixLabel?: React.ReactNode;
  sectionIndex: number;
}) => {
  const { palette } = useTheme();

  const { control, getValues } = useFormContext<InvestmentProductManagerDTO>();

  const sectionDataPath = useMemo(
    () => getEditManagerSectionDataPath(sectionIndex),
    [sectionIndex],
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: sectionDataPath.highlightType,
        dataStatus: sectionDataPath.dataStatus,
      },
    });

  return (
    <SHStack>
      <SHStack direction="row" spacing={1} alignItems="center">
        {icon}
        <SHStack direction="row" spacing={0.5} alignItems="start">
          {typeof title === "string" ? (
            <SHHtmlBlock
              variant={"subtitle2"}
              textAlign={"left"}
              content={title}
            />
          ) : (
            title
          )}
          <SHDotGroup
            blueDot={isShowBlueDot}
            orangeDot={isShowOrangeDot}
            paddingTop={"5px"}
          />
        </SHStack>
        <SHStack direction="row" spacing={1} alignItems="center" pb={"5px"}>
          {postfixLabel}
        </SHStack>
      </SHStack>
      {typeof subTitle === "string" ? (
        <SHHtmlBlock
          variant="body4"
          color={palette.text.third}
          content={subTitle}
        />
      ) : (
        subTitle
      )}
    </SHStack>
  );
};
