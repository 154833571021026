import { forwardRef, SVGProps } from "react";

interface EyeIconProps {
  isActive: boolean;
}

export const EyeIcon = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement> & EyeIconProps
>(({ isActive, fill = "#515B6F", ...props }, ref) => (
  <svg
    {...props}
    ref={ref}
    className={"sh-image-svg"}
    width="15"
    height="14"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_19444_40654)" className={"sh-eye-svg-g"}>
      <path
        className="sh-icon-svg-eye"
        d="M6.6668 7.20078C6.98506 7.20078 7.29028 7.07435 7.51532 6.84931C7.74037 6.62427 7.8668 6.31904 7.8668 6.00078C7.8668 5.68252 7.74037 5.3773 7.51532 5.15225C7.29028 4.92721 6.98506 4.80078 6.6668 4.80078C6.34854 4.80078 6.04331 4.92721 5.81827 5.15225C5.59323 5.3773 5.4668 5.68252 5.4668 6.00078C5.4668 6.31904 5.59323 6.62427 5.81827 6.84931C6.04331 7.07435 6.34854 7.20078 6.6668 7.20078Z"
        fill={fill}
      />
      <path
        className="sh-icon-svg-eye"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.941406 6.00078C1.70581 3.56658 3.97981 1.80078 6.66661 1.80078C9.35341 1.80078 11.6274 3.56658 12.3918 6.00078C11.6274 8.43498 9.35341 10.2008 6.66661 10.2008C3.97981 10.2008 1.70581 8.43498 0.941406 6.00078ZM9.06661 6.00078C9.06661 6.6373 8.81375 7.24775 8.36366 7.69784C7.91357 8.14793 7.30313 8.40078 6.66661 8.40078C6.03009 8.40078 5.41964 8.14793 4.96955 7.69784C4.51946 7.24775 4.26661 6.6373 4.26661 6.00078C4.26661 5.36426 4.51946 4.75381 4.96955 4.30373C5.41964 3.85364 6.03009 3.60078 6.66661 3.60078C7.30313 3.60078 7.91357 3.85364 8.36366 4.30373C8.81375 4.75381 9.06661 5.36426 9.06661 6.00078Z"
        fill={fill}
      />
      {isActive ? null : (
        <g filter="url(#filter0_d_19444_40654)">
          <rect
            className="sh-icon-svg-eye"
            x="1.79297"
            y="1.5"
            width="12.4907"
            height="0.75"
            rx="0.375"
            transform="rotate(38.7463 1.79297 1.5)"
            fill={fill}
          />
        </g>
      )}
    </g>
  </svg>
));
