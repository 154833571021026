import { SHStack, SHStackProps } from "@components/design-systems";
import { ProductCard } from "@components/product-card";
import { ProductDTO } from "@models/product/entities/product";
import { ProductType } from "@models/product/enums/productType";

export interface ProductListProps extends SHStackProps {
  data?: ProductDTO[];
  onClickProduct: (
    productId?: string,
    supplierId?: string,
    productType?: ProductType | null,
  ) => void;
}

export const ProductList = ({
  data = [],
  onClickProduct,
  ...others
}: ProductListProps) => {
  return (
    <SHStack
      gap={"10px"}
      sx={{ flexWrap: "wrap", width: "100%" }}
      direction={"row"}
      {...others}
    >
      {data.map((item) => (
        <ProductCard
          hasVisibility={item.hasCustomVisibility}
          key={item?.id}
          product={item}
          onClickProduct={() =>
            onClickProduct(item?.id, item?.supplierId, item?.productType)
          }
        />
      ))}
    </SHStack>
  );
};
