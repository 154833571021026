import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHDivider,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { GridImageGallery } from "@components/grid-image-gallery";
import SidePanelSection from "@components/side-panel-section";
import { ImageSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { DateFormat, FullDateFormat } from "@constants/format";
import { UserType } from "@models/auth";
import {
  ConfigurationSection,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import { S3ObjectDTO } from "@models/core";
import {
  InvestmentProductConfigurationFeature,
  InvestmentProductConfigurationSection,
} from "@models/product/investment-product/entities/investmentProduct";
import { Link, useTheme } from "@mui/material";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import {
  checkStringIsNumber,
  isSpecialSection,
} from "@pages/suppliers/_id/util";
import { RootState } from "@redux/store";
import { generateFeatureName } from "@utils/text-format";
import { format } from "date-fns";
import parse from "date-fns/parse";
import { last } from "lodash";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { FeatureViewLongText } from "../feature-view-long-text";
import { NumericFormat } from "react-number-format";
import EyeIconTooltip from "@components/svgs/eye-tooltip";
import { VisibilityMessage } from "@pages/suppliers/constants";
import Balancer from "react-wrap-balancer";

interface ViewStandardProps {
  sections: InvestmentProductConfigurationSection[] | undefined;
  isLoadingProductData?: Boolean;
  investmentProductUpdateDate: Date | undefined;
}

export const ViewStandard = ({
  sections = undefined,
  isLoadingProductData = false,
  investmentProductUpdateDate,
}: ViewStandardProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  const mainBodySections = sections?.filter(
    (section) => section.displayType?.id === SectionDisplayTypeId.MainBodyPage,
  );

  const lastItem = last(last(mainBodySections)?.features);

  const serviceBodySections = sections?.filter(
    (section) => section.displayType?.id === SectionDisplayTypeId.Feature,
  );

  const sidePanelSections = sections?.filter(
    (section) =>
      section.displayType?.id === SectionDisplayTypeId.SidePanel ||
      section.displayType?.id === SectionDisplayTypeId.SidePanelRow,
  );

  const renderFeatureItem = (
    section: InvestmentProductConfigurationSection,
    feature: InvestmentProductConfigurationFeature,
    isLastItem: boolean,
  ) => {
    const featureName = generateFeatureName(
      feature.name ?? "",
      feature.description,
    );

    const value = feature.productData?.value;
    const isViewRestricted = feature.isViewRestricted;
    const hasCustomVisibility = feature.hasCustomVisibility;

    const isLongText = feature.fieldType?.id === FieldTypeId.TextLong;

    const isSpecialFormat = isSpecialSection(section.name ?? "");

    const renderFeatureImage = (isGallery: boolean, content: ReactNode) => {
      return (
        <>
          <SHCollapse
            sx={{
              py: isSpecialFormat ? "25px" : "15px",
            }}
            open
            title={
              <SHStack direction="row" spacing={0.5} alignItems="start">
                {isGallery && <ImageSVG />}
                <SHTypography
                  color={palette.text.primary}
                  variant={isSpecialFormat ? "body1" : "subtitle2"}
                >
                  {feature.name}
                </SHTypography>
                {feature?.description && (
                  <InformationButton content={feature.description} />
                )}
              </SHStack>
            }
          >
            <SHBox sx={{ pb: isLastItem ? "0px" : "15px" }}>{content}</SHBox>
          </SHCollapse>
          {!isLastItem && <SHDivider />}
        </>
      );
    };

    const renderFeatureText = (content: ReactNode) => {
      return (
        <SHStack key={feature.id}>
          <SHStack
            direction={isLongText ? "column" : "row"}
            justifyContent="space-between"
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <Balancer>
              <SHTypography
                variant="subtitle2"
                textAlign="left"
                color={palette.text.primary}
              >
                {featureName.first}
                {feature?.description && (
                  <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                    {featureName.last}
                    <InformationButton
                      content={feature.description}
                      margin={"-8px 0px 2px 4px !important"}
                    />
                  </SHBox>
                )}
                <EyeIconTooltip
                  tooltipProps={{
                    title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                  }}
                  iconProps={{
                    style: {
                      margin: "0px 0px -2px 10px",
                    },
                  }}
                  isVisible={hasCustomVisibility && !isViewRestricted}
                />
              </SHTypography>
            </Balancer>

            <SHBox>{content}</SHBox>
          </SHStack>
          <SHDivider />
        </SHStack>
      );
    };

    const renderFeatureDateTime = () => {
      const dateValue = parse(value ?? "", FullDateFormat, new Date());
      return (
        <SHStack key={feature.id}>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              py: "15px",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              width="45%"
              color={palette.text.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    margin: "0px 0px -2px 10px",
                  },
                }}
                isVisible={hasCustomVisibility && !isViewRestricted}
              />
            </SHTypography>

            <SHBox pt={"5px"}>
              <SHTypography variant="body3" color={palette.text.fourth}>
                {format(dateValue, DateFormat)}
              </SHTypography>
            </SHBox>
          </SHStack>
          <SHDivider />
        </SHStack>
      );
    };

    const renderFeatureDropdown = () => {
      const lov = feature.lovData;
      const displayValue = lov?.find((x) => x.id === value)?.name;

      if (!displayValue) return renderNodata();

      return (
        <>
          <SHStack
            direction={"row"}
            justifyContent="space-between"
            sx={{ pr: "40px", py: "25px" }}
          >
            <SHStack direction="row" spacing={0.5} alignItems="start">
              <SHTypography color={palette.text.primary}>
                {feature.name}
              </SHTypography>
              {feature?.description && (
                <InformationButton content={feature.description} />
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    margin: "4px 0px 0px 10px",
                  },
                }}
                isVisible={hasCustomVisibility && !isViewRestricted}
              />
            </SHStack>
            <SHBox>
              <SHHtmlBlock
                variant="body3"
                color={palette.text.fourth}
                content={displayValue}
                key={feature.id}
              />
            </SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };

    const renderFeatureYesNo = () => {
      const value = feature.productData?.value;
      const additionalValue = feature.productData?.additionalValue;
      const hasDescription =
        feature.productData?.value === "true" && additionalValue;

      const content: JSX.Element = (
        <SHStack
          direction={"row"}
          justifyContent="space-between"
          sx={{ pr: "40px", py: "25px" }}
        >
          <SHStack direction="row" spacing={0.5} alignItems="center">
            <SHTypography
              color={palette.text.primary}
              variant={isSpecialFormat ? "body1" : "subtitle2"}
            >
              {feature.name}
            </SHTypography>
            {feature?.description && (
              <InformationButton content={feature.description} />
            )}
            <EyeIconTooltip
              tooltipProps={{
                title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
              }}
              iconProps={{
                style: {
                  margin: "0px 0px -2px 10px",
                },
              }}
              isVisible={hasCustomVisibility && !isViewRestricted}
            />
          </SHStack>
          <SHBox>
            <SHBooleanIcon isTrue={value === "true"} />
          </SHBox>
        </SHStack>
      );

      if (!hasDescription) {
        return (
          <>
            {content}
            <SHDivider />
          </>
        );
      }

      return (
        <>
          <SHCollapse
            title={content}
            open
            children={
              <FeatureViewLongText
                content={additionalValue}
                textColor={palette.text.fourth}
              />
            }
            fullWidthTitle
          />
          <SHDivider />
        </>
      );
    };

    const renderNodata = () => {
      if (feature.fieldType?.id === FieldTypeId.Slideshow) return <></>;

      return (
        <SHStack key={feature.id}>
          <SHStack
            direction={isLongText ? "column" : "row"}
            justifyContent="space-between"
            columnGap={1}
            sx={{ pr: "40px", py: "25px" }}
          >
            <SHTypography
              variant={isSpecialFormat ? "body1" : "subtitle2"}
              textAlign="left"
              color={palette.text.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    margin: "0px 0px -2px 10px",
                  },
                }}
                isVisible={hasCustomVisibility && !isViewRestricted}
              />
            </SHTypography>

            <SHTypography
              variant="body3"
              colorVariant="fourth"
              key={feature.id}
              flexShrink={0}
            >
              No data
            </SHTypography>
          </SHStack>
          {!isLastItem && <SHDivider />}
        </SHStack>
      );
    };
    const renderRestricted = () => {
      return (
        <SHStack key={feature.id}>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            columnGap={1}
            sx={{ pr: "40px", py: "25px" }}
          >
            <SHTypography
              variant={isSpecialFormat ? "body1" : "subtitle2"}
              textAlign="left"
              color={palette.text.primary}
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
            </SHTypography>
            <SHTypography
              variant="body3"
              colorVariant="disabled"
              key={feature.id}
            >
              {VisibilityMessage.FEATURE_LEVEL}
            </SHTypography>
          </SHStack>
          {!isLastItem && <SHDivider />}
        </SHStack>
      );
    };
    const renderPercentage = () => {
      const isNumber = checkStringIsNumber(value);
      let component = (
        <SHTypography variant="body3" colorVariant="fourth">
          {value}
        </SHTypography>
      );
      if (isNumber) {
        component = (
          <NumericFormat
            valueIsNumericString
            value={value}
            displayType="text"
            decimalScale={2}
            prefix="$"
          />
        );
      }
      return renderFeatureText(component);
    };
    const renderCurrency = () => {
      const isNumber = checkStringIsNumber(value);
      let component = (
        <SHTypography variant="body3" colorVariant="fourth">
          {value}
        </SHTypography>
      );
      if (isNumber) {
        component = (
          <NumericFormat
            valueIsNumericString
            value={value}
            displayType="text"
            decimalScale={2}
            prefix="$"
          />
        );
      }

      return renderFeatureText(component);
    };
    if (isViewRestricted) return renderRestricted();

    if (!value) return renderNodata();

    if (!sections) {
      return <></>;
    }

    switch (feature.fieldType?.id) {
      case FieldTypeId.Slideshow: {
        const value = toNativeValue(
          feature.productData?.value || "",
          FieldTypeId.Slideshow,
        ) as S3ObjectDTO[];

        if (!value || value.length === 0) {
          return <></>;
        }

        return renderFeatureImage(
          true,
          <GridImageGallery
            previewImagesDialogTitle={feature?.name}
            images={value}
          />,
        );
      }
      case FieldTypeId.Image: {
        const value = toNativeValue(
          feature.productData?.value || "",
          FieldTypeId.Image,
        ) as S3ObjectDTO;

        return renderFeatureImage(
          false,
          <SHStack justifyContent={"center"}>
            <SHImage
              src={value.url}
              alt={value.key}
              height={"250px"}
              style={{ objectFit: "contain" }}
            />
          </SHStack>,
        );
      }

      case FieldTypeId.Dropdown: {
        return renderFeatureDropdown();
      }

      case FieldTypeId.YesNo: {
        return renderFeatureYesNo();
      }

      case FieldTypeId.DateTime: {
        return renderFeatureDateTime();
      }
      case FieldTypeId.Percentage:
      case FieldTypeId.PercentageText: {
        return renderPercentage();
      }
      case FieldTypeId.Currency:
      case FieldTypeId.CurrencyText: {
        return renderCurrency();
      }

      case FieldTypeId.TextShort:
      case FieldTypeId.Number:
      case FieldTypeId.TextLong:
      case FieldTypeId.NumberText:
      default:
        return renderFeatureText(
          <SHHtmlBlock
            variant="body3"
            color={palette.text.fourth}
            content={value}
            key={feature.id}
          />,
        );
    }
  };

  const renderMainBody = () => {
    if (isLoadingProductData) {
      return (
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"100%"} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"100%"} />
          </SHStack>
        </SHStack>
      );
    }

    return (
      <SHStack spacing={2}>
        {mainBodySections?.map((section, sectionIndex, sections) => (
          <SHStack key={sectionIndex}>
            <SHStack direction="row" spacing={"5px"} alignItems="start">
              {section.iconUrl && (
                <img
                  src={section.iconUrl}
                  width={24}
                  height={24}
                  alt={section.iconUrl}
                />
              )}

              <SHTypography
                variant="subtitle1"
                sx={{ color: palette.text.primary }}
              >
                {section.name}
              </SHTypography>
              {section.description && (
                <InformationButton content={section.description} />
              )}
            </SHStack>
            {section.features?.map((feature) =>
              renderFeatureItem(section, feature, feature.id === lastItem?.id),
            )}
          </SHStack>
        ))}

        {serviceBodySections?.map((section, index) => (
          <SHStack key={index}>
            <SHStack direction="row" spacing={"5px"} alignItems="start">
              {section.iconUrl && (
                <img
                  src={section.iconUrl}
                  width={24}
                  height={24}
                  alt={section.iconUrl}
                />
              )}

              <SHTypography variant="subtitle2">{section.name}</SHTypography>
              {section.description && (
                <InformationButton content={section.description} />
              )}
            </SHStack>
            {section.features?.map(
              (feature: InvestmentProductConfigurationFeature) => (
                <React.Fragment key={feature.id}>
                  {renderServiceItem(feature)}
                </React.Fragment>
              ),
            )}
          </SHStack>
        ))}
      </SHStack>
    );
  };

  const renderServiceItem = (
    feature: InvestmentProductConfigurationFeature,
  ) => {
    const isSlideShow = feature.fieldType?.id === FieldTypeId.Slideshow;

    const value = toNativeValue(
      feature.productData?.value || "",
      isSlideShow ? FieldTypeId.Slideshow : FieldTypeId.YesNo,
    );
    const additionalValue = feature.productData?.additionalValue;
    const hasDescription = isSlideShow || (value === true && additionalValue);

    if (isSlideShow && serviceBodySections) {
      if (!value || value.length === 0) {
        return <></>;
      }

      return (
        <SHCollapse
          sx={{ pt: "25px" }}
          open
          title={
            <SHStack direction="row" spacing={0.5} alignItems="start">
              <ImageSVG />
              <SHTypography>{feature.name}</SHTypography>
            </SHStack>
          }
          children={
            <SHBox sx={{ pt: "25px" }}>
              <GridImageGallery
                previewImagesDialogTitle={serviceBodySections[0].name}
                images={value}
              />
            </SHBox>
          }
        />
      );
    }

    const renderFeatureValue = () => {
      switch (value) {
        case true:
          return <SHBooleanIcon label={"Available"} isTrue />;
        case false:
          return <SHBooleanIcon label={"Not available"} isTrue={false} />;
        default:
          return (
            <SHTypography
              variant="body2"
              colorVariant="third"
              sx={{
                minWidth: "54px",
              }}
            >
              No data
            </SHTypography>
          );
      }
    };

    const renderFeatureHeading = () => (
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        sx={{ pr: "40px", py: "25px" }}
      >
        <SHStack direction="row" spacing={0.5} alignItems="start">
          <SHTypography>{feature.name}</SHTypography>
          {feature?.description && (
            <InformationButton content={feature.description} />
          )}
        </SHStack>
        <SHBox width={130}>{renderFeatureValue()}</SHBox>
      </SHStack>
    );

    if (!hasDescription) {
      return (
        <>
          {renderFeatureHeading()}
          <SHDivider />
        </>
      );
    }

    return (
      <>
        <SHCollapse
          title={renderFeatureHeading()}
          open
          children={<FeatureViewLongText content={additionalValue} />}
          fullWidthTitle
        />
        <SHDivider />
      </>
    );
  };

  const renderSidePanel = () => {
    if (isLoadingProductData) {
      return (
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"60%"} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} />
            <SHSkeleton height={20} width={"60%"} />
            <SHSkeleton height={20} width={"60%"} />
          </SHStack>
        </SHStack>
      );
    }

    const renderPageUpdate = () => {
      if (investmentProductUpdateDate) {
        return (
          <SHTypography
            variant="body1"
            colorVariant="third"
            textAlign={"right"}
          >
            Page updated: {`${format(investmentProductUpdateDate, DateFormat)}`}
          </SHTypography>
        );
      } else {
        return <></>;
      }
    };

    return (
      <SHStack spacing={2}>
        {renderPageUpdate()}
        {sidePanelSections?.map((section, index) => (
          <SidePanelSection
            key={index}
            section={
              {
                ...section,
                sectionDisplayType: section.displayType,
              } as unknown as ConfigurationSection
            }
          />
        ))}
      </SHStack>
    );
  };

  const showUpgradeToView = () => {
    if (!isLoadingProductData && !sections) {
      if (
        user?.userType === UserType.AdviserTrial ||
        user?.userType === UserType.AdviserAdminTrial
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <SHStack
          display={"flex"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          marginTop={"25px"}
          gap={"15px"}
        >
          <SHTypography variant="subtitle2">
            Please upgrade your subscription
            <Link
              color={palette.text.primary}
              component="a"
              underline="always"
              onClick={() => {
                navigate(
                  `${generatePath(PageRoutes.practiceOverview, {
                    practiceId: user?.userMetadata?.adviser_firm_id,
                  })}/subscription`,
                );
              }}
              sx={{
                paddingX: 0.5,
                cursor: "pointer",
                textDecorationColor: palette.text.primary,
              }}
            >
              here
            </Link>
            to view detailed information, ESG policies, and features for this
            platform
          </SHTypography>
        </SHStack>
      ) : (
        <SHStack
          display={"grid"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          gap={"15px"}
        >
          {renderMainBody()}
          {renderSidePanel()}
        </SHStack>
      )}
    </SHStack>
  );
};
