import { InformationButton } from "@components/buttons/information";
import { SHRichTextEditor } from "@components/design-systems";
import {
  useEditESGPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { debounce } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EditESGInputControllerProps,
  handleOnEditESGFieldChange,
  toEditESGNativeValue,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { MAX_LONG_TEXT_LENGTH } from "@constants";

export const EditESGTextLongInput = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductEsgDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const handleOnChange = (newValue: string) => {
    handleOnEditESGFieldChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field: { value, onChange } }) => (
        <SHRichTextEditor
          value={toEditESGNativeValue(value, FieldTypeId.TextLong)}
          disabled={isDisabled}
          label={featureData.name}
          postfixLabel={postfixLabel}
          onChange={(newValue) => {
            onChange(newValue);
            debouncedHandleInputChange(newValue);
          }}
          maxLength={MAX_LONG_TEXT_LENGTH}
          height={165}
          dotGroupProps={{
            blueDot: isShowBlueDot,
            orangeDot: isShowOrangeDot,
            greenDot: featureData?.isBusinessMetric,
          }}
        />
      )}
    />
  );
};
