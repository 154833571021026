import { BetaBadge } from "@components/beta-badge";
import {
  SHBox,
  SHContainer,
  SHDot,
  SHDotType,
  SHStack,
} from "@components/design-systems";
import { SHLink } from "@components/design-systems/sh-link";
import { NavButton } from "@components/nav-button";
import { LogoSVG } from "@components/svgs";
import { TopNotification } from "@components/top-notifications";
import {
  TopNotificationGroup,
  TopNotificationItem,
} from "@components/top-notifications/model";
import { UserDropDown } from "@components/user-dropdown";
import { UserDropdownPlacement } from "@components/user-dropdown/portal";
import { LocalStorage } from "@constants";
import { PageRoutes } from "@constants/routes";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useUserPermissions } from "@hooks/userUserPermission";
import { SubscriptionBannerHidden } from "@models/users/entities/subscriptionBanner";
import { AppBar, Toolbar, useTheme } from "@mui/material";
import {
  removeConfigurationNotification,
  removeGlobalNotification,
  removeProductProfileNotification,
  removeSubscriptionNotifications,
} from "@redux/slices/top-notifications";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { concat, defaultTo, find, isEqual, isNil, map } from "lodash";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router";
import { useLocation } from "react-use";
import { appNavigation, NarBarName } from "@components/nav-bar/config";

export const NavBar = forwardRef((_props, ref?: any): JSX.Element => {
  const { palette } = useTheme();
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { havePendingPost } = useSelector((state: RootState) => state.newsFeed);
  const dispatch = useDispatch();
  const {
    globalNotifications,
    configurationNotifications,
    productProfileNotifications: platformProfileNotifications,
    adviserUserNotifications,
  } = useSelector((state: RootState) => state.topNotification);
  let notifications = concat(
    globalNotifications,
    configurationNotifications,
    platformProfileNotifications,
    adviserUserNotifications,
  );

  const productBannerItemKey = `${LocalStorage.productBannerInfo}+${user?.auth0Id}`;
  const investmentProductBannerItemKey = `${LocalStorage.investmentProductBannerInfo}+${user?.auth0Id}`;

  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const productBannerInfo = getLocalStorageItem(productBannerItemKey);
  const investmentProductBannerInfo = getLocalStorageItem(
    investmentProductBannerItemKey,
  );

  if (notifications.length > 0) {
    const initNotification = notifications.filter((notification) =>
      Object.keys(notification).includes("productId"),
    );
    if (initNotification.length > 0) {
      const productId = initNotification[0].productId!;

      const productNotifications = defaultTo(
        productBannerInfo?.[productId],
        investmentProductBannerInfo?.[productId],
      );

      let hiddenNotifications = productNotifications.filter(
        (notification: TopNotificationItem) => notification.show === false,
      );
      if (hiddenNotifications.length > 0) {
        notifications = notifications.filter(
          (notification) =>
            !hiddenNotifications.some(
              (hiddenNotification: TopNotificationItem) =>
                hiddenNotification.title === notification.title,
            ),
        );
      }
    }
  }

  //Check if there are pending posts in news feed
  const { isSuperAdmin, isAdviserAdminGroup } = useUserPermissions();

  return (
    <AppBar
      ref={ref}
      position="sticky"
      data-testid="sh-top-navbar"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          borderColor: palette.text.primary,
          borderWidth: "1px 0",
          borderStyle: "solid",
          boxShadow: "none",
          backgroundColor: hexToRGBA(palette.background.default, 0.5),
          backdropFilter: "blur(30px)",
          zIndex: 2,
        }}
      >
        <SHContainer sx={{ px: { xs: 0 } }}>
          <SHStack
            spacing={1}
            direction="row"
            justifyContent={"space-between"}
            alignItems={"stretch"}
          >
            <SHBox
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                borderColor: palette.text.primary,
                borderWidth: { xs: "0 1px 0 0", lg: "0 1px" },
                borderStyle: "solid",
                width: { sm: 120, md: 160, lg: 220 },
                px: { xs: 1, md: 2, lg: 3 },
              }}
            >
              <SHLink href={PageRoutes.home}>
                <LogoSVG />
              </SHLink>
            </SHBox>

            <SHStack
              spacing={1}
              direction="row"
              flexGrow={1}
              justifyContent="center"
              alignItems={"center"}
              data-testid="nav-buttons"
            >
              {appNavigation
                .filter(
                  (nav) =>
                    nav.roles === undefined ||
                    (user?.userType && nav.roles.includes(user.userType)) ||
                    (nav.name === "Upgrade" &&
                      user?.freeLicenceOnly &&
                      isAdviserAdminGroup),
                )
                .map((nav, index) => {
                  return nav.name !== NarBarName.SmartSearch ? (
                    <NavButton
                      key={index}
                      isActive={
                        (typeof nav.linkTo.to === "string" &&
                          location.pathname?.startsWith(nav.linkTo.to)) ||
                        (nav.name === NarBarName.Upgrade &&
                          typeof nav.linkTo.to === "string" &&
                          location.pathname?.endsWith("subscription"))
                      }
                      children={
                        isSuperAdmin && nav.haveDotGroup ? (
                          <SHStack direction={"row"} alignItems={"flex-start"}>
                            {nav.name}
                            {havePendingPost && <SHDot type={SHDotType.red} />}
                          </SHStack>
                        ) : (
                          <>
                            {nav.name}
                            {nav.isBeta && (
                              <BetaBadge
                                sx={{
                                  width: "33px",
                                  height: "12px",
                                  "& >.MuiChip-label": {
                                    overflow: "unset",
                                    paddingTop: "2px",
                                    fontSize: "8px",
                                  },
                                  "&.MuiChip-root": {
                                    position: "absolute",
                                    top: "-1px",
                                    right: "-14px",
                                  },
                                }}
                              />
                            )}
                          </>
                        )
                      }
                      linkTo={
                        nav.name !== NarBarName.Upgrade
                          ? nav.linkTo
                          : {
                              to: generatePath(
                                PageRoutes.practiceSubscription,
                                {
                                  practiceId:
                                    user?.userMetadata?.adviser_firm_id,
                                },
                              ),
                            }
                      }
                    />
                  ) : (
                    <SHStack mr={"10px !important"}>
                      <NavButton
                        key={index}
                        isActive={false}
                        children={
                          <>
                            {nav.name}
                            <BetaBadge
                              sx={{
                                width: "33px",
                                height: "12px",
                                "& >.MuiChip-label": {
                                  overflow: "unset",
                                  paddingTop: "2px",
                                  fontSize: "8px",
                                },
                                "&.MuiChip-root": {
                                  position: "absolute",
                                  top: "-1px",
                                  right: "-14px",
                                },
                              }}
                            />
                          </>
                        }
                        linkTo={nav.linkTo}
                      />
                    </SHStack>
                  );
                })}
            </SHStack>
            <UserDropDown />
          </SHStack>
        </SHContainer>
      </Toolbar>
      <UserDropdownPlacement />
      {notifications?.map((notification: TopNotificationItem) => (
        <TopNotification
          key={notification.id}
          notificationItem={notification}
          onClose={() => {
            switch (notification.group) {
              case TopNotificationGroup.Configuration:
                dispatch(removeConfigurationNotification(notification?.id));
                break;
              case TopNotificationGroup.PlatformProfile:
                const productId = notification.productId!;

                const updateProductBannerInfo = (
                  bannerInfo: any,
                  localStorageKey: string,
                ) => {
                  if (
                    !!bannerInfo &&
                    Object.keys(bannerInfo).includes(productId)
                  ) {
                    bannerInfo[productId] = bannerInfo[productId].map(
                      (notificationLocal: TopNotificationItem) => {
                        return isEqual(
                          notificationLocal.title,
                          notification.title,
                        )
                          ? { ...notificationLocal, show: false }
                          : notificationLocal;
                      },
                    );

                    setLocalStorageItem(localStorageKey, bannerInfo);
                  }
                };

                updateProductBannerInfo(
                  productBannerInfo,
                  productBannerItemKey,
                );
                updateProductBannerInfo(
                  investmentProductBannerInfo,
                  investmentProductBannerItemKey,
                );
                dispatch(removeProductProfileNotification(notification?.id));

                break;
              case TopNotificationGroup.AdviserUserSubscription:
                const currBannersHidden: SubscriptionBannerHidden[] =
                  getLocalStorageItem(LocalStorage.SubscriptionBannersHidden) ??
                  [];
                const existsInCurrentBannersHidden = find(currBannersHidden, {
                  id: notification.id,
                  userId: user?.auth0Id,
                });

                let newBannersHidden;
                if (existsInCurrentBannersHidden) {
                  newBannersHidden = map(currBannersHidden, (item) => {
                    if (item.id !== notification.id) return item;
                    return {
                      ...item,
                      expiredDate: notification.subscriptionExpiredDate,
                      hiddenCount: item.hiddenCount + 1,
                      hiddenDate: !isNil(notification.testClockDate)
                        ? notification.testClockDate
                        : new Date(),
                    };
                  });
                } else {
                  newBannersHidden = concat(currBannersHidden, {
                    id: notification.id ?? "",
                    userId: user?.auth0Id ?? "",
                    adviserFirmId: user?.userMetadata?.adviser_firm_id ?? "",
                    hiddenCount: 1,
                    hiddenDate: !isNil(notification.testClockDate)
                      ? notification.testClockDate
                      : new Date(),
                    expiredDate:
                      notification.subscriptionExpiredDate ?? new Date(),
                  });
                }

                setLocalStorageItem(
                  LocalStorage.SubscriptionBannersHidden,
                  newBannersHidden,
                );
                dispatch(removeSubscriptionNotifications(notification?.id));
                break;
              default:
                dispatch(removeGlobalNotification(notification?.id));
                break;
            }
          }}
        />
      ))}
    </AppBar>
  );
});
