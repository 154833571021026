import { SHStack } from "@components/design-systems";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useFormContext } from "react-hook-form";
import { getNewModelProductSeries } from "@services/product/investment-product/investmentProductService";
import { useNotification } from "@hooks/useNotification";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import SeriesSectionTitle from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/section-title-controller";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { isEqual } from "lodash";
import EditSeriesCollapseMenu from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/edit-series-collapse-menu";
import EditSeriesRightPanel from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/edit-series-right-panel";

const EditSeriesPageLayout = () => {
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const { investmentProductSeriesData } = useSelector(
    (state: RootState) => state.investmentProduct,
  );
  const { reset, getValues } = useFormContext();
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );

  const { investmentProductId } = useParams();
  const { notify } = useNotification();
  const [formData, setFormData] = useState<InvestmentProductSeriesDTO[]>(
    investmentProductSeriesData ?? [],
  );

  const selectedSection = useMemo(() => {
    return formData?.[selectedIndex[0]]?.sections?.[selectedIndex[1]];
  }, [formData, selectedIndex]);

  const handleAddNewSeriesModel = async () => {
    const response = await getNewModelProductSeries(investmentProductId ?? "");

    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });
      return;
    }

    let newSeriesData = response.data as InvestmentProductSeriesDTO;
    const preInvestmentSeriesData = [
      ...getValues().investmentProductSeriesData,
    ] as InvestmentProductSeriesDTO[];

    newSeriesData = createFeatureProductData(newSeriesData);
    if (preInvestmentSeriesData.length > 0) {
      newSeriesData.order =
        Math.max(
          ...preInvestmentSeriesData.map((i) => {
            return i.order ? i.order : 0;
          }),
        ) + 1;
    } else {
      newSeriesData.order = 1;
    }

    preInvestmentSeriesData.push(newSeriesData);
    reset({ investmentProductSeriesData: preInvestmentSeriesData });
    setFormData(preInvestmentSeriesData);
  };

  const createFeatureProductData = (
    investmentProductSeries: InvestmentProductSeriesDTO,
  ): InvestmentProductSeriesDTO => {
    investmentProductSeries.sections?.forEach((section) => {
      section.features?.forEach((feature, featureIdx) => {
        const featureFieldType = feature.fieldType?.id;
        const isFirstIndexTextShort =
          featureFieldType === FieldTypeId.TextShort && featureIdx === 0;
        const dataStatusField =
          isFirstIndexTextShort || featureFieldType === FieldTypeId.Slideshow
            ? InvestmentDataStatus.Filled
            : InvestmentDataStatus.MissingData;

        feature.productData = {
          id: "",
          productId: investmentProductSeries.productId,
          configurationFeatureId: feature.id,
          value:
            feature.fieldType?.id === FieldTypeId.TextShort && featureIdx === 0
              ? investmentProductSeries.name
              : "",
          publishedValue: undefined,
          additionalValue: "",
          publishedAdditionalValue: undefined,
          isPositiveScore: false,
          dataStatus: dataStatusField,
          highlightType: HighlightType.Added,
          isModified: true,
        };
      });
    });

    return investmentProductSeries;
  };
  const renderRightSidePane = () => {
    if (formData && formData.length === 0) {
      return <></>;
    }

    return (
      <SHStack>
        <SHStack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <SeriesSectionTitle
            selectedIndex={selectedIndex}
            sectionName={selectedSection?.name}
            seriesName={
              selectedSection?.features
                ? selectedSection.features[0].productData?.value
                : ""
            }
            isDisabled={isSaving || isPendingApproval}
            viewingGroupData={
              formData?.[selectedIndex[0]].viewingGroupSelections ?? []
            }
          />
        </SHStack>
        <form>
          <EditSeriesRightPanel
            investmentProductSeriesData={formData?.[selectedIndex[0]]}
            selectedIndex={selectedIndex}
            isDisabled={isSaving || isPendingApproval}
          />
        </form>
      </SHStack>
    );
  };

  useEffect(() => {
    if (investmentProductSeriesData) {
      setFormData(investmentProductSeriesData);
      reset({ investmentProductSeriesData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductSeriesData]);

  return (
    <SHStack>
      <ProductFeaturesLayout
        leftSide={
          <EditSeriesCollapseMenu
            investmentProductSeriesData={formData}
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            defaultExpandIndexes={[0]}
            onAddNewModelClick={handleAddNewSeriesModel}
            isDisabled={isSaving || isPendingApproval}
            mode="edit"
          />
        }
        rightSide={renderRightSidePane()}
      />
    </SHStack>
  );
};

export default EditSeriesPageLayout;
