import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { InformationButton } from "@components/buttons/information";
import {
  SHRichTextEditor,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const EditManagerTextLongInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack width={{ xs: "100%", md: 520 }} gap={1}>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SHRichTextEditor
            value={toEditManagerNativeValue(value, FieldTypeId.TextLong)}
            disabled={isDisabled}
            label={featureData.name}
            postfixLabel={postfixLabel}
            onChange={(newValue) => {
              onChange(newValue);
              debouncedHandleInputChange(newValue);
            }}
            maxLength={MAX_LONG_TEXT_LENGTH}
            height={165}
            dotGroupProps={{
              blueDot: isShowBlueDot,
              orangeDot: isShowOrangeDot,
              greenDot: !!featureData?.isBusinessMetric,
            }}
          />
        )}
      />
      {featureData.allowedVisibilitySetting && (
        <SHStack direction={"row"} alignItems={"center"} gap={2}>
          <SHTypography variant="body3">Visible to</SHTypography>
          <Controller
            name={featureDataPath.viewingGroupSelections}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SelectViewingGroups
                disabled={isDisabled}
                sx={{
                  flex: 1,
                }}
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value);
                }}
                value={
                  (getValues(featureDataPath.viewingGroupSelections) as
                    | ViewingGroupSelectionDTO[]
                    | null) || []
                }
                viewingGroupData={featureData.viewingGroupSelections ?? []}
              />
            )}
          />
        </SHStack>
      )}
    </SHStack>
  );
};
