import { SHHtmlBlock } from "@components/design-systems";
import { FieldGroup } from "@layouts/form/field-group";
import { HighlightType } from "@models/configuration";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import {
  InvestmentDataStatus,
  InvestmentProductDataTab,
} from "@models/product/investment-product/enums/status";
import { defaultTo, isEqual } from "lodash";

export const investmentProductTabMapping: {
  [key in string]?: {
    id: InvestmentProductDataTab;
    title: string;
    description: string;
    viewIndex?: number;
    editIndex?: number;
  };
} = {
  Manager: {
    id: InvestmentProductDataTab.Manager,
    title: InvestmentProductDataTab.Manager,
    description: "",
    viewIndex: 0,
    editIndex: 0,
  },
  Series: {
    id: InvestmentProductDataTab.Series,
    title: InvestmentProductDataTab.Series,
    description: "",
    viewIndex: 1,
    editIndex: 1,
  },
  Models: {
    id: InvestmentProductDataTab.Models,
    title: InvestmentProductDataTab.Models,
    description: "",
    viewIndex: 2,
    editIndex: 2,
  },
  Availability: {
    id: InvestmentProductDataTab.Availability,
    title: InvestmentProductDataTab.Availability,
    description: "",
    viewIndex: 3,
  },
  ESG: {
    id: InvestmentProductDataTab.ESG,
    title: "Environmental, social and governance (ESG)",
    description:
      "The “ESG” tab of your SMA profile highlights your approach to ESG, including policies and initiatives",
    viewIndex: 4,
    editIndex: 3,
  },
};

export class InvestmentProductSelectedTab {
  selectedTab: number = 0;
  isEditMode?: boolean = false;
}

export const renderEditTabTitle = (
  tab: InvestmentProductDataTab,
): JSX.Element => {
  const firstStandardSection = investmentProductTabMapping[tab];

  if (!firstStandardSection) return <></>;

  return (
    <FieldGroup
      firstLine
      title={firstStandardSection.title}
      subTitle={
        <SHHtmlBlock
          variant="body4"
          color="#515B6F"
          maxWidth={"80%"}
          content={firstStandardSection.description}
        />
      }
    />
  );
};

export const getEditInvestmentProductTabIndex = (
  selectedIndex: number,
): number => {
  const tabData = Object.values(investmentProductTabMapping).find((tab) =>
    isEqual(tab?.viewIndex, selectedIndex),
  );

  return defaultTo(tabData?.editIndex, 0);
};

export const otherConfigTabs: Partial<InvestmentConfigurationGroup>[] = [
  {
    id: "Visibility",
    order: 5,
    dataStatus: InvestmentDataStatus.Filled,
    highlightType: HighlightType.Added,
  },
];
