import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { ConfigurationSection } from "./section";

export interface ConfigurationGroup {
  id: string;
  name: string;
  sectionType: SectionType;
  order: number;
  createdDate: Date;
  sections?: ConfigurationSection[];
  dataStatus: ProductDataStatus;
  highlightType?: HighlightType;
  isModified?: null | boolean;
}

export enum SectionType {
  Standard = "Standard",
  HasSubSections = "HasSubSections",
  Fee = "Fee",
  SMAList = "SMAList"
}

export enum HighlightType {
  Added = "Added",
  Edited = "Edited",
  Cloned = "Cloned",
  MissingData = "MissingData",
}
