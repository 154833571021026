import { HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { defaultTo, isEqual } from "lodash";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  getEditManagerFeatureDataPath,
  getEditManagerFeatureProductDataPath,
  getEditManagerGroupDataFieldPath,
  getEditManagerSectionDataPath,
  handleEditManagerAdditionalValueChange,
  handleEditManagerYesNoFieldChange,
  handleOnEditManagerGoodScoreFieldChange,
  ManagerFeatureProductDataPath,
  ManagerGroupDataPath,
  ManagerSectionDataPath,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";

export type EditESGInputControllerProps = {
  featureData: InvestmentProductConfigurationFeature;
  featureIndex: number;
  sectionIndex: number;
  isDisabled?: boolean;
  onChange?: ({
    sectionIndex,
    featureIndex,
    newValue,
  }: {
    sectionIndex: number;
    featureIndex: number;
    newValue?: string;
  }) => void;
};

export type ESGGroupDataPath = ManagerGroupDataPath & {};

export type ESGSectionDataPath = ManagerSectionDataPath & {};

export type ESGFeatureProductDataPath = ManagerFeatureProductDataPath & {};

export const toEditESGNativeValue = toEditManagerNativeValue;

export const getEditESGGroupDataFieldPath = (): ESGGroupDataPath => ({
  ...getEditManagerGroupDataFieldPath(),
});

export const getEditESGSectionDataPath = (
  sectionIndex: number,
): ESGSectionDataPath => ({
  ...getEditManagerSectionDataPath(sectionIndex),
});

export const getEditESGFeatureDataPath = (
  sectionIndex: number,
  featureIndex: number,
) => ({
  ...getEditManagerFeatureDataPath(sectionIndex, featureIndex),
});

export const getEditESGFeatureProductDataPath = (
  sectionIndex: number,
  featureIndex: number,
): ESGFeatureProductDataPath => ({
  ...getEditManagerFeatureProductDataPath(sectionIndex, featureIndex),
});

export const handleOnEditESGGoodScoreFieldChange =
  handleOnEditManagerGoodScoreFieldChange;

export const handleOnEditESGFieldChange = ({
  originalValue,
  newValue,
  getValues,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: string | null;
  newValue?: string | null;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ESGFeatureProductDataPath;
}) => {
  const publishedValue = defaultTo(
    getValues(featureProductDataPath.publishedValue),
    "",
  );

  const highlightType = !isEqual(publishedValue, newValue)
    ? HighlightType.Edited
    : null;
  const dataStatus = !!newValue
    ? InvestmentDataStatus.Filled
    : InvestmentDataStatus.MissingData;

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(featureProductDataPath.dataStatus, dataStatus);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};

export const handleEditEsgYesNoFieldChange = handleEditManagerYesNoFieldChange;

export const handleEditESGAdditionalValueChange =
  handleEditManagerAdditionalValueChange;
