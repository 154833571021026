import { SHButton, SHButtonProps } from "@components/design-systems";
import React from "react";
import RevertToDraftDialog from "@pages/suppliers/_id/_products/_id/components/dialogs/revert-to-draft";
import { useToggle } from "react-use";

export interface InvestmentProductRevertToDraftButtonProps
  extends SHButtonProps {
  isReverting?: boolean;

  onRevert: () => Promise<void>;
}

const InvestmentProductRevertToDraftButton = ({
  isReverting,
  onRevert,
}: InvestmentProductRevertToDraftButtonProps) => {
  const [showDialog, toggleShowDialog] = useToggle(false);

  const handleOnRevert = async () => {
    await onRevert();
    toggleShowDialog(false);
  };

  return (
    <>
      <SHButton
        variant="outlined"
        size="extraMedium"
        onClick={() => toggleShowDialog(true)}
      >
        Revert to draft
      </SHButton>

      {showDialog && (
        <RevertToDraftDialog
          body="Reverting the investment product profile back to draft will allow Administrators and Suppliers to continue making changes. Are you sure?"
          onClose={() => toggleShowDialog(false)}
          onSubmit={handleOnRevert}
          isSubmitting={isReverting}
        />
      )}
    </>
  );
};

export default InvestmentProductRevertToDraftButton;
