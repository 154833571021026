import { SHBox, SHDotGroupProps, SHStack } from "@components/design-systems";
import { TabLabel } from "@components/design-systems/sh-tabs/components/tab-label";
import { SHTabSkeleton } from "@components/skeletons/design-systems/SHTabSkeleton";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, TabProps, Tabs, TabsProps, useTheme } from "@mui/material";
import { To } from "history";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { TabLink } from "./components/tab-link";
import { BetaBadge } from "@components/beta-badge";

export interface SHTab extends TabProps {
  component?: React.ReactNode;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  linkTo?: To;
  isBeta?: boolean;
}
export interface SHTabsProps {
  tabs?: SHTab[];
  value?: string;
  isNavLink?: boolean;
  onValueChange?: (value: string) => void;
  tabListProps?: TabsProps;
  isHandleByProps?: boolean;
  isLoading?: boolean;
}
export const SHTabs = ({
  value: propValue = "0",
  onValueChange,
  isNavLink = false,
  tabs = [],
  tabListProps,
  isHandleByProps,
  isLoading = false,
}: SHTabsProps): JSX.Element => {
  const [value, setValue] = useState(propValue);
  const { palette } = useTheme();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
      if (typeof onValueChange === "function") onValueChange(newValue);
    },
    [onValueChange],
  );

  useEffect(() => {
    if (!isEqual(value, propValue)) setValue(propValue);
    // eslint-disable-next-line
  }, [propValue]);

  const contextValue = useMemo(() => {
    return isHandleByProps ? propValue : value;
  }, [isHandleByProps, propValue, value]);

  if (isLoading) {
    return <SHTabSkeleton />;
  }
  if (isNavLink) {
    return (
      <SHStack>
        <SHBox
          sx={{
            borderBottom: 1,
            borderColor: palette.common.black,
            pb: "10px",
          }}
          data-testid="sh-tabs"
        >
          <Tabs variant="scrollable" value={false}>
            {tabs.map((tab: SHTab, index: number) => {
              const {
                component,
                hidden,
                linkTo,
                dotGroupProps,
                isBeta,
                ...tabProps
              } = tab;

              if (hidden) {
                return null;
              }

              if (linkTo !== undefined && !tabProps.disabled) {
                return (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <TabLink to={linkTo} end={linkTo === ""} key={index}>
                      {({ isActive }) => (
                        <Tab
                          {...tabProps}
                          label={
                            <TabLabel
                              icon={tabProps.icon}
                              label={tabProps.label}
                              disabled={tabProps?.disabled}
                              dotGroupProps={dotGroupProps}
                              isActive={isActive}
                            />
                          }
                          icon={undefined}
                          key={index}
                          value={index.toString()}
                          disableRipple
                          className={isActive ? "Mui-selected" : undefined}
                        />
                      )}
                    </TabLink>
                    {isBeta && (
                      <BetaBadge
                        sx={{
                          width: "33px",
                          height: "12px",
                          "& >.MuiChip-label": {
                            overflow: "unset",
                            paddingTop: "2px",
                            fontSize: "8px",
                          },
                          "&.MuiChip-root": {
                            position: "absolute",
                            top: "1px",
                            right: "-14px",
                          },
                        }}
                      />
                    )}
                  </div>
                );
              }

              return (
                <Tab
                  {...tabProps}
                  label={
                    <TabLabel
                      icon={tabProps.icon}
                      label={tabProps.label}
                      disabled={true}
                      dotGroupProps={dotGroupProps}
                    />
                  }
                  icon={undefined}
                  key={index}
                  disableRipple
                />
              );
            })}
          </Tabs>
        </SHBox>
      </SHStack>
    );
  }

  return (
    <TabContext value={contextValue}>
      <SHStack>
        <SHBox
          sx={{
            borderBottom: 1,
            borderColor: palette.common.black,
            pb: "10px",
          }}
          data-testid="sh-tabs"
        >
          <TabList
            {...tabListProps}
            onChange={handleChange}
            variant="scrollable"
          >
            {tabs.map(
              (
                {
                  component,
                  hidden,
                  linkTo,
                  dotGroupProps,
                  isBeta,
                  ...tabProps
                }: SHTab,
                index: number,
              ) =>
                hidden ? null : linkTo !== undefined && !tabProps.disabled ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <TabLink to={linkTo} end={linkTo === ""} key={index}>
                      {({ isActive }) => (
                        <Tab
                          {...tabProps}
                          label={
                            <TabLabel
                              icon={tabProps.icon}
                              label={tabProps.label}
                              disabled={tabProps?.disabled}
                              dotGroupProps={dotGroupProps}
                              isActive={isActive}
                            />
                          }
                          icon={undefined}
                          key={index}
                          value={index.toString()}
                          disableRipple
                          className={isActive ? "Mui-selected" : undefined}
                        />
                      )}
                    </TabLink>
                    {isBeta && (
                      <BetaBadge
                        sx={{
                          width: "33px",
                          height: "12px",
                          "& >.MuiChip-label": {
                            overflow: "unset",
                            paddingTop: "2px",
                            fontSize: "8px",
                          },
                          "&.MuiChip-root": {
                            position: "absolute",
                            top: "1px",
                            right: "-14px",
                          },
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <Tab
                    {...tabProps}
                    label={
                      <TabLabel
                        icon={tabProps.icon}
                        label={tabProps.label}
                        disabled={tabProps?.disabled}
                        dotGroupProps={dotGroupProps}
                        isActive={index.toString() === contextValue}
                      />
                    }
                    icon={undefined}
                    key={index}
                    value={index.toString()}
                    disableRipple
                  />
                ),
            )}
          </TabList>
        </SHBox>
        {tabs.map(({ hidden, component }: SHTab, index: number) =>
          hidden ? null : (
            <TabPanel value={index.toString()} key={index} sx={{ p: 0 }}>
              {component}
            </TabPanel>
          ),
        )}
      </SHStack>
    </TabContext>
  );
};
