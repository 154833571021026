import { DeleteButton } from "@components/buttons/delete";
import { DisclaimerAssumptionsButton } from "@components/buttons/disclaimer-assumptions";
import { FavouritedButton } from "@components/buttons/favourited";
import {
  SHAlert,
  SHButton,
  SHContainer,
  SHDivider,
  SHInputAdornment,
  SHNumericField,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableCell,
  SHTableCellProps,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { Dollar2SVG, WarningSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { useInvestment } from "@hooks/useInvestment";
import { useIsNew } from "@hooks/useIsNew";
import { useManagedAccount } from "@hooks/useManagedAccount";
import { useNotification } from "@hooks/useNotification";
import { FieldGroup } from "@layouts/form/field-group";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { ManagedAccountUpdateDTO } from "@models/managed-accounts/entities/analysis";
import {
  InvestmentSelectionDTO,
  ManagedAccountPortfolioSetupDTO,
  SelectedInvestmentDTO,
} from "@models/managed-accounts/entities/step/setup";
import { ManagedAccountStep } from "@models/managed-accounts/enums/step";
import {
  Collapse,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
  Zoom,
} from "@mui/material";
import {
  MaxPortfolioSize,
  MaxWeight,
  MinPortfolioSize,
  RemainingSpecialValue,
} from "@pages/managed-accounts/_id/steps/portfolio-setup/config";
import { PortfolioSetupSkeleton } from "@pages/managed-accounts/_id/steps/portfolio-setup/skeleton";
import { SelectInvestments } from "@pages/managed-accounts/components/auto-completes/investments";
import { SelectInvestmentsFavourited } from "@pages/managed-accounts/components/auto-completes/investments-favourited";
import { PortfolioTemplate } from "@pages/managed-accounts/components/auto-completes/portfolio-template";
import { MAFeeDisclaimerDialog } from "@pages/managed-accounts/components/dialogs/fee-disclaimer";
import { SaveInvestmentAsTemplateDialog } from "@pages/managed-accounts/components/dialogs/save-as-template";
import { NoWeightsDialog } from "@pages/managed-accounts/components/no-weights";
import { checkHasWeightZero } from "@pages/managed-accounts/util";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import {
  concat,
  filter,
  findIndex,
  forEach,
  isEmpty,
  some,
  sortBy,
  sumBy,
} from "lodash";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router";
import Truncate from "react-truncate";
import { useEffectOnce } from "react-use";

export interface ManagedAccountPortfolioSetupProps extends StepCompProps {}

export const ManagedAccountPortfolioSetup: ForwardRefRenderFunction<
  StepCompRef,
  ManagedAccountPortfolioSetupProps
> = ({ stepNumber }, ref) => {
  const { managedAccountId } = useParams<{ managedAccountId: string }>();
  const { palette, shadows } = useTheme();
  const { notify } = useNotification();
  const isNew = useIsNew();
  const navigate = useNavigate();

  const {
    setIsDirty,
    setIsValid,
    managedAccount,
    loadManagedAccountStep,
    createManagedAccount,
    updateManagedAccount,
    setManagedAccount,
  } = useManagedAccount();
  const {
    investmentSelections,
    investmentFavourited,
    setOriginalInvestmentSelection,
    setOriginalInvestmentFavourited,
    setInvestmentSelection,
    setInvestmentFavourited,
    putInvestmentFavourited,
  } = useInvestment();

  const {
    ui: { isLoading, isSubmitting },
    isReadOnly,
  } = useSelector((state: RootState) => state.platformAnalysis);
  const { user } = useSelector((state: RootState) => state.auth);

  const { reset, watch, control, setValue, getValues, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      ...new ManagedAccountPortfolioSetupDTO(),
    },
  });

  const { fields, remove, replace } = useFieldArray({
    control,
    name: "selectedInvestments",
    // keyName: "shCode",
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState<
    | {
        callback: (value: boolean | PromiseLike<boolean>) => void;
      }
    | undefined
  >(undefined);

  const [fetchedData, setFetchedData] = useState(false);
  const [showMAFeeDisclaimerDialog, setShowMAFeeDisclaimerDialog] =
    useState(false);
  const [showSaveAsTemplateDialog, setShowSaveAsTemplateDialog] =
    useState(false);
  const [
    showWarningRequiredInvestmentWeight,
    setShowWarningRequiredInvestmentWeight,
  ] = useState(false);
  const [
    showWarningIneligibilitySaveAsTemplate,
    setShowWarningIneligibilitySaveAsTemplate,
  ] = useState(false);
  const [hasReselectedInvestment, setHasReselectedInvestment] = useState(false);

  const isDisabled = isSubmitting || isLoading || isReadOnly;

  const portfolioSize = watch("portfolioSize") ?? 0;
  const investmentsSelected = watch("selectedInvestments") ?? [];
  const totalInvestmentWeight = Number(
    sumBy(investmentsSelected, "weight")?.toFixed(2) ?? 0,
  );

  const totalRemainingWeight = 100 - totalInvestmentWeight;
  const totalRemainingValue =
    portfolioSize - sumBy(investmentsSelected, "value");
  const isValidPortfolioSize = portfolioSize > 0;
  // const isValidWeight = totalInvestmentWeight === 100;
  const isValidValue = Math.abs(totalRemainingValue) < 1;
  // const isValidRemaining = totalRemainingValue === 0;
  const isValidInvestmentsSelectedCount = investmentsSelected.length < 50;

  const hasChangeInvestmentWeight = some(
    formState.dirtyFields.selectedInvestments,
    { weight: true },
  );
  const hasChangeSelectedInvestment = some(
    formState.dirtyFields.selectedInvestments,
    { shCode: true },
  );

  const showWarningPortfolioSize =
    !isValidPortfolioSize && (isNew || fetchedData);
  const showWarningRemainingAllocation =
    !isValidValue && (isNew || fetchedData);
  const hasWarningRequiredInvestmentWeight =
    !(totalInvestmentWeight > 0) && (isNew || fetchedData);

  const loadData = async (managedAccountId?: string) => {
    if (!managedAccountId || managedAccountId === "new") return;
    setFetchedData(false);
    const managedAccountStep = await loadManagedAccountStep({
      managedAccountId: managedAccountId,
      stepId: ManagedAccountStep.PortfolioSetup,
    });
    setFetchedData(true);
    const portfolioSetup = managedAccountStep?.portfolioSetup;
    if (!portfolioSetup) return;
    reset(portfolioSetup);
  };

  useEffectOnce(() => {
    loadData(managedAccountId);
    return () => {
      setIsValid(true);
      setIsDirty(false);
    };
  });

  const calculateInvestmentValue = (portfolioSize: number, index: number) => {
    const weight =
      (getValues(`selectedInvestments.${index}.weight`) ?? 0) / 100;
    return Number((weight * portfolioSize).toFixed(2));
  };

  const calculateInvestmentWeight = (portfolioSize: number, index: number) => {
    const value = getValues(`selectedInvestments.${index}.value`) ?? 0;

    return Number(((value / portfolioSize) * 100).toFixed(2));
  };

  const handleOnChangePortfolioSize = (portfolioSizeValue: number) => {
    setValue("portfolioSize", portfolioSizeValue, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (isValidPortfolioSize && !isEmpty(investmentsSelected)) {
      investmentsSelected?.forEach((item, index) => {
        const newInvestmentValue = calculateInvestmentValue(
          portfolioSizeValue,
          index,
        );
        setValue(`selectedInvestments.${index}.value`, newInvestmentValue, {
          shouldDirty: true,
          shouldValidate: true,
        });
      });
    }
  };

  const handleOnChangeInvestmentWeight = (weight: number, index: number) => {
    setValue(`selectedInvestments.${index}.weight`, weight, {
      shouldDirty: true,
      shouldValidate: true,
    });

    const investmentValue = calculateInvestmentValue(portfolioSize, index);
    setValue(
      `selectedInvestments.${index}.value`,
      investmentValue > MaxPortfolioSize ? MaxPortfolioSize : investmentValue,
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
  };

  const handleOnChangeInvestmentValue = (value: number, index: number) => {
    setValue(`selectedInvestments.${index}.value`, value, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (isValidPortfolioSize) {
      const investmentWeight = calculateInvestmentWeight(portfolioSize, index);
      setValue(
        `selectedInvestments.${index}.weight`,
        investmentWeight > MaxWeight ? MaxWeight : investmentWeight,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }
  };

  const getConfirm = async (): Promise<boolean> => {
    return await new Promise((res) => {
      setShowConfirmDialog({ callback: res });
    });
  };

  const handleOnSubmit = async () => {
    const investmentFavouritedCodes = investmentFavourited.map(
      (item) => item.shCode,
    );

    const formValues = getValues();

    const hasWeightZero = checkHasWeightZero(formValues.selectedInvestments);
    if (hasWeightZero) {
      const isContinue = await getConfirm();
      setShowConfirmDialog(undefined);
      if (!isContinue) {
        return false;
      }
    }

    const response = await putInvestmentFavourited(investmentFavouritedCodes);
    if (!response) {
      return false;
    }

    let isSuccess = false;
    if (isNew) {
      isSuccess = await handleOnCreateManagedAccount();
    } else {
      isSuccess = await handleOnUpdateManagedAccount();
    }

    setOriginalInvestmentSelection(investmentSelections);
    setOriginalInvestmentFavourited(investmentFavourited);

    return isSuccess;
  };

  const handleOnCreateManagedAccount = async () => {
    let isSuccess = false;
    const formValues = getValues();
    const newFormValues = {
      ...formValues,
      selectedInvestments: formValues.selectedInvestments.map((item) => {
        return {
          ...item,
          weight: item.weight ?? 0,
          value: item.value ?? 0,
        };
      }),
    } as ManagedAccountPortfolioSetupDTO;
    setIsDirty(false);

    const newManagedAccount = await createManagedAccount({
      analysisInclusion: "Standalone",
      includingAnalysisId: null,
      ownerId: user?.auth0Id ?? "",
      firmId: user?.userMetadata?.supplier_id ?? "",
      portfolioSize: newFormValues.portfolioSize,
      selectedInvestments: newFormValues.selectedInvestments,
    });
    isSuccess = !!newManagedAccount;
    setIsDirty(!isSuccess);
    if (isSuccess) {
      reset(newFormValues);
      navigate(
        generatePath(PageRoutes.managedAccountsDetail, {
          managedAccountId: newManagedAccount?.id,
        }),
        {
          replace: true,
        },
      );
    }
    return isSuccess;
  };

  const handleOnUpdateManagedAccount = async () => {
    const formValues = getValues();
    const newFormValues = {
      ...formValues,
      selectedInvestments: formValues.selectedInvestments.map((item) => {
        return {
          ...item,
          weight: item.weight ?? 0,
          value: item.value ?? 0,
        };
      }),
    } as ManagedAccountPortfolioSetupDTO;

    if (!formState.isDirty) return true;
    if (!managedAccount) return false;
    let managedAccountUpdated = await updateManagedAccount({
      id: managedAccount.id,
      setup: {
        hasRefreshData:
          hasChangeInvestmentWeight || hasChangeSelectedInvestment,
        ...newFormValues,
      },
    } as ManagedAccountUpdateDTO);
    if (!!managedAccountUpdated) {
      reset(newFormValues);
      setManagedAccount({
        ...managedAccount,
        portfolioSetup: newFormValues,
      });
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isValidInvestmentsSelectedCount && hasReselectedInvestment) {
      notify("Maximum 50 investments can be selected", {
        variant: "error",
        close: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidInvestmentsSelectedCount]);

  useEffect(() => {
    setIsDirty(formState.isDirty);
    // eslint-disable-next-line
  }, [formState.isDirty]);

  useEffect(() => {
    setIsValid(formState.isValid && isValidPortfolioSize && isValidValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isValid, isValidPortfolioSize, isValidValue]);

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      // Next button
      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      return true;
    },
    onSubmit: async () => {
      // Save button
      return await handleOnSubmit();
    },
    onExit: async () => {
      // Exit button
      const investmentFavouritedCodes = investmentFavourited.map(
        (item) => item.shCode,
      );
      await putInvestmentFavourited(investmentFavouritedCodes);
    },
  }));

  const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      border: "none",
      p: 0,
      m: 0,
    },
  }));

  if (isLoading) return <PortfolioSetupSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
      <form>
        <SHStack>
          <StepName name={"Portfolio setup"} stepNumber={stepNumber} />
          <SHStack spacing={"15px"} my={"25px"}>
            <SHTypography variant="subtitle1">Welcome!</SHTypography>
            <SHTypography variant="body4">
              Choose either an individual model or construct a portfolio out of
              multiple models. You can save your portfolio as a template and
              load the same allocations again in future analysis.
            </SHTypography>
            <SHTypography variant="body4" fontSize={"10px"}>
              Any result provided by this calculator is illustrative only. It
              relies upon information and assumptions that are subject to
              change. If varied, this information and assumptions may change the
              result. Any result is not intended to be relied on for the purpose
              of making a decision about a particular financial product,
              platform or provider.
            </SHTypography>
            <SHTypography variant="body4" fontSize={"10px"}>
              By using this calculator, you confirm that you have read and
              accepted the {"  "}
              <DisclaimerAssumptionsButton
                onClick={() => setShowMAFeeDisclaimerDialog(true)}
              />
            </SHTypography>
          </SHStack>
          <SHDivider />

          <FieldGroup
            maxWidth={490}
            title="Total investment portfolio size"
            subTitle="Please estimate the average total holding size for the next 12 months. Our fee calculations assume the portfolio size remains the same throughout the year."
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <Controller
                name="portfolioSize"
                control={control}
                render={({ field: { ref, onChange, ...others } }) => (
                  <SHNumericField
                    {...others}
                    min={MinPortfolioSize}
                    max={MaxPortfolioSize}
                    numericFormatProps={{
                      thousandSeparator: true,
                      decimalScale: 2,
                      allowNegative: false,
                    }}
                    label={"Portfolio size"}
                    autoComplete="off"
                    placeholder=""
                    onChange={(e, value) => {
                      handleOnChangePortfolioSize(value ?? 0);
                    }}
                    InputProps={{
                      startAdornment: (
                        <SHInputAdornment position="start">
                          <Dollar2SVG />
                        </SHInputAdornment>
                      ),
                    }}
                    sx={{
                      width: { xs: "100%", md: 250 },
                      "& .MuiInput-root": {
                        "&::before,&::after": {
                          left: "30px",
                        },
                        "& .MuiInput-input": {
                          fontSize: "16px",
                          lineHeight: "160%",
                          fontWeight: 600,
                          fontFamily: "Epilogue",
                        },
                      },
                    }}
                  />
                )}
              />
              <Collapse in={showWarningPortfolioSize}>
                <SHAlert
                  severity="error"
                  textAlign={"left"}
                  icon={<WarningSVG />}
                >
                  Portfolio size must be greater than zero
                </SHAlert>
              </Collapse>
            </SHStack>
          </FieldGroup>
          <SHDivider />

          <FieldGroup
            maxWidth={490}
            title="Optional: Load an investment portfolio template"
            subTitle={
              <SHTypography variant="body4" color={palette.text.third}>
                You can either choose each investment to include in your
                analysis one by one, or you can load from a template your firm
                has created. <br /> Careful: If you load a template, it will
                cause you to lose any data already entered into the portfolio
                table below
              </SHTypography>
            }
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <PortfolioTemplate
                isDisabled={isDisabled}
                portfolioSize={portfolioSize}
                replaceInvestment={replace}
                onClearSaveAsTemplateWarnings={() => {
                  setShowWarningRequiredInvestmentWeight(false);
                  setShowWarningIneligibilitySaveAsTemplate(false);
                }}
              />
            </SHStack>
          </FieldGroup>
          <SHDivider />

          <FieldGroup
            maxWidth={1000}
            title="Portfolio composition"
            subTitle="Search and add investments to the portfolio. If you have favourited any investments, use the second dropdown to only see your favourites."
          />

          <SHStack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            marginBottom={3}
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <SHStack direction="column">
                <SelectInvestments
                  disabled={!isValidInvestmentsSelectedCount}
                  selectedCodes={
                    investmentsSelected?.map((item) => item.shCode) ?? []
                  }
                  textFieldProps={{
                    InputLabelProps: { shrink: true },
                    label: "Add investments to the portfolio",
                    placeholder: "Search all investments",
                  }}
                  hasChange={(value) => setHasReselectedInvestment(value)}
                  onAppend={(investment) => {
                    replace(
                      sortBy(concat(watch("selectedInvestments"), investment), [
                        "managerName",
                        "name",
                      ]),
                    );
                  }}
                  onRemove={(shCode) =>
                    remove(
                      findIndex(investmentsSelected, {
                        shCode: shCode,
                      }),
                    )
                  }
                  onRemoveAll={() => {
                    remove();
                  }}
                />
              </SHStack>
            </SHStack>
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <SHStack direction="column">
                <SelectInvestmentsFavourited
                  disabled={!isValidInvestmentsSelectedCount}
                  maxLength={investmentFavourited.length}
                  selectedCodes={
                    investmentsSelected?.map((item) => item.shCode) ?? []
                  }
                  hasChange={(value) => setHasReselectedInvestment(value)}
                  onAppend={(investment) => {
                    replace(
                      sortBy(concat(fields, investment), [
                        "managerName",
                        "name",
                      ]),
                    );
                  }}
                  onRemove={(shCode) =>
                    remove(
                      findIndex(investmentsSelected, {
                        shCode: shCode,
                      }),
                    )
                  }
                  onRemoveAll={() => {
                    let arrIndex: number[] = [];
                    const filteredItems = filter(investmentsSelected, {
                      isFavourited: true,
                    });

                    forEach(filteredItems, (item) => {
                      const index = findIndex(investmentsSelected, {
                        shCode: item.shCode,
                      });
                      arrIndex.push(index);
                    });

                    if (!isEmpty(arrIndex)) remove(arrIndex);
                  }}
                  textFieldProps={{
                    InputLabelProps: { shrink: true },
                    label: "Select from favourites",
                    placeholder: "Search favourited investments",
                  }}
                />
              </SHStack>
            </SHStack>
          </SHStack>

          <SHTable sx={{ mb: 3 }}>
            <InvestmentSelectedTableHead />
            <SHTableBody
              sx={{
                "&.MuiTableBody-root::before": {
                  lineHeight: "0px",
                  fontSize: "0px",
                },
              }}
            >
              {fields.map((selectedInvestment, index) => {
                const { code, shCode, isFavourited, displayName } =
                  selectedInvestment;
                return (
                  <SHTableRow key={shCode}>
                    <PortfolioSetupTableCell>
                      <SHStack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={"10px"}
                      >
                        <DeleteButton
                          height={32}
                          width={32}
                          backgroundColor={"transparent"}
                          borderColor={palette.common.black}
                          iconColor={palette.common.black}
                          onClick={() => {
                            remove(index);
                          }}
                          tabIndex={-1}
                        />
                        <FavouritedButton
                          height={32}
                          width={32}
                          isFavourited={isFavourited}
                          onClick={() => {
                            const newSelectedInvestments =
                              investmentsSelected.map(
                                (item: SelectedInvestmentDTO) => {
                                  if (item.shCode !== shCode) return item;
                                  return {
                                    ...item,
                                    isFavourited: !item.isFavourited,
                                  };
                                },
                              );

                            const newInvestmentSelections =
                              investmentSelections.map(
                                (item: InvestmentSelectionDTO) => {
                                  if (item.shCode !== shCode) return item;
                                  return {
                                    ...item,
                                    isFavourited: !item.isFavourited,
                                  };
                                },
                              );

                            const newFavouritedInvestment =
                              newInvestmentSelections.filter(
                                (item) => item.isFavourited,
                              );

                            setInvestmentSelection(newInvestmentSelections);

                            setValue(
                              "selectedInvestments",
                              newSelectedInvestments,
                              { shouldDirty: true, shouldValidate: true },
                            );

                            setInvestmentFavourited(newFavouritedInvestment);
                          }}
                          tabIndex={-1}
                        />
                        <SHStack width={"90%"}>
                          <Truncate
                            lines={1}
                            ellipsis={
                              <TooltipStyled
                                TransitionComponent={Zoom}
                                placement="top-end"
                                title={
                                  <SHStack
                                    sx={{
                                      bgcolor: hexToRGBA(
                                        palette.common.white,
                                        0.9,
                                      ),
                                      border: "1px solid #E3E3E3",
                                      backdropFilter: "blur(5px)",
                                      borderRadius: "3px",
                                      boxShadow: shadows[1],
                                      position: "relative",
                                      width: "350px",
                                      p: 1,
                                    }}
                                  >
                                    <SHTypography
                                      variant="caption"
                                      colorVariant="disabled"
                                      lineHeight={1.2}
                                      textAlign={"justify"}
                                    >
                                      {displayName}
                                    </SHTypography>
                                  </SHStack>
                                }
                              >
                                <span
                                  style={{
                                    fontSize: "0.875rem",
                                    textAlign: "left",
                                    color: palette.text.primary,
                                    lineHeight: "160%",
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    cursor: "pointer",
                                  }}
                                >
                                  ...
                                </span>
                              </TooltipStyled>
                            }
                            style={{
                              fontSize: "0.875rem",
                              textAlign: "left",
                              color: palette.text.primary,
                              lineHeight: "160%",
                              fontWeight: 700,
                              wordBreak: "break-word",
                            }}
                          >
                            {displayName}
                          </Truncate>

                          <SHTypography
                            variant="body2"
                            sx={{ fontWeight: 400, p: 0 }}
                            colorVariant="third"
                          >
                            {code}
                          </SHTypography>
                        </SHStack>
                      </SHStack>
                    </PortfolioSetupTableCell>
                    <PortfolioSetupTableCell>
                      <Controller
                        name={`selectedInvestments.${index}.weight`}
                        control={control}
                        render={({
                          field: { ref, onChange, value, ...others },
                        }) => (
                          <SHNumericField
                            {...others}
                            min={0}
                            max={MaxWeight}
                            value={watch(`selectedInvestments.${index}.weight`)}
                            onChange={(event, value) => {
                              setShowWarningRequiredInvestmentWeight(false);
                              setShowWarningIneligibilitySaveAsTemplate(false);
                              handleOnChangeInvestmentWeight(value ?? 0, index);
                            }}
                            onFocus={() => {
                              const value = getValues(
                                `selectedInvestments.${index}.weight`,
                              );
                              if (value === 0 || value === null)
                                setValue(
                                  `selectedInvestments.${index}.weight`,
                                  null,
                                  {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  },
                                );
                            }}
                            numericFormatProps={{
                              thousandSeparator: true,
                              decimalScale: 2,
                              allowNegative: false,
                            }}
                            InputProps={{
                              endAdornment: (
                                <SHInputAdornment position="end">
                                  <PercentageTypos />
                                </SHInputAdornment>
                              ),
                            }}
                            sx={{
                              "& .MuiInput-root": {
                                "&::before,&::after": {
                                  right: "30px",
                                },
                                "& .MuiInput-input": {
                                  fontSize: "16px",
                                  lineHeight: "160%",
                                  fontWeight: 600,
                                  fontFamily: "Epilogue",
                                  textAlign: "right",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </PortfolioSetupTableCell>
                    <PortfolioSetupTableCell>
                      <Controller
                        name={`selectedInvestments.${index}.value`}
                        control={control}
                        render={({
                          field: { ref, onChange, value, ...others },
                        }) => (
                          <SHNumericField
                            {...others}
                            min={0}
                            max={MaxPortfolioSize}
                            value={watch(`selectedInvestments.${index}.value`)}
                            onFocus={() => {
                              const value = getValues(
                                `selectedInvestments.${index}.value`,
                              );
                              if (value === 0 || value === null)
                                setValue(
                                  `selectedInvestments.${index}.value`,
                                  null,
                                  {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  },
                                );
                            }}
                            onChange={(event, value) => {
                              setShowWarningRequiredInvestmentWeight(false);
                              handleOnChangeInvestmentValue(value ?? 0, index);
                            }}
                            numericFormatProps={{
                              thousandSeparator: true,
                              decimalScale: 2,
                              allowNegative: false,
                            }}
                            InputProps={{
                              startAdornment: (
                                <SHInputAdornment position="start">
                                  <Dollar2SVG />
                                </SHInputAdornment>
                              ),
                            }}
                            sx={{
                              "& .MuiInput-root": {
                                "&::before,&::after": {
                                  left: "30px",
                                },
                                "& .MuiInput-input": {
                                  fontSize: "16px",
                                  lineHeight: "160%",
                                  fontWeight: 600,
                                  fontFamily: "Epilogue",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </PortfolioSetupTableCell>
                  </SHTableRow>
                );
              })}
            </SHTableBody>
          </SHTable>

          <SHStack flexDirection={"row"} justifyContent={"space-between"}>
            <Collapse in={watch("selectedInvestments").length > 0}>
              <SHButton
                variant="outlined"
                size="extraMedium"
                disabled={isSubmitting}
                isLoading={isLoading}
                onClick={() => {
                  setShowWarningRequiredInvestmentWeight(
                    hasWarningRequiredInvestmentWeight,
                  );
                  setShowWarningIneligibilitySaveAsTemplate(
                    !hasWarningRequiredInvestmentWeight &&
                      totalInvestmentWeight !== 100,
                  );

                  setShowSaveAsTemplateDialog(
                    !hasWarningRequiredInvestmentWeight &&
                      totalInvestmentWeight === 100,
                  );
                }}
              >
                Save as template
              </SHButton>
            </Collapse>

            <SHStack
              mb={
                showWarningRemainingAllocation ||
                showWarningRequiredInvestmentWeight ||
                showWarningIneligibilitySaveAsTemplate
                  ? "25px"
                  : "0"
              }
              spacing={"25px"}
              width={{ md: "45%", sm: "65%" }}
            >
              <SHStack
                flexDirection={"row"}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: "54px",
                  border: "1px solid #D6DDEB",
                  backgroundColor: hexToRGBA("#D6DDEB", 0.2),
                  backdropFilter: "blur(0.5px)",
                  padding: "16px 15px",
                }}
              >
                <SHTypography variant="subtitle2" fontSize="18px">
                  Remaining
                </SHTypography>
                <SHStack
                  flexDirection={"row"}
                  sx={{ width: "315px", minWidth: "315px" }}
                >
                  <SHStack
                    flexDirection={"row"}
                    gap={"10px"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    sx={{ width: "120px", minWidth: "120px" }}
                  >
                    <SHTypography variant="subtitle2" fontSize="18px">
                      <NumericFormat
                        displayType="text"
                        defaultValue={100}
                        value={totalRemainingWeight}
                        allowNegative={
                          totalRemainingWeight.toFixed(2) ===
                          RemainingSpecialValue
                            ? false
                            : true
                        }
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </SHTypography>
                    <PercentageTypos />
                  </SHStack>
                  <SHStack
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={"10px"}
                    pl={"30px"}
                    sx={{ width: "195px", minWidth: "195px" }}
                  >
                    <Dollar2SVG />
                    <SHTypography variant="subtitle2" fontSize="18px">
                      <NumericFormat
                        displayType="text"
                        defaultValue={portfolioSize}
                        value={totalRemainingValue}
                        allowNegative={
                          totalRemainingValue.toFixed(2) ===
                          RemainingSpecialValue
                            ? false
                            : true
                        }
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </SHTypography>
                  </SHStack>
                </SHStack>
              </SHStack>
              <SHStack
                spacing={
                  showWarningRequiredInvestmentWeight
                    ? 1
                    : showWarningIneligibilitySaveAsTemplate &&
                      showWarningRemainingAllocation
                    ? 0.5
                    : 0
                }
              >
                <Collapse in={showWarningRemainingAllocation}>
                  <SHAlert
                    severity="error"
                    textAlign={"left"}
                    icon={<WarningSVG />}
                  >
                    Remaining allocation must equal zero
                  </SHAlert>
                </Collapse>
                <Collapse in={showWarningRequiredInvestmentWeight}>
                  <SHAlert
                    severity="error"
                    textAlign={"left"}
                    icon={<WarningSVG />}
                  >
                    Template requires at least one investment with a weight
                  </SHAlert>
                </Collapse>
                <Collapse in={showWarningIneligibilitySaveAsTemplate}>
                  <SHAlert
                    severity="error"
                    textAlign={"left"}
                    icon={<WarningSVG />}
                  >
                    Weights must sum to 100% to save as template
                  </SHAlert>
                </Collapse>
              </SHStack>
            </SHStack>
          </SHStack>
          <SHDivider />
        </SHStack>
      </form>
      {showMAFeeDisclaimerDialog && (
        <MAFeeDisclaimerDialog
          onClose={() => setShowMAFeeDisclaimerDialog(false)}
        />
      )}

      {showSaveAsTemplateDialog && (
        <SaveInvestmentAsTemplateDialog
          onClose={() => setShowSaveAsTemplateDialog(false)}
          onSubmit={() => {}}
          selectedInvestment={investmentsSelected}
        />
      )}

      {showConfirmDialog !== undefined && (
        <NoWeightsDialog
          onClose={() => showConfirmDialog.callback(false)}
          onSubmit={() => {
            showConfirmDialog.callback(true);
          }}
        />
      )}
    </SHContainer>
  );
};

const PortfolioSetupTableCell = ({
  children,
  width,
  colSpan,
  sx: sxProps,
}: {
  width?: string | number;
  children?: React.ReactNode;
  colSpan?: number;
} & SHTableCellProps) => {
  return (
    <SHTableCell
      colSpan={colSpan}
      sx={{
        width: width,
        height: "auto",
        padding: "12px 15px",
        alignItems: "center",
        ...sxProps,
      }}
    >
      {children}
    </SHTableCell>
  );
};

const InvestmentSelectedTableHead = () => {
  return (
    <SHTableHead>
      <SHTableRow>
        <PortfolioSetupTableCell>
          <SHTypography
            variant="body1"
            colorVariant="third"
            paddingLeft={"84px"}
          >
            Investment name/code
          </SHTypography>
        </PortfolioSetupTableCell>
        <PortfolioSetupTableCell sx={{ width: "120px", minWidth: "120px" }}>
          <SHTypography variant="body1" colorVariant="third">
            Weight
          </SHTypography>
        </PortfolioSetupTableCell>
        <PortfolioSetupTableCell sx={{ width: "195px", minWidth: "195px" }}>
          <SHTypography variant="body1" colorVariant="third">
            Value
          </SHTypography>
        </PortfolioSetupTableCell>
      </SHTableRow>
    </SHTableHead>
  );
};

const PercentageTypos = () => {
  return (
    <SHTypography
      variant="subtitle1"
      fontSize={"22px"}
      fontWeight={900}
      lineHeight={"160%"}
      disabled
    >
      %
    </SHTypography>
  );
};
