import { InformationButton } from "@components/buttons/information";
import {
  useEditESGPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { debounce } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EditESGInputControllerProps,
  handleOnEditESGFieldChange,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { SHNumericFieldV2 } from "@components/design-systems/sh-numeric-field-v2";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";

export const EditESGNumberInput = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductEsgDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const handleOnChange = (newValue: string) => {
    handleOnEditESGFieldChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field: { ref, ...other } }) => (
        <SHNumericFieldV2
          disabled={isDisabled}
          sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
          thousandSeparator
          valueIsNumericString
          value={toStringValue(other.value, FieldTypeId.Number)}
          onValueChange={({ value }) => {
            other.onChange(value);
            debouncedHandleInputChange(value);
          }}
          fullWidth
          label={featureData.name}
          placeholder={EditProductDefaults.PlaceHolder}
          postfixLabel={postfixLabel}
          dotGroupProps={{
            blueDot: isShowBlueDot,
            orangeDot: isShowOrangeDot,
            greenDot: featureData?.isBusinessMetric,
          }}
        />
      )}
    />
  );
};
