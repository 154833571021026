import { APIExtRoutes } from "@constants/routes";
import { APIResponse } from "@models/core";
import {
  SubscriptionProductDTO,
  SupplierCreateDTO,
  SupplierDTO,
  SupplierLovDTO,
  SupplierUpdateDTO,
  SupplierUserAggregatedDTO,
} from "@models/supplier/entities/suppliers";
import { SubscriptionProductType } from "@models/supplier/enums/subscriptionProductType";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export async function createSupplier(
  supplier?: SupplierCreateDTO,
): Promise<APIResponse<SupplierDTO>> {
  const response = await APIGatewayService.post<SupplierCreateDTO>(
    APIExtRoutes.suppliers,
    supplier,
  );

  return handleErrorProxy(response, "Supplier created successfully!");
}

export async function getSupplier(
  supplierId?: string,
): Promise<APIResponse<SupplierDTO>> {
  const response = await APIGatewayService.get<SupplierDTO>(
    generatePath(APIExtRoutes.supplier, { supplierId }),
  );

  return handleErrorProxy(response);
}

export async function updateSupplier(
  supplierId: string,
  supplier?: SupplierUpdateDTO,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<SupplierUpdateDTO>(
    generatePath(APIExtRoutes.supplier, { supplierId }),
    supplier,
  );

  return handleErrorProxy(response, "Changes have been saved.");
}

// TODO: Need to get from supplier detail
export async function getSupplierLicenses(
  supplierId?: string,
): Promise<APIResponse<SupplierUserAggregatedDTO>> {
  const response = await APIGatewayService.get<SupplierUserAggregatedDTO>(
    generatePath("/api/aggregate/suppliers/:supplierId/users/new", {
      supplierId,
    }),
  );
  return handleErrorProxy(response);
}

export async function checkSupplierUniqueAFSL(
  afsl: string,
): Promise<APIResponse> {
  const response = await APIGatewayService.get(
    generatePath(APIExtRoutes.supplierUniqueAFSL, { afsl }),
  );
  return handleErrorProxy(response);
}

export async function getLicenseeByAFSL(
  afsl: string,
): Promise<APIResponse<SupplierDTO>> {
  const response = await APIGatewayService.get<SupplierDTO>(
    generatePath(APIExtRoutes.getLicenseeByAFSL, { afsl }),
  );
  return handleErrorProxy(response);
}

export async function getSubscriptionProducts(
  productType: SubscriptionProductType,
): Promise<APIResponse<SubscriptionProductDTO[]>> {
  const response = await APIGatewayService.get<SubscriptionProductType>(
    generatePath(APIExtRoutes.subscriptionProducts, { productType }),
  );
  return handleErrorProxy(response);
}

export async function getSupplierLov(): Promise<APIResponse<SupplierLovDTO[]>> {
  const response = await APIGatewayService.get<APIResponse<SupplierLovDTO[]>>(
    APIExtRoutes.supplierLov,
  );
  return handleErrorProxy(response);
}
