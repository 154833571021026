import { CalendarSVG } from "@components/svgs/calendar";
import { CalendarCheckedSVG } from "@components/svgs/calendar-checked";
import { SxProps, Theme } from "@mui/material";
import { CalendarPickerView } from "@mui/x-date-pickers";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { forwardRef } from "react";
import { SHTextField, SHTextFieldProps } from "../sh-text-field";
import { useToggle } from "react-use";

export interface SHDatePickerProps
  extends Omit<DatePickerProps<Date | null, Date | null>, "renderInput"> {
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  views?: CalendarPickerView[];
  inputFormat?: string;
  sxTextField?: SxProps<Theme>;
  openOnClick?: boolean;
}

export const SHDatePicker = forwardRef(
  (
    {
      inputFormat,
      textFieldProps,
      value,
      views = ["year", "day"],
      sxTextField,
      openOnClick = false,
      ...others
    }: SHDatePickerProps,
    ref: any,
  ): JSX.Element => {
    const [openCalendar, toggleOpenCalendar] = useToggle(false);

    return (
      <DesktopDatePicker
        {...others}
        views={views}
        inputFormat={inputFormat}
        value={value}
        ref={ref}
        components={{
          OpenPickerIcon:
            textFieldProps?.error || !value ? CalendarSVG : CalendarCheckedSVG,
        }}
        renderInput={(params) => (
          <SHTextField
            {...params}
            {...textFieldProps}
            sx={{
              ...sxTextField,
              caretColor: openOnClick ? "transparent" : "initial",
            }}
            onPaste={(e) => {
              openOnClick && e.preventDefault();
            }}
            autoComplete={openOnClick ? "off" : "on"}
            onClick={(e) => {
              if (others.disabled) e.preventDefault();
              else toggleOpenCalendar(true);
            }}
            onKeyDown={(e) => {
              if (openOnClick) e.preventDefault();
            }}
          />
        )}
        componentsProps={{
          actionBar: openOnClick
            ? {
                actions: ["clear"],
                style: {
                  height: "35px",
                },
              }
            : {},
        }}
        open={openOnClick ? openCalendar : undefined}
        onClose={() => toggleOpenCalendar(false)}
      />
    );
  },
);
