import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { DateFormat } from "@constants/format";
import {
  InvestmentProductModelsDTO
} from "@models/product/investment-product/entities/model";
import {
  getFeatureProductDataPaths
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { lastDayOfYear, parse } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
interface TableDatePickerProps {
  disabled?: boolean;
  width: string;
  onChange: (value: Date | null) => void;
  selectedIndex: [number, number];
  featureIndex: number;
  sectionIndex: number;
}
export const TableDatePicker = ({
  disabled = false,
  selectedIndex,
  featureIndex,
  sectionIndex,
  width,
  onChange,
}: TableDatePickerProps) => {
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );
  const { control, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();

  const watchedValue = useWatch({
    control,
    name: featureProductDataPath.value,
  }) as string | null;
  const productDataValue = useMemo(
    () => {
      const stringValue = getValues(featureProductDataPath.value) as
        | string
        | null;
      if (isEmpty(stringValue)) return null;
      const date = parse(String(stringValue), DateFormat, new Date());
      return date;
    },
    // eslint-disable-next-line
    [watchedValue, selectedIndex],
  );
  const [currentValue, setCurrentValue] = useState<Date | null>(
    productDataValue,
  );
  useEffect(
    () => setCurrentValue(productDataValue),
    // eslint-disable-next-line
    [selectedIndex, productDataValue],
  );
  return (
    <SHDatePicker
      PopperProps={{
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["top", "bottom"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "viewport",
            },
          },
        ],
      }}
      openOnClick
      onChange={(value) => {
        setCurrentValue(value);
        onChange(value);
      }}
      maxDate={lastDayOfYear(new Date())}
      views={["year", "month", "day"]}
      minDate={new Date("1/1/2000")}
      inputFormat={DateFormat}
      value={currentValue}
      disabled={disabled}
      sxTextField={{
        width: width,
        "& .MuiInput-root > input": {
          padding: "10px 0px 8px 0px",
        },
      }}
    />
  );
};
