import { SHBooleanField } from "@components/design-systems";
import { useEditESGPaths } from "@hooks/useInvestmentProduct";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { debounce, isNil } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useMount } from "react-use";
import {
  EditESGInputControllerProps,
  handleOnEditESGGoodScoreFieldChange,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

export const EditESGGoodScoreInput = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { control, setValue } = useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const handleOnChange = (newValue: boolean) => {
    handleOnEditESGGoodScoreFieldChange({
      originalValue: featureData.productData?.isPositiveScore,
      newValue,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  useMount(() => {
    const positiveScoreData = featureData.productData?.isPositiveScore;

    if (!isNil(positiveScoreData)) return;

    setValue(
      featureProductDataPath.isPositiveScore,
      Boolean(positiveScoreData),
    );
  });

  return (
    <Controller
      name={featureProductDataPath.isPositiveScore}
      control={control}
      render={({ field: { ref, ...other } }) => (
        <SHBooleanField
          {...other}
          disabled={isDisabled}
          value={Boolean(other.value)}
          isSwitch
          startAdornment="Good score?"
          isShowClearButton={false}
          onChange={(event) => {
            const newValue = !!event.target.value;
            other.onChange(newValue);
            debouncedHandleInputChange(newValue);
          }}
        />
      )}
    />
  );
};
