import { mockSupplierLov } from "@components/auto-completes/supplier/mock";
import {
  SHAutocomplete,
  SHBox,
  SHCheckbox,
  SHStack,
  SHTextFieldProps,
  SHTypography,
} from "@components/design-systems";
import { SupplierLovDTO } from "@models/supplier/entities/suppliers";
import { useTheme } from "@mui/material";
import { useState } from "react";
interface SelectSupplierProps {
  multiple?: boolean;
  maxLength?: number;
  textFieldProps?: Omit<SHTextFieldProps, "value">;
  supplierLov?: SupplierLovDTO[];
  value?: SupplierLovDTO[];
  onChange: (value: SupplierLovDTO[] | null) => void;
  disabled?: boolean;
}
const SelectSupplier = ({
  multiple,
  maxLength,
  textFieldProps,
  supplierLov = mockSupplierLov,
  value = [],
  onChange,
  disabled,
}: SelectSupplierProps) => {
  const { palette } = useTheme();
  const [selectedSupplier, setSelectedSupplier] =
    useState<SupplierLovDTO[]>(value);
  return (
    <SHStack direction={"column"} spacing={"16px"}>
      <SHAutocomplete
        multiple={multiple}
        value={value}
        disabled={disabled}
        options={supplierLov}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        textFieldProps={{
          ...textFieldProps,
          InputLabelProps: { shrink: true },
          placeholder:
            value?.length && multiple ? undefined : textFieldProps?.placeholder,
          InputProps: {
            startAdornment: selectedSupplier?.length ? (
              <SHTypography
                disabled={disabled}
                variant="body1"
                sx={{ paddingLeft: "10px" }}
              >
                {`${selectedSupplier?.length}/${supplierLov.length} selected`}
              </SHTypography>
            ) : undefined,
          },
        }}
        onChange={(e: any, newValue, r, details) => {
          if (
            r === "removeOption" &&
            e?.type === "keydown" &&
            e?.key === "Backspace"
          ) {
            return;
          }
          if (!Array.isArray(newValue)) {
            onChange([]);
            return;
          }
          if (supplierLov.length && supplierLov.length < newValue.length) {
            return;
          }
          onChange(newValue);
          setSelectedSupplier(newValue);
        }}
        renderOption={(props, option, { selected }) => {
          const isChecked = selected;
          return (
            <li {...props} key={option.id} style={{ padding: "1px 10px" }}>
              <SHStack alignItems={"center"} direction="row" spacing={1}>
                <SHCheckbox checked={isChecked} />
                <SHTypography variant="body3" fontWeight={500}>
                  {`${option.firmName} (AFSL: ${option.afsl} and all linked  practices)`}
                </SHTypography>
              </SHStack>
            </li>
          );
        }}
      />
      <SHBox
        sx={{
          height: "150px",
          px: "12px",
          py: "10px",
          border: `1px solid ${palette.divider}`,
          overflowY: "auto",
        }}
        p={"12px"}
      >
        <SHTypography variant="body2" textAlign={"left"}>
          {selectedSupplier
            ?.map(
              (item) =>
                `${item.firmName} (AFSL: ${item.afsl} and all linked  practices)`,
            )
            .join(", ")}
        </SHTypography>
      </SHBox>
    </SHStack>
  );
};

export default SelectSupplier;
