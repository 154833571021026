import { PageRoutes } from "@constants";
import { updateSupplier } from "@redux/slices/supplier";
import { RootState, useAppDispatch } from "@redux/store";
import {
  checkSupplierUniqueAFSL,
  getSupplier,
  getSupplierLov,
} from "@services/suppliers";
import { useNavigate } from "react-router";
import { useNotification } from "./useNotification";
import { useUserPermissions } from "./userUserPermission";
import { useSelector } from "react-redux";
import { getPracticesByAFSL } from "@services/practice/practiceService";

export const useSupplier = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { isAdminGroup } = useUserPermissions();
  const navigate = useNavigate();
  const { supplierData } = useSelector((state: RootState) => state.supplier);

  const loadSupplier = async (supplierId: string) => {
    const { data, message } = await getSupplier(supplierId);
    if (data) {
      dispatch(updateSupplier(data));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
      if (isAdminGroup) {
        navigate(`${PageRoutes.suppliers}`);
      }
    }
  };

  const refreshSupplier = () => {
    dispatch(updateSupplier());
  };

  const loadPracticesByAFSL = async (afsl: string) => {
    const { isSuccess, data, message } = await getPracticesByAFSL(afsl);

    if (isSuccess) {
      return data;
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };
  const loadSupplierLov = async () => {
    const { isSuccess, data, message } = await getSupplierLov();
    if (isSuccess) {
      return data;
    }
    notify(message, {
      variant: "error",
      close: true,
    });
  };

  const isSupplierUniqueAFSL = async (afsl: string) => {
    const { isSuccess } = await checkSupplierUniqueAFSL(afsl);

    return isSuccess;
  };

  return {
    supplierData,
    loadSupplier,
    loadPracticesByAFSL,
    isSupplierUniqueAFSL,
    refreshSupplier,
    loadSupplierLov,
  };
};
