import { InformationButton } from "@components/buttons/information";
import { SHLabel, SHSelect, SHStack } from "@components/design-systems";
import {
  useEditESGPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { hexToRGBA } from "@utils";
import { debounce } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useMount } from "react-use";
import {
  EditESGInputControllerProps,
  handleOnEditESGFieldChange,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

export const EditESGDropDownInput = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductEsgDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const defaultValue = useMemo(
    () => featureData.lovData?.find((opt) => opt.isDefault)?.id,
    [featureData.lovData],
  );

  useMount(() => {
    const value = getValues(featureProductDataPath.value);
    if (!value && !!defaultValue) {
      setValue(featureProductDataPath.value, defaultValue);
      setValue(featureProductDataPath.highlightType, HighlightType.Added);
      setValue(featureProductDataPath.dataStatus, InvestmentDataStatus.Filled);
    }
  });

  const handleOnChange = (newValue?: string) => {
    handleOnEditESGFieldChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field: { ref, ...other } }) => {
        return (
          <SHStack
            direction="column"
            gap={1}
            mb={1}
            sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
          >
            <SHLabel
              label={featureData?.name}
              postfixLabel={postfixLabel}
              disabled={isDisabled}
              dotGroupProps={{
                blueDot: isShowBlueDot,
                orangeDot: isShowOrangeDot,
                greenDot: featureData?.isBusinessMetric,
              }}
            />

            <SHSelect
              {...other}
              fullWidth
              dataItemKey="id"
              displayField="name"
              placeholder="Select"
              disabled={isDisabled}
              data={featureData?.lovData ?? []}
              value={other.value ? other.value : defaultValue}
              sx={{
                "& .sh-select-placeholder": {
                  color: `${hexToRGBA("#3D98FF", 0.6)} !important`,
                },
              }}
              allowClear={!defaultValue && !!other.value}
              onClear={(value) => {
                other.onChange(value);
                debouncedHandleInputChange(value ?? "");
              }}
              onChange={(event) => {
                const newValue = event.target.value;
                other.onChange(newValue);
                debouncedHandleInputChange(newValue);
              }}
            />
          </SHStack>
        );
      }}
    />
  );
};
