import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import {
  SHLabel,
  SHSelect,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { hexToRGBA } from "@utils";
import { useMemo } from "react";
import { InformationButton } from "@components/buttons/information";
import { HighlightType } from "@models/configuration";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { getFeatureDataPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/edit-series-right-panel";

interface SeriesFeatureDropdownProps {
  feature: InvestmentProductConfigurationFeature;
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  selectedIndex: [number, number];
  isDisabled?: boolean;
}

const SeriesFeatureDropdown = ({
  feature,
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  isDisabled = false,
}: SeriesFeatureDropdownProps) => {
  const { control, setValue, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const defaultValue = useMemo(
    () => feature.lovData?.find((opt) => opt.isDefault)?.id,
    [feature.lovData],
  );
  const {
    investmentSeriesDataPath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataModifiedPath,
    featureProductDataStatusPath,
    featureProductDataPublishedPath,
    featureViewingGroupSelectionsPath,
    investmentSeriesDataHighlightTypePath,
  } = getFeatureDataPath(selectedIndex, investmentProductSeriesData, feature);
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductHighlightTypePath as SeriesPath,
      dataStatus: featureProductDataStatusPath as SeriesPath,
    },
  });

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(feature.viewingGroupSelections, newValue);

    setValue(
      `${investmentSeriesDataPath}.isModified` as SeriesPath,
      isModified,
    );
    setValue(sectionModifiedPath as SeriesPath, isModified);
    setValue(featureModifiedPath as SeriesPath, isModified);
    setValue(featureProductDataModifiedPath as SeriesPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const handleOnChange = (newValue?: string) => {
    const publishedData = getValues(
      featureProductDataPublishedPath as SeriesPath,
    );

    setValue(featureProductDataPath as SeriesPath, newValue);
    setValue(sectionModifiedPath as SeriesPath, true);
    setValue(
      investmentSeriesDataHighlightTypePath as SeriesPath,
      newValue !== publishedData ? HighlightType.Edited.toString() : "",
    );
    setValue(
      featureProductHighlightTypePath as SeriesPath,
      newValue !== feature.productData?.value
        ? HighlightType.Edited.toString()
        : "",
    );
    setValue(
      featureProductDataStatusPath as SeriesPath,
      isNil(newValue) || isEmpty(newValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(
      featureProductDataModifiedPath as SeriesPath,
      newValue !== feature.productData?.value,
    );
    setValue(
      featureModifiedPath as SeriesPath,
      newValue !== feature.productData?.value,
    );
    setValue(`${investmentSeriesDataPath}.isModified` as SeriesPath, true);
  };

  return (
    <SHStack
      direction={"row"}
      alignItems="center"
      justifyContent={"flex-start"}
      gap={2.5}
      mb={1}
    >
      <SHStack width={"38%"}>
        <Controller
          name={featureProductDataPath as SeriesPath}
          control={control}
          render={({ field: { ref, ...other } }) => {
            return (
              <SHStack direction="column" gap={1}>
                <SHSelect
                  label={
                    <SHLabel
                      label={
                        <SHTypography variant="subtitle2">
                          {feature?.name}
                          {!isEmpty(feature.description) && (
                            <InformationButton content={feature.description} />
                          )}
                        </SHTypography>
                      }
                      dotGroupProps={{
                        blueDot: isShowBlueDot,
                        orangeDot: isShowOrangeDot,
                      }}
                    />
                  }
                  {...other}
                  dataItemKey="id"
                  displayField="name"
                  placeholder="Select"
                  data={feature.lovData ?? []}
                  value={other.value ? other.value : defaultValue}
                  allowClear={!defaultValue && !!other.value}
                  onClear={(value) => {
                    other.onChange(value);
                    handleOnChange(value);
                  }}
                  disabled={isDisabled}
                  sx={{
                    "& .sh-select-placeholder": {
                      color: `${hexToRGBA("#3D98FF", 0.6)} !important`,
                    },
                  }}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    other.onChange(newValue);
                    handleOnChange(newValue);
                  }}
                />
              </SHStack>
            );
          }}
        />
      </SHStack>
      {feature.allowedVisibilitySetting && (
        <SHStack width={"30%"}>
          <Controller
            name={featureViewingGroupSelectionsPath as SeriesPath}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SelectViewingGroups
                textFieldProps={{
                  label: `Visible to`,
                }}
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value);
                }}
                value={
                  (getValues(
                    featureViewingGroupSelectionsPath as SeriesPath,
                  ) as ViewingGroupSelectionDTO[] | null) || []
                }
                viewingGroupData={feature.viewingGroupSelections ?? []}
                disabled={isDisabled}
              />
            )}
          />
        </SHStack>
      )}
    </SHStack>
  );
};

export default SeriesFeatureDropdown;
