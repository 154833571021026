import { SHButton, SHButtonProps } from "@components/design-systems";
// for feature customize
interface InvestmentProductSaveDraftButtonProps extends SHButtonProps {}
export const InvestmentProductSaveDraftButton = ({ ...props }: InvestmentProductSaveDraftButtonProps) => {
  return (
    <SHButton variant="outlined" size="extraMedium" {...props}>
      Save draft
    </SHButton>
  );
};
