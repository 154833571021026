import { theme } from "@themes";
import { SVGProps } from "react";

export const DeleteSVG = ({
  color = theme.palette.error.main,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.458 12.483a.47.47 0 0 1-.469.47.47.47 0 0 1-.468-.47V6.39a.47.47 0 0 1 .468-.47.47.47 0 0 1 .47.47v6.093Zm2.344 0a.47.47 0 0 1-.469.47.47.47 0 0 1-.469-.47V6.39a.47.47 0 0 1 .47-.47.47.47 0 0 1 .468.47v6.093Zm2.344 0a.47.47 0 0 1-.469.47.47.47 0 0 1-.469-.47V6.39a.47.47 0 0 1 .469-.47.47.47 0 0 1 .469.47v6.093Zm-.074-10.988 1.076 1.613h2.045a.702.702 0 1 1 0 1.407h-.235v8.906a2.343 2.343 0 0 1-2.344 2.344H4.052a2.343 2.343 0 0 1-2.344-2.344V4.515h-.234a.702.702 0 1 1 0-1.407h2.045l1.075-1.613c.305-.456.817-.73 1.365-.73h2.748c.548 0 1.06.274 1.365.73ZM5.21 3.108h4.248l-.556-.833a.235.235 0 0 0-.194-.104H5.96c-.076 0-.176.04-.193.104l-.557.833ZM3.115 13.421c0 .519.42.937.937.937h6.563a.936.936 0 0 0 .937-.937V4.515H3.114v8.906Z"
        fill={color}
      />
    </svg>
  );
};
