import {
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { FRCell } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/table-cell";
import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { useTheme } from "@mui/material";
import { HighlightType } from "@models/configuration";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import SeriesFeatureTableLabel from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/table-input-controller/table-lable";

interface SeriesFeatureTableProps {
  features: InvestmentProductConfigurationFeature[];
  selectedIndex: [number, number];
  tableHeaderName?: string;
  isDisabled?: boolean;
}

const SeriesFeatureTable = ({
  features,
  selectedIndex = [0, 0],
  isDisabled = false,
}: SeriesFeatureTableProps) => {
  const { palette } = useTheme();
  const { control, setValue, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();

  const productSeriesDataPath = `investmentProductSeriesData.${selectedIndex[0]}`;
  const featureHeaderNamePath = `investmentProductSeriesData.${selectedIndex[0]}.sections.0.features.0`;
  const sectionPath = `${productSeriesDataPath}.sections.${selectedIndex[1]}`;
  const featurePath = `${sectionPath}.features`;

  const handleOnChangeInputField = (
    featureIndex: number,
    value?: string | null,
  ) => {
    const featureItemPath = `${featurePath}.${featureIndex}`;

    setValue(
      `${featureItemPath}.productData.highlightType` as SeriesPath,
      value !== features[featureIndex].productData?.value
        ? HighlightType.Edited.toString()
        : "",
    );
    setValue(
      `${featureItemPath}.productData.dataStatus` as SeriesPath,
      isNil(value) || isEmpty(value)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(
      `${featureItemPath}.isModified` as SeriesPath,
      value !== features[featureIndex].productData?.value,
    );
    setValue(
      `${featureItemPath}.productData.isModified` as SeriesPath,
      value !== features[featureIndex].productData?.value,
    );
    setValue(`${sectionPath}.isModified` as SeriesPath, true);
    setValue(`${productSeriesDataPath}.isModified` as SeriesPath, true);
    setValue(`${featureItemPath}.productData.value` as SeriesPath, value);
  };
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
    featureIdx: number,
  ) => {
    const productSeriesModifiedPath = `${productSeriesDataPath}.isModified`;
    const sectionModifiedPath = `${sectionPath}.isModified`;
    const featureModifiedPath = `${featurePath}.${featureIdx}.isModified`;
    const featureProductDataModifiedPath = `${featurePath}.${featureIdx}.productData.isModified`;
    const isModified = !isEqual(
      features[featureIdx]?.viewingGroupSelections,
      newValue,
    );

    setValue(productSeriesModifiedPath as SeriesPath, isModified);
    setValue(sectionModifiedPath as SeriesPath, isModified);
    setValue(featureModifiedPath as SeriesPath, isModified);
    setValue(featureProductDataModifiedPath as SeriesPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const getProductSeriesName = () => {
    return getValues(
      `${featureHeaderNamePath}.productData.value` as SeriesPath,
    ) as string;
  };
  const renderVisibilityHeader = () => {
    const isHaveViewingGroupSelections = features.some((feature) =>
      feature.viewingGroupSelections ? feature.allowedVisibilitySetting : false,
    );

    if (isHaveViewingGroupSelections) {
      return (
        <FRCell isHeader cellWidth={"190px !important"}>
          <SHTypography disabled={isDisabled} variant="body4" fontWeight={600}>
            Visible to
          </SHTypography>
        </FRCell>
      );
    }
  };
  const renderVisibilityCell = (
    viewingGroupSelections: ViewingGroupSelectionDTO[],
    featureIndex: number,
  ) => {
    const featureViewingGroupSelectionsPath = `${featurePath}.${featureIndex}.viewingGroupSelections`;
    const shouldRenderCell =
      viewingGroupSelections.length > 0 &&
      features[featureIndex].allowedVisibilitySetting;

    return (
      <FRCell>
        {shouldRenderCell && (
          <Controller
            name={featureViewingGroupSelectionsPath as SeriesPath}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SelectViewingGroups
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value, featureIndex);
                }}
                value={
                  (getValues(
                    featureViewingGroupSelectionsPath as SeriesPath,
                  ) as ViewingGroupSelectionDTO[] | null) || []
                }
                viewingGroupData={viewingGroupSelections ?? []}
                disabled={isDisabled}
              />
            )}
          />
        )}
      </FRCell>
    );
  };

  return (
    <SHTableContainer
      sx={{
        paddingTop: "25px",
        paddingBottom: "15px",
        overflowY: "hidden",
        maxWidth: "700px !important",
      }}
    >
      <SHTable>
        <SHTableHead>
          <SHTableRow>
            <FRCell isHeader>
              <SHTypography variant="body4" fontWeight={600}>
                Series FUM
              </SHTypography>
            </FRCell>
            <FRCell isHeader cellWidth={"180px !important"}>
              <SHTypography
                disabled={isDisabled}
                variant="body4"
                fontWeight={600}
              >
                {getProductSeriesName()}
              </SHTypography>
            </FRCell>
            {renderVisibilityHeader()}
          </SHTableRow>
        </SHTableHead>
        <SHTableBody
          sx={{
            "&.MuiTableBody-root::before": {
              lineHeight: "0px",
              fontSize: "0px",
            },
            maxWidth: "523.5px !important",
          }}
        >
          <SHTableRow
            style={{
              height: "13px",
              border: 0,
              borderBottomWidth: 1,
              background: "transparent",
            }}
          ></SHTableRow>
          {features?.map((row, index) => {
            const fieldName = `${featurePath}.${index}.productData.value`;

            return (
              <SHTableRow
                key={fieldName}
                sx={{
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: index !== 0 ? 0 : 1,
                  backgroundColor:
                    (index + 1) % 2 === 0
                      ? `${palette.secondary[50]} !important`
                      : "transparent !important",
                }}
              >
                {index === 0 ? (
                  <></>
                ) : (
                  <>
                    <SeriesFeatureTableLabel
                      productFeatureData={row}
                      featureDataPath={
                        `${featurePath}.${index}.productData` as SeriesPath
                      }
                    />
                    <FRCell cellWidth={"180px !important"}>
                      <Controller
                        name={fieldName as any}
                        control={control}
                        render={({ field: { ref, ...other } }) => {
                          return (
                            <SHTextField
                              {...other}
                              placeholder={"Enter detail"}
                              maxLength={100}
                              fullWidth
                              sx={{
                                "&>.MuiInput-root": {
                                  height: "auto",
                                  minHeight: "40px",
                                  "& .MuiInput-input": {
                                    "&::placeholder": {
                                      color: "#3D98FF",
                                    },
                                  },
                                },
                              }}
                              disabled={isDisabled}
                              onChange={(event) => {
                                handleOnChangeInputField(
                                  index,
                                  event.target.value,
                                );
                              }}
                              multiline
                              maxRows={3}
                            />
                          );
                        }}
                      />
                    </FRCell>
                    {renderVisibilityCell(
                      row.viewingGroupSelections ?? [],
                      index,
                    )}
                  </>
                )}
              </SHTableRow>
            );
          })}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};

export default SeriesFeatureTable;
