import { SHBox, SHButton, SHStack } from "@components/design-systems";
import { useNotification } from "@hooks/useNotification";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PrivateRichTextEditor } from "./components/text-editor";

export class HTMLConvertor {
  content: string = "";
}

export const ConvertToHtml = () => {
  const { palette } = useTheme();
  const { notify } = useNotification();
  const formControls = useForm({
    mode: "onChange",
    defaultValues: {
      ...new HTMLConvertor(),
    },
  });

  const { control, getValues, reset } = formControls;

  const [text, setText] = useState<string>("");

  const convertToHtml = () => {
    const value = getValues();
    setText(value.content);
  };

  const clearData = () => {
    reset();
    setText("");
  };

  return (
    <SHStack
      sx={{
        marginX: 5,
        marginY: 5,
        height: "100vh",
      }}
    >
      <SHBox component={"form"} height={"100%"}>
        <SHStack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <SHStack sx={{ width: "50%", height: "100%" }}>
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <PrivateRichTextEditor
                  {...field}
                  maxLength={5000}
                  // label={"Text to convert"}
                  sx={{ marginTop: 0, width: "50vw" }}
                />
              )}
            />
          </SHStack>

          <SHStack
            direction={"column"}
            justifyContent={"space-between"}
            sx={{ paddingX: 2, gap: 2 }}
          >
            <SHButton
              variant="contained"
              sx={{ width: "100px" }}
              onClick={convertToHtml}
            >
              Convert
            </SHButton>
            <SHButton
              variant="outlined"
              sx={{ width: "100px" }}
              onClick={clearData}
            >
              Clear
            </SHButton>
          </SHStack>

          <SHStack sx={{ width: "50%", height: "100%" }} alignItems={"center"}>
            <textarea
              value={text}
              style={{
                resize: "none",
                outline: "none",
                height: "100%",
                width: "100%",
                // backgroundColor: "cyan",
                marginBottom: "10px",
                borderColor: palette.grey[300],
                borderWidth: "1px 1px",
                borderStyle: "solid",
                fontFamily: "Epilogue, sans-serif",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.4,
                color: palette.text.primary,
                padding: 15,
              }}
            />
            <SHButton
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(text);
                notify("Data copied to clipboard", {
                  variant: "success",
                  close: true,
                });
              }}
            >
              Copy to Clipboard
            </SHButton>
          </SHStack>
        </SHStack>
      </SHBox>
    </SHStack>
  );
};
