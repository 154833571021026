import { APIExtRoutes } from "@constants/routes";
import { APIResponse } from "@models/core";
import { InvestmentProductBannerInfoDTO } from "@models/product/investment-product/entities/bannerInfo";
import { InvestmentProductBrandingDataDTO } from "@models/product/investment-product/entities/brandingData";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import {
  InvestmentProductBDMUserDTO,
  InvestmentProductDTO,
} from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import {
  InvestmentProductConfigurationModel,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import {
  APIGatewayService,
  getDataWithMinimumDelay,
  handleErrorProxy,
} from "@services/shService";
import { isEqual } from "lodash";
import { generatePath } from "react-router";
import { compileInvestmentProductGroups } from "./util";

export async function postInvestmentProduct(
  requestBody?: InvestmentProductManagerDTO,
): Promise<APIResponse<InvestmentProductManagerDTO>> {
  const response = await APIGatewayService.post<InvestmentProductManagerDTO>(
    APIExtRoutes.investmentProducts,
    requestBody,
  );
  return handleErrorProxy(response, "Product has been created.");
}

export async function putInvestmentProduct(
  requestBody?: InvestmentProductDTO,
): Promise<APIResponse<InvestmentProductDTO>> {
  const response = await APIGatewayService.post<InvestmentProductDTO>(
    APIExtRoutes.investmentProducts,
    requestBody,
  );
  return handleErrorProxy(response, "Saved as draft!");
}

export async function putInvestmentProductManagerData(
  productId: string,
  managerData: InvestmentProductManagerDTO,
): Promise<APIResponse<InvestmentProductManagerDTO>> {
  const response = await APIGatewayService.put<InvestmentProductManagerDTO>(
    generatePath(APIExtRoutes.investmentProductsDetail, {
      investmentProductId: productId,
    }),
    managerData,
  );

  return handleErrorProxy(response, "Saved as draft!");
}

export async function getInvestmentProductBannerInfo(
  investmentProductId: string,
): Promise<APIResponse<InvestmentProductBannerInfoDTO[]>> {
  const response = await APIGatewayService.get<
    InvestmentProductBannerInfoDTO[]
  >(
    generatePath(APIExtRoutes.investmentProductsBannerInfo, {
      investmentProductId,
    }),
  );
  return handleErrorProxy(response);
}

export async function getProductManagedBrandingInfo(
  investmentProductId: string,
): Promise<APIResponse<InvestmentProductBrandingDataDTO>> {
  const response = await APIGatewayService.get<InvestmentProductBannerInfoDTO>(
    generatePath(APIExtRoutes.investmentProductsBrandingData, {
      investmentProductId,
    }),
  );

  return handleErrorProxy(response);
}

export async function getInvestmentProductManager(
  investmentProductId?: string,
  mode?: "view" | "edit",
  supplierId: string = "",
): Promise<APIResponse<InvestmentProductManagerDTO>> {
  const response = await getDataWithMinimumDelay(
    APIGatewayService.get<InvestmentProductBannerInfoDTO>(
      generatePath(APIExtRoutes.investmentProductsManager, {
        investmentProductId,
        mode,
        supplierId,
      }),
    ),
  );

  return handleErrorProxy(response);
}

export async function getNewModelProductSeries(investmentProductId: string) {
  const seriesPath = `${APIExtRoutes.investmentProductsSeries}/new`;
  const response = await getDataWithMinimumDelay(
    APIGatewayService.get<InvestmentProductSeriesDTO>(
      generatePath(seriesPath, {
        investmentProductId,
      }),
    ),
  );

  return handleErrorProxy(response);
}

export async function getOrUpdateInvestmentProductSeries(
  investmentProductId?: string,
  mode?: "view" | "edit",
  seriesDto: InvestmentProductSeriesDTO[] = [],
): Promise<APIResponse<InvestmentProductSeriesDTO[]>> {
  const seriesPath = `${APIExtRoutes.investmentProductsSeries}?mode=:mode`;
  let response;

  if (mode === "edit" && seriesDto.length > 0) {
    response = await getDataWithMinimumDelay(
      APIGatewayService.put<InvestmentProductSeriesDTO[]>(
        generatePath(seriesPath, {
          investmentProductId,
          mode,
        }),
        seriesDto,
      ),
    );
  } else {
    response = await getDataWithMinimumDelay(
      APIGatewayService.get<InvestmentProductSeriesDTO[]>(
        generatePath(seriesPath, {
          investmentProductId,
          mode,
        }),
      ),
    );
  }

  return handleErrorProxy(response, "Saved as draft!");
}

export async function getInvestmentProductModels(
  investmentProductId?: string,
  mode?: "view" | "edit",
): Promise<APIResponse<InvestmentProductModelsDTO[]>> {
  const response = await getDataWithMinimumDelay(
    APIGatewayService.get<InvestmentProductModelsDTO[]>(
      generatePath(APIExtRoutes.investmentProductsModels, {
        investmentProductId: investmentProductId,
        mode,
      }),
    ),
  );

  return handleErrorProxy(response);
}
export async function getInvestmentProductConfigurationModel(
  seriesId: string,
  investmentProductId: string,
  modelId: string = "new",
): Promise<APIResponse<InvestmentProductConfigurationModel>> {
  const response =
    await APIGatewayService.get<InvestmentProductConfigurationModel>(
      generatePath(APIExtRoutes.investmentProductsConfigurationModel, {
        investmentProductId,
        seriesId,
        modelId,
      }),
    );
  return handleErrorProxy(response);
}
export async function putInvestmentProductModels(
  investmentProductId: string,
  investmentProductData: InvestmentProductModelsDTO[],
): Promise<APIResponse<InvestmentProductModelsDTO[]>> {
  const response = await APIGatewayService.put<InvestmentProductModelsDTO[]>(
    generatePath(APIExtRoutes.investmentProductModelsUpdate, {
      investmentProductId: investmentProductId,
    }),
    investmentProductData,
  );

  return handleErrorProxy(response, "Saved as draft!");
}

export async function investmentProductSubmitForReview(
  investmentProductId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.investmentProductsSubmitForReview, {
      investmentProductId,
    }),
  );
  return handleErrorProxy(response, "Changes have been submitted for review!");
}

export async function investmentProductRevertToDraft(
  investmentProductId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.investmentProductsRevertToDraft, {
      investmentProductId,
    }),
  );
  return handleErrorProxy(
    response,
    "Investment product profile has been reverted back to draft!",
  );
}

export async function investmentProductPublishDraft(
  investmentProductId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.put<string>(
    generatePath(APIExtRoutes.investmentProductsPublishDraft, {
      investmentProductId,
    }),
  );
  return handleErrorProxy(response, "Changes have been published!");
}

export async function investmentProductRecallSubmission(
  investmentProductId: string,
): Promise<APIResponse<string>> {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.investmentProductsRecallSubmission, {
      investmentProductId,
    }),
  );
  return handleErrorProxy(
    response,
    "Investment product profile has been recalled back to draft!",
  );
}

export async function investmentProductDiscardDraft(
  investmentProductId: string,
): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    generatePath(APIExtRoutes.investmentProductsDiscardDraft, {
      investmentProductId,
    }),
  );
  return handleErrorProxy(
    response,
    "Investment product profile changes have been discarded.",
  );
}

export async function getInvestmentProductBDMUsers(
  productId: string,
  regionIds?: string[],
): Promise<APIResponse<InvestmentProductBDMUserDTO[]>> {
  const response = await getDataWithMinimumDelay(
    APIGatewayService.post<InvestmentProductBDMUserDTO[]>(
      generatePath(APIExtRoutes.productBDMUsers, { productId }),
      { regionIds: regionIds },
    ),
  );

  return handleErrorProxy(response);
}

export async function getInvestmentProductESGData(
  investmentProductId?: string,
  mode?: "view" | "edit",
): Promise<APIResponse<InvestmentProductEsgDTO>> {
  const response = await getDataWithMinimumDelay(
    APIGatewayService.get<InvestmentProductEsgDTO>(
      generatePath(APIExtRoutes.investmentProductsESG, {
        investmentProductId: investmentProductId,
        mode,
      }),
    ),
  );

  return handleErrorProxy(response);
}

export async function putInvestmentProductEsgData(
  productId: string,
  esgData: InvestmentProductEsgDTO,
): Promise<APIResponse<InvestmentProductEsgDTO>> {
  const response = await APIGatewayService.put<InvestmentProductEsgDTO>(
    generatePath(APIExtRoutes.investmentProductsEsgUpdate, {
      investmentProductId: productId,
    }),
    esgData,
  );

  return handleErrorProxy(response, "Saved as draft!");
}

export async function getInvestmentProductDots(
  productId?: string,
  version?: string,
  configurationVersion?: string,
  investmentConfigurationVersion?: string,
): Promise<APIResponse<InvestmentConfigurationGroup[]>> {
  const response = await APIGatewayService.get<
    InvestmentConfigurationGroup[] | undefined
  >(
    generatePath(APIExtRoutes.investmentProductGroups, {
      productId: productId,
      version: version,
      configurationVersion: configurationVersion,
      investmentConfigurationVersion: investmentConfigurationVersion,
    }),
  );

  if (response?.data) {
    response.data = compileInvestmentProductGroups(
      isEqual(productId, "new"),
      response?.data,
    );
  }

  return handleErrorProxy(response);
}
