import {
  SHHtmlBlock,
  SHLabel,
  SHRichTextEditor,
  SHStack,
} from "@components/design-systems";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import {
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { RootState } from "@redux/store";
import { debounce, isEmpty, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
interface ModelFeatureTextEditorProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationModelsFeature;
}
export const FeatureTextEditor = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: ModelFeatureTextEditorProps) => {
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const originalValue = feature.productData?.value;
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  /** Hook **/
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );

  /** State **/

  /** Effect */

  /** Handler **/
  const handleInputChange = debounce((newValue: string | null) => {
    setValue(
      featureProductDataPath.dataStatus,
      isEmpty(newValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(
      featurePath.dataStatus,
      isEmpty(newValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(featurePath.isModified, !isEqual(newValue, originalValue));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalValue),
    );
    const publishedValue = getValues(featureProductDataPath.publishedValue);
    if (publishedValue !== newValue) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  }, DEBOUNCE_DELAY);
  return (
    <SHStack
      flexDirection="row"
      flexWrap={{ sm: "wrap", lg: "nowrap" }}
      gap={{ sm: 2, lg: 4 }}
    >
      <SHStack>
        <SHLabel
          dotGroupProps={{
            orangeDot: isShowOrangeDot,
            blueDot: isShowBlueDot,
            greenDot: feature.isBusinessMetric,
          }}
          label={feature.name}
        />
        <SHHtmlBlock variant="body3" content={feature.description} />
      </SHStack>
      <Controller
        control={control}
        name={featureProductDataPath.value}
        render={({ field: { ref, ...other } }) => (
          <SHRichTextEditor
            disabled={isSaving || isPendingApproval}
            value={
              getValues(featureProductDataPath.value) as string | undefined
            }
            label={"Please enter detail"}
            onChange={(newValue) => {
              const actualValue = isEmpty(newValue) ? null : newValue;
              other.onChange(actualValue);
              handleInputChange(actualValue);
            }}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        )}
      />
    </SHStack>
  );
};
