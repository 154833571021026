import { SHButton, SHButtonProps, SHStack } from "@components/design-systems";
import SubmitForReviewDialog from "@pages/suppliers/_id/_products/_id/components/dialogs/submit-for-review";
import { useToggle } from "react-use";

export interface InvestmentProductSubmitForReviewButtonProps
  extends SHButtonProps {
  isApproved: boolean;
  isSubmitting?: boolean;

  onSubmit: () => Promise<void>;
}

export const InvestmentProductSubmitForReviewButton = ({
  isApproved,
  isSubmitting,
  onSubmit,
  ...others
}: InvestmentProductSubmitForReviewButtonProps) => {
  const [showDialog, toggleShowDialog] = useToggle(false);

  const handleOnSubmit = async () => {
    await onSubmit();
    toggleShowDialog(false);
  };

  return (
    <SHStack>
      <SHButton
        {...others}
        variant="contained"
        size="extraMedium"
        onClick={() => toggleShowDialog(true)}
      >
        Submit for review
      </SHButton>

      {showDialog && (
        <SubmitForReviewDialog
          onClose={() => toggleShowDialog(false)}
          onSubmit={handleOnSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </SHStack>
  );
};
