import { InformationButton } from "@components/buttons/information";
import {
  useEditESGPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { ImageField } from "@models/core";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { UploadField } from "@pages/suppliers/_id/_products/_id/edit/components/upload-field";
import { debounce } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EditESGInputControllerProps,
  handleOnEditESGFieldChange,
  toEditESGNativeValue,
} from "../../util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

export const EditESGImageField = ({
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditESGInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductEsgDTO>();

  const { featureProductDataPath } = useEditESGPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductEsgDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const handleOnChange = (newValue: string) => {
    handleOnEditESGFieldChange({
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field: { ref, ...other } }) => {
        const imageField = toEditESGNativeValue(other.value, FieldTypeId.Image);

        return (
          <UploadField
            disabled={isDisabled}
            label={featureData.name}
            postfixLabel={postfixLabel}
            dotGroupProps={{
              redDot: isShowOrangeDot,
              blueDot: isShowBlueDot,
              greenDot: featureData?.isBusinessMetric,
            }}
            showImage={!!imageField}
            isEditMode
            value={imageField}
            onChange={(value: ImageField | null) => {
              const newValue = toStringValue(value, FieldTypeId.Image);
              other.onChange(newValue);
              debouncedHandleInputChange(newValue);
            }}
          />
        );
      }}
    />
  );
};
