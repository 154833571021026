import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHDivider,
  SHHtmlBlock,
  SHImage,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHBooleanIcon } from "@components/design-systems/sh-boolean-icon";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { GridImageGallery } from "@components/grid-image-gallery";
import { ImageSVG } from "@components/svgs";
import { DateFormat, FullDateFormat } from "@constants/format";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { UserType } from "@models/auth";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { useTheme } from "@mui/material";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { RootState } from "@redux/store";
import { generateFeatureName } from "@utils/text-format";
import { format, parse } from "date-fns";
import { isEmpty, last } from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FeatureViewLongText } from "../feature-view-long-text";
import { FRCell } from "../table-cell";
import { UpgradeSubscriptionNotice } from "../view-feature-model/components/upgrade-subscription-notice";
import { InvestmentProductCollapseMenu } from "./components/investment-collapse-menu";
import SectionTitle from "./components/sub-section-title";
import { checkStringIsNumber } from "@pages/suppliers/_id/util";
import { NumericFormat } from "react-number-format";
import EyeIconTooltip from "@components/svgs/eye-tooltip";
import { VisibilityMessage } from "@pages/suppliers/constants";

interface ViewFeatureProps {
  investmentProductSeriesData?: InvestmentProductSeriesDTO[];
}

export const ViewFeature = ({
  investmentProductSeriesData,
}: ViewFeatureProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { palette } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const selectedSection = useMemo(() => {
    return investmentProductSeriesData?.[selectedIndex[0]]?.sections?.[
      selectedIndex[1]
    ];
  }, [investmentProductSeriesData, selectedIndex]);
  const textShortFeature =
    selectedSection?.features?.filter(
      (feature) => feature.fieldType?.id === FieldTypeId.TextShort,
    ) ?? [];

  const lastItem = last(selectedSection?.features);

  const renderInvestmentTableItem = (
    features: InvestmentProductConfigurationFeature[],
  ) => {
    if (features.length === 0) return;

    features = features.filter((e) => e.name !== "Series");

    return (
      <>
        <SHTableContainer
          sx={{
            padding: "15px 0",
            overflowY: "hidden",
            maxWidth: "523.5px !important",
          }}
        >
          <SHTable>
            <SHTableHead>
              <SHTableRow>
                <FRCell isHeader>
                  <SHTypography variant="body4" fontWeight={600}>
                    Series FUM
                  </SHTypography>
                </FRCell>
                <FRCell isHeader cellWidth={"180px !important"}>
                  <SHTypography variant="body4" fontWeight={600}>
                    {!isEmpty(investmentProductSeriesData)
                      ? investmentProductSeriesData?.[selectedIndex[0]].name
                      : ""}
                  </SHTypography>
                </FRCell>
              </SHTableRow>
            </SHTableHead>
            <SHTableBody
              sx={{
                "&.MuiTableBody-root::before": {
                  lineHeight: "0px",
                  fontSize: "0px",
                },
                maxWidth: "523.5px !important",
              }}
            >
              <SHTableRow
                style={{
                  height: "13px",
                  border: 0,
                  borderBottomWidth: 1,
                  background: "transparent",
                }}
              ></SHTableRow>
              {features?.map((row, index) => {
                return (
                  <SHTableRow
                    key={row.id}
                    sx={{
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderTopWidth: index !== 0 ? 0 : 1,
                      backgroundColor:
                        index % 2 === 0
                          ? `${palette.secondary[50]} !important`
                          : "transparent !important",
                    }}
                  >
                    <FRCell>
                      <SHTypography variant="body4" colorVariant="fourth">
                        {row?.name}
                      </SHTypography>
                      {!isEmpty(row.description) && (
                        <InformationButton content={row.description} />
                      )}
                      <EyeIconTooltip
                        tooltipProps={{
                          title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                        }}
                        iconProps={{
                          style: {
                            marginLeft: "10px",
                            marginBottom: "-3px",
                          },
                        }}
                        isVisible={
                          row.hasCustomVisibility && !row.isViewRestricted
                        }
                      />
                    </FRCell>
                    <FRCell cellWidth={"180px !important"}>
                      <SHTypography
                        variant="body4"
                        colorVariant={
                          row.isViewRestricted ? "disabled" : "fourth"
                        }
                      >
                        {row.isViewRestricted
                          ? VisibilityMessage.FEATURE_LEVEL
                          : isEmpty(row?.productData?.value)
                          ? "No data"
                          : row?.productData?.value}
                      </SHTypography>
                    </FRCell>
                  </SHTableRow>
                );
              })}
            </SHTableBody>
          </SHTable>
        </SHTableContainer>
        <SHDivider />
      </>
    );
  };

  const renderRestricted = (
    feature: InvestmentProductConfigurationFeature,
    isLastItem: boolean,
  ) => {
    if (feature.order === textShortFeature[0]?.order) {
      return renderInvestmentTableItem(textShortFeature);
    }
    if (textShortFeature.includes(feature)) {
      return null;
    }
    const featureName = generateFeatureName(
      feature.name ?? "",
      feature.description,
    );
    return (
      <SHStack key={feature.id}>
        <SHStack
          direction={"column"}
          justifyContent="space-between"
          columnGap={1}
          sx={{ pr: "40px", py: "25px" }}
        >
          <SHTypography
            variant={"subtitle2"}
            textAlign="left"
            color={palette.text.primary}
          >
            {featureName.first}
            {feature?.description && (
              <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                {featureName.last}
                <InformationButton
                  content={feature.description}
                  margin={"-8px 0px 2px 4px !important"}
                />
              </SHBox>
            )}
          </SHTypography>
          <SHTypography
            variant="body3"
            colorVariant="disabled"
            key={feature.id}
            flexShrink={0}
          >
            {VisibilityMessage.FEATURE_LEVEL}
          </SHTypography>
        </SHStack>
        {!isLastItem && <SHDivider />}
      </SHStack>
    );
  };
  const renderFeatureItem = (
    feature: InvestmentProductConfigurationFeature,
    isLastItem: boolean,
  ) => {
    const isViewRestricted = feature.isViewRestricted;
    const hasCustomVisibility =
      feature.hasCustomVisibility && !isViewRestricted;
    const isSlideShow = feature.fieldType?.id === FieldTypeId.Slideshow;
    const value = toNativeValue(
      feature.productData?.value || "",
      isSlideShow ? FieldTypeId.Slideshow : FieldTypeId.YesNo,
    );
    const additionalValue = feature.productData?.additionalValue;
    const hasDescription = isSlideShow || (value === true && additionalValue);

    if (isViewRestricted) return renderRestricted(feature, isLastItem);

    /* Render handler */
    const renderDropdownFeatureItem = (
      feature: InvestmentProductConfigurationFeature,
    ) => {
      const featureName = generateFeatureName(
        feature.name ?? "",
        feature.description,
      );

      const getFeatureValue = () => {
        const lov = feature.lovData;
        const value = feature.productData?.value;
        const displayValue = lov?.find((x) => x.id === value)?.name;

        if (!displayValue) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              No data
            </SHTypography>
          );
        }

        return (
          <SHHtmlBlock
            variant="body3"
            color={palette.text.fourth}
            content={displayValue}
          />
        );
      };

      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              p: "15px 0",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              width="100%"
              color="#25324B"
              pb="5px"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    marginLeft: "10px",
                  },
                }}
                isVisible={hasCustomVisibility}
              />
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };
    const renderPercentageFeatureItem = (
      feature: InvestmentProductConfigurationFeature,
    ) => {
      const featureName = generateFeatureName(
        feature.name ?? "",
        feature.description,
      );
      const getFeatureValue = () => {
        const value = feature.productData?.value;
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              No data
            </SHTypography>
          );
        }
        const isNumber = checkStringIsNumber(value);
        if (isNumber) {
          return (
            <SHTypography variant="body3" color={palette.text.fourth}>
              <NumericFormat
                valueIsNumericString
                value={value}
                displayType={"text"}
                suffix={"%"}
                decimalScale={2}
              />
            </SHTypography>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="fourth">
            {value}
          </SHTypography>
        );
      };

      if (!investmentProductSeriesData) {
        return <></>;
      }
      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              p: "15px 0",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              width="100%"
              color={palette.text.primary}
              pb="5px"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    marginLeft: "10px",
                  },
                }}
                isVisible={hasCustomVisibility}
              />
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };
    const renderCurrencyFeatureItem = (
      feature: InvestmentProductConfigurationFeature,
    ) => {
      const featureName = generateFeatureName(
        feature.name ?? "",
        feature.description,
      );
      const getFeatureValue = () => {
        const value = feature.productData?.value;
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              No data
            </SHTypography>
          );
        }
        const isNumber = checkStringIsNumber(value);
        if (isNumber) {
          return (
            <SHTypography variant="body3" color={palette.text.fourth}>
              <NumericFormat
                valueIsNumericString
                value={value}
                displayType={"text"}
                prefix="$"
                decimalScale={2}
              />
            </SHTypography>
          );
        }
        return (
          <SHTypography variant="body3" colorVariant="fourth">
            {value}
          </SHTypography>
        );
      };

      if (!investmentProductSeriesData) {
        return <></>;
      }
      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              p: "15px 0",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              width="100%"
              color={palette.text.primary}
              pb="5px"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    marginLeft: "10px",
                  },
                }}
                isVisible={hasCustomVisibility}
              />
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };

    const renderTextLongFeatureItem = (
      feature: InvestmentProductConfigurationFeature,
    ) => {
      const featureName = generateFeatureName(
        feature.name ?? "",
        feature.description,
      );

      const getFeatureValue = () => {
        const value = feature.productData?.value;
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              No data
            </SHTypography>
          );
        }
        return (
          <SHHtmlBlock
            variant="body3"
            color={palette.text.fourth}
            content={value}
          />
        );
      };

      if (!investmentProductSeriesData) {
        return <></>;
      }

      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              p: "15px 0",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              textAlign="left"
              width="100%"
              color={palette.text.primary}
              pb="5px"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    marginLeft: "10px",
                  },
                }}
                isVisible={hasCustomVisibility}
              />
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };

    const renderTextDateTimeFeatureItem = (
      feature: InvestmentProductConfigurationFeature,
    ) => {
      const featureName = generateFeatureName(
        feature.name ?? "",
        feature.description,
      );
      const getFeatureValue = () => {
        const value = feature.productData?.value;
        if (!value) {
          return (
            <SHTypography variant="body3" colorVariant="fourth">
              No data
            </SHTypography>
          );
        }

        const dateValue = parse(value, FullDateFormat, new Date());

        return (
          <SHTypography variant="body3" color={palette.text.fourth}>
            {format(dateValue, DateFormat)}
          </SHTypography>
        );
      };

      if (!investmentProductSeriesData) {
        return <></>;
      }

      return (
        <>
          <SHStack
            direction={"column"}
            justifyContent="space-between"
            sx={{
              p: "15px 0",
              wordBreak: "break-word",
            }}
          >
            <SHTypography
              variant="subtitle2"
              color={palette.text.primary}
              textAlign="left"
              width="45%"
              pb="5px"
            >
              {featureName.first}
              {feature?.description && (
                <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                  {featureName.last}
                  <InformationButton
                    content={feature.description}
                    margin={"-8px 0px 2px 4px !important"}
                  />
                </SHBox>
              )}
              <EyeIconTooltip
                tooltipProps={{
                  title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                }}
                iconProps={{
                  style: {
                    marginLeft: "10px",
                  },
                }}
                isVisible={hasCustomVisibility}
              />
            </SHTypography>

            <SHBox>{getFeatureValue()}</SHBox>
          </SHStack>
          <SHDivider />
        </>
      );
    };

    const renderFeatureYesNoValue = () => {
      switch (value) {
        case true:
          return <SHBooleanIcon isTrue />;
        case false:
          return <SHBooleanIcon isTrue={false} />;
        default:
          return (
            <SHTypography
              variant="body2"
              colorVariant="fourth"
              sx={{
                minWidth: "54px",
              }}
            >
              No data
            </SHTypography>
          );
      }
    };

    const renderFeatureHeading = () => (
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        sx={{ pr: "40px", py: "25px" }}
      >
        <SHStack direction="row" spacing={0.5} alignItems="center">
          <SHTypography color={palette.text.primary}>
            {feature.name}
          </SHTypography>
          {feature?.description && (
            <InformationButton
              margin={"-3px 0px 2px 4px !important"}
              content={feature.description}
            />
          )}
          <EyeIconTooltip
            tooltipProps={{
              title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
            }}
            iconProps={{
              style: {
                marginLeft: "10px",
              },
            }}
            isVisible={hasCustomVisibility}
          />
        </SHStack>
        <SHBox>{renderFeatureYesNoValue()}</SHBox>
      </SHStack>
    );

    const renderFeatureImage = (isGallery: boolean, content: ReactNode) => {
      return (
        <>
          <SHCollapse
            sx={{ pt: "15px", pb: "15px" }}
            open
            title={
              <SHStack direction="row" spacing={0.5} alignItems="center">
                {isGallery && <ImageSVG />}
                <SHTypography
                  color={palette.text.primary}
                  variant={"subtitle2"}
                >
                  {feature.name}
                </SHTypography>
                {feature?.description && (
                  <InformationButton
                    margin={"-3px 0px 2px 4px !important"}
                    content={feature.description}
                  />
                )}
                <EyeIconTooltip
                  tooltipProps={{
                    title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                  }}
                  iconProps={{
                    style: {
                      marginLeft: "10px",
                    },
                  }}
                  isVisible={hasCustomVisibility}
                />
              </SHStack>
            }
          >
            <SHBox sx={{ pb: isLastItem ? "0px" : "15px" }}>{content}</SHBox>
          </SHCollapse>
          {!isLastItem && <SHDivider />}
        </>
      );
    };

    /* FieldType rendering conditions */

    switch (feature.fieldType?.id) {
      case FieldTypeId.Slideshow: {
        if (!value || value.length === 0) {
          return <></>;
        }

        return renderFeatureImage(
          true,
          <GridImageGallery
            previewImagesDialogTitle={selectedSection?.name}
            images={value}
          />,
        );
      }

      case FieldTypeId.Image: {
        if (!value || value.length === 0) {
          return (
            <>
              <SHStack
                direction={"row"}
                justifyContent="space-between"
                sx={{
                  p: "15px 0",
                  wordBreak: "break-word",
                }}
              >
                <SHTypography
                  variant="subtitle2"
                  textAlign="left"
                  width="100%"
                  color={palette.text.primary}
                  pb="5px"
                >
                  {feature.name}
                  {feature?.description && (
                    <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                      <InformationButton
                        content={feature.description}
                        margin={"-8px 0px 2px 4px !important"}
                      />
                    </SHBox>
                  )}
                </SHTypography>
                <EyeIconTooltip
                  tooltipProps={{
                    title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                  }}
                  iconProps={{
                    style: {
                      marginLeft: "10px",
                    },
                  }}
                  isVisible={hasCustomVisibility}
                />
                <SHBox>
                  <SHTypography
                    variant="body2"
                    colorVariant="fourth"
                    sx={{
                      minWidth: "54px",
                    }}
                  >
                    No data
                  </SHTypography>
                </SHBox>
              </SHStack>
              {!isLastItem && <SHDivider />}
            </>
          );
        }

        return renderFeatureImage(
          false,
          <SHStack justifyContent={"center"}>
            <SHImage
              src={value.url}
              alt={value.key}
              height={"250px"}
              style={{ objectFit: "contain" }}
            />
          </SHStack>,
        );
      }

      case FieldTypeId.Dropdown:
        return renderDropdownFeatureItem(feature);

      case FieldTypeId.TextShort: {
        if (feature.order === textShortFeature[0]?.order) {
          return renderInvestmentTableItem(textShortFeature);
        }
        return;
      }

      case FieldTypeId.DateTime: {
        return renderTextDateTimeFeatureItem(feature);
      }

      case FieldTypeId.YesNo: {
        if (!hasDescription) {
          return (
            <>
              {renderFeatureHeading()}
              <SHDivider />
            </>
          );
        }

        return (
          <>
            <SHCollapse title={renderFeatureHeading()} fullWidthTitle>
              <FeatureViewLongText
                content={additionalValue}
                textColor={palette.text.fourth}
              />
            </SHCollapse>
            <SHDivider />
          </>
        );
      }
      case FieldTypeId.Percentage:
      case FieldTypeId.PercentageText: {
        return renderPercentageFeatureItem(feature);
      }
      case FieldTypeId.CurrencyText:
      case FieldTypeId.Currency: {
        return renderCurrencyFeatureItem(feature);
      }

      default: {
        return renderTextLongFeatureItem(feature);
      }
    }
  };

  if (!investmentProductSeriesData) {
    return <></>;
  }

  const showUpgradeToView = () => {
    if (
      user?.userType === UserType.AdviserTrial ||
      user?.userType === UserType.AdviserAdminTrial
    ) {
      return true;
    }
    return false;
  };

  const renderPageUpdated = () => {
    if (!selectedSection || !selectedSection.pageUpdated) return <></>;
    return (
      <SHTypography variant="body1" colorVariant="third" textAlign={"right"}>
        Page updated: {`${format(selectedSection.pageUpdated, DateFormat)}`}
      </SHTypography>
    );
  };

  /** Final return */

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <UpgradeSubscriptionNotice />
      ) : (
        <ProductFeaturesLayout
          hasData={!isEmpty(investmentProductSeriesData)}
          leftSide={
            <InvestmentProductCollapseMenu
              investmentProductSeriesData={investmentProductSeriesData}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              defaultExpandIndexes={[0]}
            />
          }
          rightSide={
            <SHStack>
              <SHStack
                direction={"row"}
                sx={{ justifyContent: "space-between" }}
              >
                <SectionTitle
                  groupName={
                    !isEmpty(investmentProductSeriesData)
                      ? investmentProductSeriesData?.[selectedIndex[0]].name
                      : ""
                  }
                  sectionName={selectedSection?.name}
                  sectionNameColor={palette.text.fourth}
                />
                {renderPageUpdated()}
              </SHStack>
              <SHDivider />
              {selectedSection?.features?.map(
                (feature: InvestmentProductConfigurationFeature) => (
                  <React.Fragment key={feature.id}>
                    {renderFeatureItem(feature, feature.id === lastItem?.id)}
                  </React.Fragment>
                ),
              )}
            </SHStack>
          }
        />
      )}
    </SHStack>
  );
};
