import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { RootState } from "@redux/store";
import {
  updateShowBeingKickedDialog as updatePlatformShowBeingKickedDialog,
} from "@redux/slices/product";
import {
  updateShowBeingKickedDialog as updateInvestmentProductShowBeingKickedDialog,
} from "@redux/slices/product/investment-product";
import { useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import { ProductType } from "@models/product/enums/productType";

const ACTION_TYPES = {
  Platform: {
    updateShowBeingKickedDialog: updatePlatformShowBeingKickedDialog.type,
  },
  SMA: {
    updateShowBeingKickedDialog: updateInvestmentProductShowBeingKickedDialog.type,
  },
  MDA: {
    updateShowBeingKickedDialog: updateInvestmentProductShowBeingKickedDialog.type,
  }
}

interface ProductStateActions {
  updateShowBeingKickedDialog: (payload: boolean) => void;
}

interface ProductConfig {
  getShowBeingKickedDialog: (state: RootState) => boolean | undefined;
  getBeingKickedBy: (state: RootState) => string | undefined;
  actionTypes: {
    updateShowBeingKickedDialog: string;
  };
  actionCreators: {
    updateShowBeingKickedDialog: typeof updatePlatformShowBeingKickedDialog | typeof updateInvestmentProductShowBeingKickedDialog;
  }
}

const PRODUCT_CONFIG: Record<ProductType, ProductConfig> = {
  [ProductType.Platform]: {
    getShowBeingKickedDialog: (state: RootState)=> state.product.productUI?.showBeingKickedDialog,
    getBeingKickedBy: (state: RootState)=> state.product.productUI?.beingKickedBy,
    actionTypes: ACTION_TYPES.Platform,
    actionCreators: {
      updateShowBeingKickedDialog: updatePlatformShowBeingKickedDialog,
    },
  },
  [ProductType.SMA]: {
    getShowBeingKickedDialog: (state: RootState)=> state.investmentProduct.investmentProductUI?.showBeingKickedDialog,
    getBeingKickedBy: (state: RootState)=> state.investmentProduct.investmentProductUI?.beingKickedBy,
    actionTypes: ACTION_TYPES.SMA,
    actionCreators: {
      updateShowBeingKickedDialog: updateInvestmentProductShowBeingKickedDialog,
    },
  },
  [ProductType.MDA]: {
    getShowBeingKickedDialog: (state: RootState)=> state.investmentProduct.investmentProductUI?.showBeingKickedDialog,
    getBeingKickedBy: (state: RootState)=> state.investmentProduct.investmentProductUI?.beingKickedBy,
    actionTypes: ACTION_TYPES.MDA,
    actionCreators: {
      updateShowBeingKickedDialog: updateInvestmentProductShowBeingKickedDialog,
    },
  },
};

export interface ProductBeingKickedOutDialogProps {
  productType?: ProductType;
}

export const ProductBeingKickedOutDialog = ({
  productType,
}: ProductBeingKickedOutDialogProps) => {
  const dispatch = useAppDispatch();

  const productConfig = PRODUCT_CONFIG[productType ?? ProductType.Platform];

  const productActions: ProductStateActions = {
    updateShowBeingKickedDialog: (payload: boolean) =>
      dispatch({ type: productConfig.actionTypes.updateShowBeingKickedDialog, payload }),
};

  const showBeingKickedDialog = useSelector(productConfig.getShowBeingKickedDialog);

  const beingKickedBy = useSelector(productConfig.getBeingKickedBy);

  const handleOnClose = () => {
    productActions.updateShowBeingKickedDialog(false);
  };

  return (
    <>
      <SHDialog
        open={showBeingKickedDialog || false}
        onClose={handleOnClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        width={600}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              You have been kicked out of editing the Product
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {beingKickedBy} kicked you out.
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Close
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
