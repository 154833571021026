import {
  EditManagerInputControllerProps,
  handleEditManagerAdditionalValueChange,
  handleEditManagerYesNoFieldChange,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { useMemo, useState } from "react";
import { InformationButton } from "@components/buttons/information";
import { debounce, isEqual } from "lodash";
import {
  SHBooleanField,
  SHRichTextEditor,
  SHStack,
} from "@components/design-systems";
import { FieldTypeId } from "@models/configuration";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";

export const EditManagerYesNoInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const [fieldValue, setFieldValue] = useState<string | undefined>(
    featureData.productData?.value,
  );

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const displayAdditionalValueField: boolean = useMemo(() => {
    const fieldValueIsTrue = isEqual(fieldValue, "true");
    return [!!featureData.hasAdditionalValue, fieldValueIsTrue].every(Boolean);
  }, [fieldValue, featureData]);

  const handleOnChange = (newValue: string) => {
    setFieldValue(newValue);
    handleEditManagerYesNoFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const handleEditManagerAdditionalFieldChange = (newValue: string) => {
    handleEditManagerAdditionalValueChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.additionalValue,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  const debouncedHandleEditManagerAdditionalValueChange = debounce(
    handleEditManagerAdditionalFieldChange,
    EditProductDefaults.DebounceTime,
  );

  const renderAdditionalValueField = () => {
    if (!displayAdditionalValueField) return <></>;

    return (
      <Controller
        name={featureProductDataPath.additionalValue as any}
        control={control}
        render={({ field: { ref, ...other } }) => (
          <SHRichTextEditor
            {...other}
            disabled={isDisabled}
            label={EditProductDefaults.AdditionalFieldTitle}
            maxLength={MAX_LONG_TEXT_LENGTH}
            height={165}
            onChange={(value) => {
              other.onChange(value);
              debouncedHandleEditManagerAdditionalValueChange(value);
            }}
          />
        )}
      />
    );
  };

  return (
    <SHStack>
      <SHStack width={{ xs: "100%", md: 520 }} direction={"row"} gap={2}>
        <Controller
          name={featureProductDataPath.value}
          control={control}
          render={({ field: { ref, ...other } }) => {
            return (
              <SHBooleanField
                sx={{ flex: 1 }}
                {...other}
                disabled={isDisabled}
                value={toEditManagerNativeValue(other.value, FieldTypeId.YesNo)}
                onChange={(_, value?: boolean | null) => {
                  const newValue = toStringValue(value, FieldTypeId.YesNo);
                  other.onChange(newValue);
                  debouncedHandleInputChange(newValue);
                }}
                label={featureData.name}
                postfixLabel={postfixLabel}
                dotGroupProps={{
                  blueDot: isShowBlueDot,
                  orangeDot: isShowOrangeDot,
                  greenDot: !!featureData?.isBusinessMetric,
                }}
              />
            );
          }}
        />
        {featureData.allowedVisibilitySetting && (
          <Controller
            name={featureDataPath.viewingGroupSelections}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SelectViewingGroups
                disabled={isDisabled}
                textFieldProps={{
                  label: `Visible to`,
                }}
                sx={{
                  flexBasis: "40%",
                }}
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value);
                }}
                value={
                  (getValues(featureDataPath.viewingGroupSelections) as
                    | ViewingGroupSelectionDTO[]
                    | null) || []
                }
                viewingGroupData={featureData.viewingGroupSelections ?? []}
              />
            )}
          />
        )}
      </SHStack>
      {renderAdditionalValueField()}
    </SHStack>
  );
};
